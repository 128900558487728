import React, { useCallback, useEffect, useMemo } from 'react';
import { useApps } from '../../../apps/context';
import { integrationInstallMessages } from '../../../messages';
import { useDispatch } from '../../../redux/store';
import { InstallCallbackArgs } from '../../../scripts/app';
import { useToaster, useUserSafe } from '../../../scripts/hooks';
import {
  ManualFetchDataType,
  useManualDataFetch,
} from '../../../scripts/hooks/manual-trigger-hooks';
import { logError } from '../../../scripts/utils';
import {
  AppInstallController,
  AppInstallControllerProvider,
  AppInstallEventArgs,
} from '../../admin/AppsTable/AppInstallController';
import { ConnectButton } from '../../admin/AppsTableRow/Buttons/ConnectButton';

export const SlackConnectButton = (): JSX.Element => {
  const appInstallController = useMemo(() => new AppInstallController(), []);

  const dispatch = useDispatch();

  const manualTriggerAppRefresh = useManualDataFetch(
    dispatch,
    ManualFetchDataType.APPS
  );

  const toaster = useToaster();

  const user = useUserSafe();
  const apps = useApps();

  const slackApp = useMemo(() => {
    return apps.find((app) => app.definition.shortname === 'slack')!;
  }, [apps]);

  const allSlackConnectionsLength = useMemo(() => {
    const shortname = 'slack';
    let connections = user.orgByOrgId.usersAppsByOrg.nodes.filter(
      ({ appName }) => appName === shortname
    );

    connections = [
      ...connections,
      ...user.usersApps.nodes.filter(({ appName }) => appName === shortname),
    ];

    connections = connections.filter(
      (value, index, self) => self.findIndex((v) => v.id === value.id) === index
    );

    return connections.length;
  }, [user.usersApps.nodes, user.orgByOrgId.usersAppsByOrg.nodes]);

  const handleInstall = useCallback(
    ({ appInstallRequest }: Partial<AppInstallEventArgs>) => {
      if (!appInstallRequest) {
        return;
      }

      const args: InstallCallbackArgs = {};
      if (appInstallRequest.id) {
        args.appId = appInstallRequest.id;
      }

      args.v2 = true;

      if (!appInstallRequest.app.getInstallDialog(appInstallRequest.isOrg)) {
        // there is no dialog for this app, install it now
        toaster
          .withPromise(
            appInstallRequest.app
              .install(appInstallRequest.isOrg, args)
              .then(manualTriggerAppRefresh),
            ...integrationInstallMessages(appInstallRequest.app.definition),
            6000
          )
          .catch(logError);
      }
    },
    [toaster, manualTriggerAppRefresh]
  );

  useEffect(() => {
    const event_listener_handle_install = (
      eventArgs: Partial<AppInstallEventArgs>
    ) => {
      handleInstall(eventArgs);
    };

    appInstallController.on(
      'handle_app_install',
      event_listener_handle_install
    );

    return () => {
      appInstallController.off(
        'handle_app_install',
        event_listener_handle_install
      );
    };
  }, [appInstallController, handleInstall]);

  return (
    <AppInstallControllerProvider value={appInstallController}>
      <ConnectButton
        app={slackApp}
        connectionsLength={allSlackConnectionsLength}
        size="small"
      />
    </AppInstallControllerProvider>
  );
};
