import { BotMinimal } from '../../models/Bots';
import { Action } from '../actions';

export enum BotsMinimalAction {
  POPULATE_BOTS = 'BOTS_MINIMAL_POPULATE_BOTS',
  ADD_SEARCH_QUERY = 'BOTS_MINIMAL_ADD_SEARCH_QUERY',
}

export function populateBotsMinimal(payload: BotMinimal[]): Action {
  return {
    type: BotsMinimalAction.POPULATE_BOTS,
    payload,
  };
}

export function addBotsMinimalSearchedQuery(payload: string): Action {
  return {
    type: BotsMinimalAction.ADD_SEARCH_QUERY,
    payload,
  };
}
