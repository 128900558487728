import React from 'react';
import { Step } from 'react-joyride';
import { StorageKey } from '../../../scripts/storage';

export enum ONBOARDING_TOUR_STEP_CLASSES {
  CONNECT_MORE_APPS_BUTTON = 'tourConnectMoreAppsButtonContainer',
  USE_CASE_CARD = 'tourUseCaseCard',
  QA_TEXT_INPUT = 'tourQaTextInput',
}

export const TOUR_QA_MESSAGE_CLASS = 'tourQaMessage';

export const STEPS: Step[] = [
  {
    target: `.${ONBOARDING_TOUR_STEP_CLASSES.CONNECT_MORE_APPS_BUTTON}`,
    content: (
      <>
        <h2 className="text-left font-bold mt-0 mb-2">
          Discover apps to connect
        </h2>
        <p className="text-left mb-0">
          Connect all the apps you use at work for the best search experience.
        </p>
      </>
    ),
    disableBeacon: true,
  },
  {
    target: `.${ONBOARDING_TOUR_STEP_CLASSES.USE_CASE_CARD}`,
    content: (
      <>
        <h2 className="text-left font-bold mt-0 mb-2">
          Try some recommended queries
        </h2>
        <p className="text-left mb-0">
          Click on one of these recommended queries and try Dashworks!
        </p>
      </>
    ),
    disableBeacon: true,
  },
  {
    target: `.${ONBOARDING_TOUR_STEP_CLASSES.QA_TEXT_INPUT}`,
    content: (
      <>
        <h2 className="text-left font-bold mt-0 mb-2">Ask questions</h2>
        <p className="text-left mb-0">
          Ask something about a project, person, policy, process, or product in
          your organization.
        </p>
      </>
    ),
    disableBeacon: true,
  },
];

export const isUserOnboardingTourDoneStoredInLocalStorage = (): boolean => {
  const value = localStorage.getItem(StorageKey.UserOnboardingTourDone);
  if (!value) return false;
  return JSON.parse(value) as boolean;
};

export const setUserOnboardingTourDoneInLocalStorage = (
  value: boolean
): void => {
  localStorage.setItem(
    StorageKey.UserOnboardingTourDone,
    JSON.stringify(value)
  );
};

export const isQAMessagePresentInDOM = (): boolean => {
  return !!document.querySelector(`.${TOUR_QA_MESSAGE_CLASS}`);
};
