import { makeLazyComponent } from '../../../scripts/utils';

export const LazyInviteMembersModal = makeLazyComponent(
  async () =>
    (
      await import(
        /* webpackChunkName: "invite-members-modal" */ './InviteMembersModal'
      )
    ).InviteMembersModal
);
