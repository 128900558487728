import classNames from 'classnames';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { UserRoleType } from '../../../models/User';
import { RadioList, RadioListOption } from '../../controls/RadioList/RadioList';
import { UIIcon } from '../../controls/ui/UIIcon/UIIcon';
import { UITextbox } from '../../controls/ui/UITextbox/UITextbox';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '../../shadcn/lib/components/popover';

const userRoleDisplayNames: Record<UserRoleType, string> = {
  [UserRoleType.ENGINEERING]: 'Engineering',
  [UserRoleType.CUSTOMER_SUPPORT]: 'Customer Support',
  [UserRoleType.SALES]: 'Sales',
  [UserRoleType.MARKETING]: 'Marketing',
  [UserRoleType.PRODUCT_MANAGEMENT]: 'Product Management',
  [UserRoleType.HR]: 'HR',
  [UserRoleType.LEGAL_COMPLIANCE]: 'Legal Compliance',
  [UserRoleType.TALENT]: 'Talent',
  [UserRoleType.OTHER]: 'Other',
};

interface Props {
  userWorkRole: UserRoleType;
  setUserWorkRole: (role: UserRoleType) => void;
  otherWorkRole: string;
  setOtherWorkRole: React.Dispatch<React.SetStateAction<string>>;
}

export const UserWorkRoleSelect = ({
  userWorkRole,
  setUserWorkRole,
  otherWorkRole,
  setOtherWorkRole,
}: Props): JSX.Element => {
  const [openPopover, setOpenPopover] = useState(false);

  const handleRoleChange = useCallback(
    (role: UserRoleType) => {
      setUserWorkRole(role);
      setOpenPopover(false);
    },
    [setUserWorkRole]
  );

  return (
    <div>
      <div className="block font-bold text-base mb-2">
        Which best describes your role?
      </div>
      <Popover onOpenChange={setOpenPopover} open={openPopover}>
        <PopoverTrigger asChild>
          <div className="border border-solid border-gray-30 focus:border-gray-50 rounded-md px-[17px] pt-[11px] pb-[10px] flex justify-between items-center cursor-pointer">
            <div>{userRoleDisplayNames[userWorkRole]}</div>
            <div>
              <UIIcon name="arrow-down" size={20} />
            </div>
          </div>
        </PopoverTrigger>
        <PopoverContent
          className="z-[201] w-[80vw] max-w-[472px] !p-2"
          onMouseDown={() => {
            setOpenPopover(false);
          }}
        >
          <UserRoleSelect
            onChange={handleRoleChange}
            selectedRole={userWorkRole}
          />
        </PopoverContent>
      </Popover>
      <div
        className={classNames(
          {
            hidden: userWorkRole !== UserRoleType.OTHER,
          },
          'mt-2'
        )}
      >
        <UITextbox
          onChange={(e) => {
            setOtherWorkRole(e);
          }}
          placeholder="Enter your role"
          size="large"
          value={otherWorkRole}
        />
      </div>
    </div>
  );
};

interface UserRoleSelectProps {
  selectedRole: UserRoleType;
  onChange: (role: UserRoleType) => void;
}

const UserRoleSelect: FC<UserRoleSelectProps> = ({
  selectedRole,
  onChange,
}) => {
  const userRoleOptions: RadioListOption<UserRoleType>[] = useMemo(
    () =>
      Object.values(UserRoleType).map((source) => ({
        value: source,
        displayName: userRoleDisplayNames[source],
      })),
    []
  );

  return (
    <RadioList
      onChange={onChange}
      options={userRoleOptions}
      selected={selectedRole}
      setPaywallModalOpen={() => {
        // No-op
      }}
    />
  );
};
