import { StorageKey } from '../../../scripts/storage';

export const isUserSetupDoneStoredInLocalStorage = (): boolean => {
  const value = localStorage.getItem(StorageKey.UserSetupDone);

  if (!value) return false;
  return JSON.parse(value) as boolean;
};

export const setUserSetupDoneInLocalStorage = (value: boolean): void => {
  localStorage.setItem(StorageKey.UserSetupDone, JSON.stringify(value));
};
