import { Message, Realtime, RealtimeChannel } from 'ably';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { QAMessage } from '../../models/QAmodels';
import { useQAController } from '../../scripts/QAController';
import { useFlag, useLoginTokens, useUserSafe } from '../../scripts/hooks';
import { logDebug, logError, logWarning } from '../../scripts/utils';
import {
  chunkString,
  getAblyRealtimeChatToken,
  getStringSizeInKB,
} from './utils';

export type onMessageAddedFn = (qaMessage: QAMessage) => void;

interface RealtimeChatContextProps {
  activeTopicId: string | null;
  broadcastQAMessage: onMessageAddedFn;
}

enum ChatMessageDataType {
  CHAT_MESSAGE_CHUNK = 'CHAT_MESSAGE_CHUNK',
}

interface ChatMessageChunkData {
  type: ChatMessageDataType.CHAT_MESSAGE_CHUNK;
  sender_email: string;
  message_chunk: string;
  meta: {
    total: number;
    index: number;
    topic_id: string;
    message_id: string;
  };
}

const RealtimeChatContext = React.createContext<
  RealtimeChatContextProps | undefined
>(undefined);

export const useRealtimeChat = (): RealtimeChatContextProps => {
  const ctx = React.useContext(RealtimeChatContext);
  if (!ctx) {
    throw new Error('Attempted to use context outside of scope');
  }

  return ctx;
};

export const RealtimeChatProvider: React.FC = ({ children }) => {
  const [ably, setAbly] = useState<Realtime | null>(null);
  const userEmail = useUserSafe((u) => u.email);
  const [activeTopicId, setActiveTopicId] = useState<string | null>(null);

  const idToken = useLoginTokens();
  const [ablyChatToken, setAblyChatToken] = useState<string | null>(null);
  const multiplayerEnabled = useFlag('multiplayerEnabled');
  const realtimeMultiplayerEnabled = useFlag('realtimeMultiplayer');

  const messageChunksRef = useRef<Record<string, ChatMessageChunkData[]>>({});

  const qaController = useQAController();
  const messages = qaController.useMessages();
  const currentTopicMessages = qaController.useCurrentTopicMessages();
  const currentConversationId = qaController.useCurrentTopicId();

  useEffect(() => {
    if (currentConversationId) {
      const qaMessage = qaController.getLatestQAMessage(
        messages,
        currentTopicMessages,
        currentConversationId
      );

      if (qaMessage?.topicAccess?.isMultiplayer) {
        setActiveTopicId(currentConversationId);
        return;
      }
    }

    setActiveTopicId(null);
  }, [currentConversationId, qaController, messages, currentTopicMessages]);

  useEffect(() => {
    async function getAblyToken() {
      if (idToken) {
        const ablyChatTokenRes = await getAblyRealtimeChatToken();
        setAblyChatToken(ablyChatTokenRes);
      }
    }

    if (multiplayerEnabled && realtimeMultiplayerEnabled) {
      getAblyToken();
    }
  }, [idToken, multiplayerEnabled, realtimeMultiplayerEnabled]);

  useEffect(() => {
    if (!activeTopicId || !userEmail || !ablyChatToken) {
      return;
    }

    let ablyClient: Realtime | null = null;
    let channel: RealtimeChannel | null = null;

    function initializeAbly() {
      try {
        if (!ablyChatToken) return;
        ablyClient = new Realtime({ token: ablyChatToken });

        ablyClient.connection.on('connected', () => {
          setAbly(ablyClient);
          logDebug('Connected to Ably');
        });

        ablyClient.connection.on('failed', () => {
          logError(new Error("'Failed to connect to Ably'"));
          setAbly(null);
        });

        channel = ablyClient.channels.get(activeTopicId!);

        channel.subscribe('chat', (message: Message) => {
          if (!message.data || typeof message.data !== 'object') return;
          if (
            (message.data as Record<string, string>).type !==
            ChatMessageDataType.CHAT_MESSAGE_CHUNK
          ) {
            return;
          }

          const data = message.data as ChatMessageChunkData;

          if (data.sender_email === userEmail) {
            return;
          }

          const topicId = data.meta.topic_id;

          if (topicId !== activeTopicId) {
            logWarning('Not broadcasting message to inactive topic');
            return;
          }

          const messageId = data.meta.message_id;
          const totalChunks = data.meta.total;

          // if there is a single chunk add it directly to the topic
          if (totalChunks === 1) {
            const qaMessage = JSON.parse(data.message_chunk) as QAMessage;
            qaController.addQAMessageToTopic(topicId, [qaMessage], true);
            qaController.markTopicAsRead(topicId);
            return;
          }

          // handle multiple chunks per message
          let messageChunks = messageChunksRef.current[messageId];
          if (!messageChunks) {
            messageChunks = [];
          }

          messageChunks.push(data);

          messageChunksRef.current[messageId] = messageChunks;

          // when all chunks are received assemble them and add to the topic
          if (messageChunks.length === totalChunks) {
            const rearrangedMessageChunks = messageChunks
              .sort((a, b) => a.meta.index - b.meta.index)
              .map((m) => m.message_chunk)
              .join('');

            const qaMessage = JSON.parse(rearrangedMessageChunks) as QAMessage;

            qaController.addQAMessageToTopic(topicId, [qaMessage], true);
            qaController.markTopicAsRead(topicId);
          }
        });
      } catch (error) {
        logError(error);
      }
    }

    initializeAbly();

    return () => {
      if (channel) {
        try {
          logDebug('Unsubscribing from Ably channel');
          channel.unsubscribe();
        } catch (error) {
          logError(error);
        }
      }

      if (ablyClient) {
        try {
          logDebug('Closing Ably client');
          ablyClient.close();
        } catch (error) {
          logError(error);
        }
      }

      setAbly(null);
    };
  }, [activeTopicId, userEmail, qaController, ablyChatToken]);

  const broadcastQAMessage = useCallback(
    (qaMessage: QAMessage) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { debugLogs, ...qaMessageWithoutDebugLogs } = qaMessage;

      if (
        !ably ||
        !activeTopicId ||
        !userEmail ||
        !qaMessageWithoutDebugLogs.topicAccess?.isMultiplayer
      ) {
        return;
      }

      if (activeTopicId !== qaMessageWithoutDebugLogs.conversation_id) return;

      const channel = ably.channels.get(activeTopicId);
      const stringifiedMessage = JSON.stringify(qaMessageWithoutDebugLogs);

      const messageSize = getStringSizeInKB(stringifiedMessage);

      const MAX_CHUNK_SIZE = 50;

      if (messageSize < MAX_CHUNK_SIZE) {
        const messageData: ChatMessageChunkData = {
          type: ChatMessageDataType.CHAT_MESSAGE_CHUNK,
          sender_email: userEmail,
          message_chunk: stringifiedMessage,
          meta: {
            total: 1,
            index: 0,
            message_id: qaMessageWithoutDebugLogs.row_id,
            topic_id: qaMessageWithoutDebugLogs.conversation_id,
          },
        };

        channel.publish('chat', messageData);
      } else {
        const chunks = chunkString(stringifiedMessage, MAX_CHUNK_SIZE);

        for (const [index, chunk] of chunks.entries()) {
          const messageData: ChatMessageChunkData = {
            type: ChatMessageDataType.CHAT_MESSAGE_CHUNK,
            sender_email: userEmail,
            message_chunk: chunk,
            meta: {
              total: chunks.length,
              index,
              message_id: qaMessageWithoutDebugLogs.row_id,
              topic_id: qaMessageWithoutDebugLogs.conversation_id,
            },
          };

          channel.publish('chat', messageData);
        }
      }
    },
    [userEmail, ably, activeTopicId]
  );

  const contextValue = useMemo(
    () => ({
      activeTopicId,
      broadcastQAMessage,
    }),
    [activeTopicId, broadcastQAMessage]
  );

  return (
    <RealtimeChatContext.Provider value={contextValue}>
      {children}
    </RealtimeChatContext.Provider>
  );
};
