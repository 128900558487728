import { UISelectItem } from '../../components/controls/ui/UISelect/UISelect';

export const ROLE_OPTIONS: UISelectItem[] = [
  {
    label: 'Member',
    value: 'member',
  },
  {
    label: 'Admin',
    value: 'admin',
  },
];
