import React, { FC, Fragment, useCallback, useEffect } from 'react';
import { PaywallModal } from '../../components/admin/EnterprisePaywall/EnterprisePaywall';
import { UIIcon } from '../../components/controls/ui/UIIcon/UIIcon';
import { QAFeedbackModal } from '../../components/jit-qa/QAFeedbackModal';
import { QAMessageView } from '../../components/jit-qa/QAMessageView';
import { QAPageAnswerView } from '../../components/jit-qa/QAPageView';
import { QAStreamingMessage } from '../../components/jit-qa/QAStreamingMessage';
import { QAFileDragNDrop } from '../../components/jit-qa/files/QAFileDragNDrop';
import { QATextInputView } from '../../components/jit-qa/textInputBox/QATextInputView';
import { LazyQAWorkflowTemplateModal } from '../../components/workflowModal';
import { trackEvent } from '../../extra/sharedMethods';
import { useQAController } from '../../scripts/QAController';
import { AnalyticsEvent } from '../../scripts/constants/analytics-event';
import { useBoolState } from '../../scripts/hooks';
import { useBots } from '../../scripts/hooks/bots';

export const SidePanelPage: FC = () => {
  const qaController = useQAController();
  const { bots } = useBots();

  const messages = qaController.useCurrentTopicMessages();
  const { isNewTopic } = qaController.getIsNewTopic();

  const [openPaywallModal, setOpenPaywallModal, setNotOpenPaywallModal] =
    useBoolState(false);

  const streamingAnswerStateMap = qaController.useStreamingAnswerStateMap();

  const latestMessageId =
    messages.length > 0 ? messages[messages.length - 1]?.message_id : undefined;

  const latestQAMessageStreamingState = latestMessageId
    ? streamingAnswerStateMap[latestMessageId]
    : undefined;

  const handleNewTopic = useCallback(() => {
    qaController.setCurrentTopicMessages([]);
  }, [qaController]);

  useEffect(() => {
    trackEvent(AnalyticsEvent.SidePanelOpened);
  }, []);

  return (
    <QAFileDragNDrop>
      <div className="flex h-screen">
        <div className="flex flex-1 flex-col h-full relative">
          {messages.length === 0 && (
            <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full flex flex-col justify-center items-center gap-4">
              <UIIcon name="dashworks" size={64} type="apps" />
              <div className="text-2xl font-semibold">
                How may I help you today?
              </div>
            </div>
          )}

          <div className="flex-grow overflow-auto scrollbar scrollbar-track-white scrollbar-thumb-gray-30 scrollbar-thin">
            <div className="max-w-3xl mx-auto mb-8 mt-2 w-[100vw]">
              <div>
                {messages.map((message) => (
                  <Fragment key={message.row_id}>
                    <QAMessageView
                      bots={bots}
                      isViewOnly={false}
                      key={message.row_id}
                      qaMessage={message}
                      sharableConversation={messages}
                    />
                    {message.sender === 'USER' && (
                      <QAStreamingMessage
                        bots={bots}
                        message_id={message.message_id}
                      />
                    )}
                  </Fragment>
                ))}
              </div>
            </div>
          </div>

          <div className="sticky bottom-0 bg-white">
            <QATextInputView
              disableDeepresearch
              disableNewTopicButton={
                isNewTopic || !!latestQAMessageStreamingState
              }
              disableNewTopicButtonReason={
                latestQAMessageStreamingState
                  ? 'Please wait for the current answer to finish generating'
                  : 'Already on a new topic'
              }
              hideRecommendedWorkflows
              newTopicCallback={handleNewTopic}
              setOpenPaywallModal={setOpenPaywallModal}
            />
          </div>
          <QAPageAnswerView />
          <LazyQAWorkflowTemplateModal
            setOpenPaywallModal={setOpenPaywallModal}
          />
          <QAFeedbackModal sharableConversation={messages} />
        </div>

        {openPaywallModal && (
          <PaywallModal allowUpgrade closeModal={setNotOpenPaywallModal} />
        )}
      </div>
    </QAFileDragNDrop>
  );
};
