import React, { useCallback } from 'react';
import { useToaster } from '../../../scripts/hooks';
import { isSquareImg } from '../../../scripts/utils';
import { ImagePicker, ImageType } from '../../admin/ImagePicker/ImagePicker';
import { UITextbox } from '../../controls/ui/UITextbox/UITextbox';

interface Props {
  companyName: string;
  setCompanyName: (name: string) => void;
  companyLogoUrl?: string;
  setCompanyLogoUrl: (url: string) => void;
}

export const OrgInfoSetup = ({
  companyName,
  setCompanyName,
  companyLogoUrl,
  setCompanyLogoUrl,
}: Props): JSX.Element => {
  const toaster = useToaster();

  const handleComplete = useCallback(
    async (url: string) => {
      const isSquare = await isSquareImg(url);
      if (!isSquare) {
        toaster.failure('Logo must be square');
        return;
      }

      setCompanyLogoUrl(url);
    },
    [setCompanyLogoUrl, toaster]
  );

  return (
    <div>
      <ImagePicker
        imageUrl={companyLogoUrl}
        maxFileSize={5 * 1024 * 1024}
        onComplete={handleComplete}
        subject="Logo"
        type={ImageType.Logo}
      >
        Change Logo
      </ImagePicker>
      <div className="w-full mt-5">
        <label
          className="block font-bold text-base mb-2"
          htmlFor="generalCompanyName"
        >
          Company Name
        </label>
        <UITextbox
          inputId="generalCompanyName"
          onChange={setCompanyName}
          placeholder="Enter company name"
          size="large"
          value={companyName}
        />
      </div>
    </div>
  );
};
