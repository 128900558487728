import React, { ReactNode, useCallback, useRef, useState } from 'react';
import { UITextbox } from '../../components/controls/ui/UITextbox/UITextbox';
import { withInstallModal } from '../../hoc/withInstallModal';
import { onSetFreshdeskToken } from '../../scripts/apis/updateFreshdeskCookie';
import { onSetFreshserviceToken } from '../../scripts/apis/updateFreshserviceCookie';
import { onSetHighspotToken } from '../../scripts/apis/updateHighspotCookie';
import { App } from '../../scripts/app';
import {
  EventSource,
  MessageAction,
} from '../../scripts/constants/message-action';
import { useLowerCase, useUserSafe } from '../../scripts/hooks';
import { logError } from '../../scripts/utils';
import { ConnectStep } from './Components';
import { EmailCaptureStep } from './EmailCaptureStep';
import { SubdomainCaptureStep } from './SubdomainCaptureStep';

interface SubdomainCaptureOptions {
  loginURL: string;
  hostname: string;
}

interface CredentialField {
  name: string;
  placeholder?: string;
}

interface SimpleAPIKeyInstallProps {
  instructionSteps: ReactNode;
  stepCount: number;
  app: App;
  credentialName?: string;
  credentialFields?: CredentialField[];
  subdomainCapture?: SubdomainCaptureOptions;
  emailCapture?: boolean;
  subdomain_override?: string;
  initialApiKeyInstructions?: ReactNode;
  additionalApiKeyInstructions?: ReactNode;
  teamSlug?: string;
  suppressApiError?: boolean;
  authFlag?: string;
  authSession?: string;
  disableConnect?: boolean;
}

export const SimpleAPIKeyInstall = withInstallModal<SimpleAPIKeyInstallProps>(
  ({
    connectInProgress,
    authFlag,
    authSession,
    app: { definition },
    instructionSteps,
    stepCount,
    credentialName = 'API key',
    credentialFields = [{ name: 'api_key', placeholder: 'Enter API key' }],
    subdomainCapture,
    emailCapture,
    subdomain_override,
    initialApiKeyInstructions,
    additionalApiKeyInstructions,
    teamSlug,
    suppressApiError,
    handleAPIInstall,
    disableConnect,
  }) => {
    const textboxRefs = useRef<(HTMLInputElement | null)[]>([]);

    const [credentials, setCredentials] = useState<Record<string, string>>({});
    const [apiError, setApiError] = useState('');
    const initialEmail = useUserSafe((u) => u.email);
    const [email, setEmail] = useState(emailCapture ? '' : initialEmail);

    const [subdomain, setSubdomain] = useLowerCase('');

    const setApiErrorMessage = useCallback(
      (message: string) => {
        if (suppressApiError) {
          return;
        }

        setApiError(message);
        if (message !== '') {
          textboxRefs.current[0]?.focus();
        }
      },
      [suppressApiError]
    );

    const handleCredentialChange = useCallback(
      (name: string, value: string) => {
        setCredentials((prev) => ({
          ...prev,
          [name]: value,
        }));
      },
      []
    );

    const handleClickDone = useCallback(async () => {
      try {
        await handleAPIInstall({
          username: email,
          ...credentials,
          subdomain: subdomain_override ?? subdomain,
          team_slug: teamSlug,
          auth_session: authSession,
          auth_flag: authFlag,
        });

        if (definition.shortname === 'highspot') {
          window.addEventListener('message', onSetHighspotToken);
          window.postMessage({
            action: MessageAction.RefreshHighspotCookie,
            source: EventSource.WEB_APP,
            payload: {
              domain: `${subdomain}.highspot.com`,
            },
          });
        }

        if (definition.shortname === 'freshdesk') {
          window.addEventListener('message', onSetFreshdeskToken);
          window.postMessage({
            action: MessageAction.RefreshFreshdeskCookie,
            source: EventSource.WEB_APP,
            payload: {
              domain: `${subdomain}.freshdesk.com`,
            },
          });
        }

        if (definition.shortname === 'freshservice') {
          window.addEventListener('message', onSetFreshserviceToken);
          window.postMessage({
            action: MessageAction.RefreshFreshserviceCookie,
            source: EventSource.WEB_APP,
            payload: {
              domain: `${subdomain}.freshservice.com`,
            },
          });
        }
      } catch (error) {
        logError(error);
        setApiErrorMessage(`Incorrect ${credentialName}. Please try again.`);
      }
    }, [
      handleAPIInstall,
      email,
      credentials,
      subdomain_override,
      subdomain,
      teamSlug,
      authSession,
      authFlag,
      definition.shortname,
      setApiErrorMessage,
      credentialName,
    ]);

    const connectDisabled =
      disableConnect ||
      Object.values(credentials).some((cred) => cred.trim() === '') ||
      (subdomainCapture && subdomain.trim() === '') ||
      email === '';

    const { displayName: name } = definition;

    return (
      <>
        {instructionSteps}

        {subdomainCapture && (
          <SubdomainCaptureStep
            hostname={subdomainCapture.hostname}
            loginURL={subdomainCapture.loginURL}
            name={name}
            onChange={setSubdomain}
            step={++stepCount}
            subdomain={subdomain}
          />
        )}

        {emailCapture && (
          <EmailCaptureStep
            onChange={setEmail}
            step={++stepCount}
            value={email}
          />
        )}

        <ConnectStep step={++stepCount} title={`Enter ${credentialName}`}>
          <p>
            {initialApiKeyInstructions}
            Enter the required credentials and click connect.
            {additionalApiKeyInstructions}
          </p>
          {credentialFields.map((field, index) => (
            <div className="stepField" key={field.name}>
              <UITextbox
                className="stepFieldInput"
                error={apiError}
                onChange={(value) => {
                  handleCredentialChange(field.name, value);
                }}
                placeholder={field.placeholder ?? `Enter ${field.name}`}
                ref={(el) => (textboxRefs.current[index] = el)}
                size="large"
                type="password"
                value={credentials[field.name] ?? ''}
              />
            </div>
          ))}
        </ConnectStep>
        {connectInProgress(handleClickDone, connectDisabled)}
      </>
    );
  }
);
