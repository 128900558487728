import './CopyButton.scss';
import classNames from 'classnames';
import React, { useCallback } from 'react';
import {
  useBoolState,
  useTimeoutCreator,
  useToaster,
} from '../../../../scripts/hooks';
import { logError } from '../../../../scripts/utils';
import { UIIconButton } from '../../ui/UIIconButton/UIIconButton';

interface CopyButtonProps {
  value?: string;
  onCopy?: () => void;
  size?: number;
  hoverEffect?: boolean;
  square?: boolean;
  subject?: string;
  domElementId?: string;
}

/**
 * https://stackoverflow.com/a/59462028
 * Copies a rendered html element (image, text, table) to clipboard with formatting preserved
 * @param elementId domNode id to be copied
 */
function copyElementToClipboard(elementId: string) {
  const element = document.getElementById(elementId);
  if (!element) {
    throw new Error(`Element with id:${elementId} not found`);
  }

  window.getSelection()?.removeAllRanges();
  const range = document.createRange();
  range.selectNode(element);
  window.getSelection()?.addRange(range);
  document.execCommand('copy');
  window.getSelection()?.removeAllRanges();
}

export const CopyButton: React.FC<CopyButtonProps> = ({
  value,
  onCopy,
  size,
  hoverEffect = true,
  square,
  subject = '',
  domElementId,
}) => {
  const [copied, setCopied, unsetCopied] = useBoolState(false);
  const setTimeout = useTimeoutCreator();
  const toaster = useToaster();

  const copyButtonClickHandler = useCallback(() => {
    if (copied) {
      return;
    }

    if (value) {
      navigator.clipboard.writeText(value).then(
        () => {
          setCopied();
          setTimeout(unsetCopied, 1500);

          onCopy?.();
        },
        () => {
          toaster.failure('Failed to copy to clipboard!');
        }
      );
    } else if (domElementId) {
      try {
        copyElementToClipboard(domElementId);
        setCopied();
        setTimeout(unsetCopied, 1500);
        onCopy?.();
      } catch (error) {
        logError(error);
        toaster.failure('Failed to copy to clipboard!');
      }
    }
  }, [
    copied,
    domElementId,
    onCopy,
    setCopied,
    setTimeout,
    toaster,
    unsetCopied,
    value,
  ]);

  return (
    <UIIconButton
      className={classNames('copyButton', {
        valueCopied: copied,
        hoverEffect,
        square,
      })}
      name={copied ? 'check' : 'copy'}
      onClick={copyButtonClickHandler}
      size={size}
      square={square}
      tooltip={copied ? 'Copied' : `Copy ${subject} to clipboard`}
    />
  );
};
