import { trackEvent } from '../../extra/sharedMethods';
import { AnalyticsEvent } from '../constants/analytics-event';
import { logError } from '../utils';
import { invokeFastApi } from './fastapi';

export const onSetFreshdeskToken = async (
  event: MessageEvent<{
    action: string;
    payload: { token: string };
  }>
): Promise<void> => {
  if (event.data.action !== 'dash-set-freshdesk-token') {
    return;
  }

  const { token } = event.data.payload;

  if (!token) {
    trackEvent(AnalyticsEvent.FreshdeskTokenNotFoundAfterInstall);
    return;
  }

  const path = '/freshdesk/setCookie';
  try {
    await invokeFastApi({
      path,
      method: 'POST',
      body: { token },
    });
  } catch (error) {
    logError(error);
  }

  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  window.removeEventListener('message', onSetFreshdeskToken);
};
