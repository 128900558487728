import React, { FC, useMemo } from 'react';
import { Bot } from '../../models/Bots';
import { QAStages } from '../../models/QAmodels';
import { useQAController } from '../../scripts/QAController';
import { QAMessageView } from './QAMessageView';

interface IQAStreamingMessage {
  bots: Bot[];
  showAllSources?: boolean;
  message_id: string;
}

export const QAStreamingMessage: FC<IQAStreamingMessage> = ({
  bots,
  showAllSources = false,
  message_id,
}) => {
  const qaController = useQAController();
  const streamingAnswerStateMap = qaController.useStreamingAnswerStateMap();

  const [streamingAnswerMessage, currentStage, isCompleted] = useMemo(() => {
    const streamingStateForCurrentMessage = streamingAnswerStateMap[message_id];
    if (!streamingStateForCurrentMessage) {
      return [null, null, null];
    }

    return [
      streamingStateForCurrentMessage.answerValue,
      streamingStateForCurrentMessage.currentStage,
      streamingStateForCurrentMessage.isCompleted,
    ];
  }, [streamingAnswerStateMap, message_id]);

  if (!streamingAnswerMessage || isCompleted) {
    return null;
  }

  return (
    <QAMessageView
      bots={bots}
      isAnswerStreaming={currentStage === QAStages.STREAMING_ANSWER}
      isLoading
      isViewOnly={false}
      qaMessage={streamingAnswerMessage}
      showAllSources={showAllSources}
    />
  );
};
