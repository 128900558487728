import React, { FC, useCallback, useMemo, useState } from 'react';
import { Bot } from '../../../models/Bots';
import { useMembers } from '../../../scripts/hooks/members';
import { MemberStatus, OrgMember } from '../../../scripts/models/org-member';
import { debounce } from '../../../scripts/utils';
import { ProfileInfoHover } from '../../bots/ProfileInfoHover';
import { Loading, LoadingSize } from '../../controls/Loading/Loading';
import { UserImage } from '../../controls/UserImage/UserImage';
import { UIIcon } from '../../controls/ui/UIIcon/UIIcon';
import { UITextbox } from '../../controls/ui/UITextbox/UITextbox';
import { UITooltip } from '../../controls/ui/UIToolTip/UIToolTip';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '../../shadcn/lib/components/popover';

interface MentionsSelectProps {
  bots: Bot[];
  botsLoading: boolean;
  setSelectedBot: (bot: Bot) => void;
  setSelectedMember: (member: OrgMember) => void;
  isBotSelected: boolean;
  isMemberSelected: boolean;
}

const MentionsSelectDashAi: FC<MentionsSelectProps> = ({
  bots,
  botsLoading,
  setSelectedBot,
  setSelectedMember,
  isBotSelected,
  isMemberSelected,
}) => {
  const [query, setQuery] = useState('');
  const {
    members,
    loading: membersLoading,
    fetchMembersByQuery,
  } = useMembers();

  const filteredBots = useMemo(() => {
    return bots.filter((bot) =>
      bot.bot_name.toLowerCase().includes(query.toLowerCase())
    );
  }, [bots, query]);

  const signedUpMembers = useMemo(() => {
    return members.filter((member) => member.status === MemberStatus.SIGNED_UP);
  }, [members]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetchMembers = useCallback(
    debounce((value: string) => {
      fetchMembersByQuery(value, MemberStatus.SIGNED_UP);
    }, 200),
    []
  );

  const handleSearch = useCallback(
    (value: string) => {
      setQuery(value);
      debouncedFetchMembers(value);
    },
    [debouncedFetchMembers]
  );

  return (
    <div className="max-h-80 overflow-y-auto cursor-auto custom-scrollbar">
      <UITextbox
        autoFocus
        onChange={handleSearch}
        placeholder="Search members or bots"
        value={query}
      />

      {!isBotSelected && (
        <>
          <div className="flex justify-between p-2 pb-1">
            <div className="text-sm text-gray-50 font-medium">People</div>
          </div>
          {membersLoading ? (
            <Loading size={LoadingSize.Small} />
          ) : // eslint-disable-next-line unicorn/no-nested-ternary
          signedUpMembers.length > 0 ? (
            signedUpMembers.slice(0, 5).map((member) => {
              return (
                <div
                  className="flex items-center px-3 py-2 gap-2 hover:bg-cloud-10 cursor-pointer"
                  key={member.user_id}
                  onClick={() => {
                    setSelectedMember(member);
                  }}
                >
                  <div className="max-h-5">
                    <UserImage
                      displayName={member.name}
                      imageUrl={member.icon}
                      size={20}
                    />
                  </div>
                  {member.name ?? member.email}
                </div>
              );
            })
          ) : (
            <div className="text-gray-50 text-sm text-center">
              No people found
            </div>
          )}
        </>
      )}

      {!isBotSelected && !isMemberSelected && (
        <>
          <div className="flex justify-between p-2 pb-1">
            <div className="text-sm text-gray-50 font-medium">Bots</div>
          </div>
          {botsLoading ? (
            <Loading size={LoadingSize.Small} />
          ) : // eslint-disable-next-line unicorn/no-nested-ternary
          filteredBots.length > 0 ? (
            filteredBots.slice(0, 5).map((bot) => {
              return (
                <div
                  className="flex items-center px-3 py-2 gap-2 hover:bg-cloud-10 cursor-pointer"
                  key={bot.id}
                  onClick={() => {
                    setSelectedBot(bot);
                  }}
                >
                  <span className="text-xl/5">{bot.icon}</span>
                  {bot.bot_name}
                  {bot.description && (
                    <ProfileInfoHover
                      description={bot.description}
                      icon={bot.icon}
                      name={bot.bot_name}
                    />
                  )}
                </div>
              );
            })
          ) : (
            <div className="text-gray-50 text-sm text-center">
              No bots found
            </div>
          )}
        </>
      )}
    </div>
  );
};

interface MentionsSelectButtonProps {
  disabled?: boolean;
  bots: Bot[];
  botsLoading: boolean;
  setSelectedBot: (bot: Bot) => void;
  setSelectedMember: (member: OrgMember) => void;
  isBotSelected: boolean;
  isMemberSelected: boolean;
}

export const MentionsSelectButtonDashAi: FC<MentionsSelectButtonProps> = ({
  disabled,
  bots,
  botsLoading,
  setSelectedBot,
  setSelectedMember,
  isBotSelected,
  isMemberSelected,
}) => {
  const [openSelect, setOpenSelect] = useState(false);

  const handleSelectBot = useCallback(
    (bot: Bot) => {
      setSelectedBot(bot);
      setOpenSelect(false);
    },
    [setSelectedBot, setOpenSelect]
  );

  const handleSelectMember = useCallback(
    (member: OrgMember) => {
      setSelectedMember(member);
      setOpenSelect(false);
    },
    [setSelectedMember, setOpenSelect]
  );

  const handleOpenSelect = useCallback(() => {
    if (disabled) {
      setOpenSelect(false);
      return;
    }

    setOpenSelect((prev) => !prev);
  }, [disabled, setOpenSelect]);

  return (
    <Popover onOpenChange={handleOpenSelect} open={openSelect}>
      <PopoverTrigger asChild disabled={disabled}>
        <div>
          <UITooltip title="Select members or bots">
            <div
              className={`flex items-center hover:bg-cloud-10 p-2 rounded-md cursor-pointer border-gray-30 border-solid border ${
                disabled ? 'opacity-50' : ''
              }`}
            >
              <UIIcon name="at-sign" size={16} stroke />
            </div>
          </UITooltip>
        </div>
      </PopoverTrigger>
      <PopoverContent align="center" className="!p-0">
        <MentionsSelectDashAi
          bots={bots}
          botsLoading={botsLoading}
          isBotSelected={isBotSelected}
          isMemberSelected={isMemberSelected}
          setSelectedBot={handleSelectBot}
          setSelectedMember={handleSelectMember}
        />
      </PopoverContent>
    </Popover>
  );
};
