import React, { FC } from 'react';
import { trackEvent } from '../../../../extra/sharedMethods';
import { AllReferencesSummary, QAMessage } from '../../../../models/QAmodels';
import {
  QAControllerEventArgs,
  useQAController,
} from '../../../../scripts/QAController';
import { AnalyticsEvent } from '../../../../scripts/constants/analytics-event';
import { UIIcon } from '../../../controls/ui/UIIcon/UIIcon';
import { ReferenceIcon } from '../QAReferenceUtil';

export const ShowAllReferencesItem: FC<{
  answerId: string;
  allReferencesSummary: AllReferencesSummary;
  topicMessages?: QAMessage[];
  messageId?: string;
}> = ({ answerId, allReferencesSummary, topicMessages, messageId }) => {
  const qaController = useQAController();
  const parentMessage = messageId
    ? qaController.getUserMessageByMessageId(messageId)
    : qaController.getUserMessageForAssistantAnswer(answerId, topicMessages);

  const forceShow =
    parentMessage?.deepresearch &&
    !parentMessage.isAnswerNotLoadedAndIsNotStreaming;

  let { app_references_count } = allReferencesSummary;

  if (app_references_count === undefined) {
    app_references_count = [];
  }

  if (!forceShow && app_references_count.length === 0) {
    return null;
  }

  const appCount = window.innerWidth < 768 ? 1 : 3;

  return (
    <div
      // eslint-disable-next-line max-len
      className="w-[44%] sm:w-[30%] py-1 px-1 sm:px-2 flex items-center gap-1.5 border-gray-30 border border-solid rounded neumorphic-background hover:bg-none hover:bg-purple-10 hover:border-purple-30 cursor-pointer"
      onClick={() => {
        const body: Partial<QAControllerEventArgs> = {
          allReferencesSummary,
          query_id: parentMessage?.row_id,
        };

        if (parentMessage?.deepresearch) {
          body.deepresearchData = {
            deepResearch: true,
            messageId: parentMessage.message_id,
          };
        }

        trackEvent(AnalyticsEvent.QASeeAllSourcesClicked, body);

        qaController.triggerEvent('hideAppsNotifications', {});
        qaController.triggerEvent('showReferencesSidecar', body);
      }}
    >
      <div className="flex">
        {app_references_count.slice(0, appCount).map((app_count) => (
          <div
            className="flex-shrink-0 flex items-center justify-center rounded-full"
            key={app_count.appName}
          >
            <ReferenceIcon size={16} source={app_count.appName} />
          </div>
        ))}
        {app_references_count.length > appCount && (
          <div className="flex-shrink-0 bg-white w-5 h-5 flex items-center justify-center rounded-full text-2xs font-medium">
            {Math.min(app_references_count.length - appCount, 9)}+
          </div>
        )}
        {app_references_count.length === 0 && parentMessage?.deepresearch && (
          <div className="flex-shrink-0 flex items-center justify-center rounded-full py-[2px]">
            <UIIcon name="files" size={16} />
          </div>
        )}
      </div>
      <div className="text-xs truncate">Explore more</div>
    </div>
  );
};
