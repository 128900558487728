import React, { FC } from 'react';
import { InviteMembersList } from './InviteMembersList';
import { MembersSelect } from './MembersSelect';
import { InviteMember, UpdateMemberRoleFn } from './types';

interface InviteMembersContentProps {
  selectedMembers: InviteMember[];
  membersToSendNewInvite: InviteMember[];
  membersToResendInvite: InviteMember[];
  selectMember: (member: InviteMember) => void;
  removeMember: (email: string) => void;
  updateMemberRole: UpdateMemberRoleFn;
  headingSize: 'lg' | 'sm';
  canShowOptionsInBottom?: boolean;
  placeholder?: string;
  doNotLoadMembers?: boolean;
}

export const InviteMembersContent: FC<InviteMembersContentProps> = ({
  selectedMembers,
  membersToSendNewInvite,
  membersToResendInvite,
  selectMember,
  removeMember,
  updateMemberRole,
  placeholder,
  doNotLoadMembers,
  headingSize = 'lg',
  canShowOptionsInBottom = false,
}) => {
  return (
    <>
      <MembersSelect
        canShowOptionsInBottom={canShowOptionsInBottom}
        doNotLoadMembers={doNotLoadMembers}
        onMemberSelect={selectMember}
        placeholder={placeholder}
        selectedMembers={selectedMembers}
      />
      <div className="overflow-y-auto max-h-[200px] mt-4 px-1">
        <InviteMembersList
          headingSize={headingSize}
          members={membersToSendNewInvite}
          onRemove={removeMember}
          title="Invite"
          updateMemberRole={updateMemberRole}
        />
        <InviteMembersList
          headingSize={headingSize}
          members={membersToResendInvite}
          onRemove={removeMember}
          title="Resend invite"
          updateMemberRole={updateMemberRole}
        />
      </div>
    </>
  );
};
