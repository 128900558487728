import LRU from 'lru-cache';
import { invokeAWSGatewayAPI } from './aws';

export interface PreviewData {
  content: string;
  from: string;
  to: string;
  type: string;
  content_type: string;
  full_url?: string;
}

interface PreviewDataParams {
  objectId: string;
  threadId?: string;
}

const CACHE_PREVIEW_DATA = new LRU<string, PreviewData>({
  max: 50,
  ttl: 1000 * 60 * 2,
  // 2 min
});

/**
 * Get preview data for a result.
 */
export const getPreviewData = async (
  query: PreviewDataParams
): Promise<PreviewData> => {
  const cacheKey = `${query.objectId}-${query.threadId ?? ''};`;
  const cached = CACHE_PREVIEW_DATA.get(cacheKey);
  if (cached) {
    return cached;
  }

  const { data } = await invokeAWSGatewayAPI<PreviewData>({
    path: '/preview',
    method: 'GET',
    query: query as unknown as Record<string, number | string>,
    shouldRetry: true,
  });

  CACHE_PREVIEW_DATA.set(cacheKey, data);
  return data;
};
