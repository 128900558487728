import './ImagePicker.scss';
import React, { useCallback, useState } from 'react';
import { useToaster, useUserSafe } from '../../../scripts/hooks';
import { logError, PromiseOr } from '../../../scripts/utils';
import { UIButton, UIButtonProps } from '../../controls/ui/UIButton/UIButton';

const failureMessage =
  'There was a problem saving your image. Please try again.';

export enum ImageType {
  Background = 'background',
  Logo = 'logo',
}

interface ImagePickerProps {
  buttonProps?: UIButtonProps;
  maxFileSize: number;
  subject: string;
  imageUrl?: string;
  showPreview?: boolean;
  type: ImageType;
  transforms?: string[];
  disabled?: boolean;
  onComplete(url: string): PromiseOr<void>;
}

const fileStackOpts = {
  fromSources: [
    'local_file_system',
    'url',
    // eslint-disable-next-line @cspell/spellchecker
    'imagesearch',
    'facebook',
    'instagram',
    // eslint-disable-next-line @cspell/spellchecker
    'googledrive',
    'dropbox',
    'unsplash',
  ],
  accept: 'image/*',
};

export const ImagePicker: React.FC<ImagePickerProps> = ({
  imageUrl,
  maxFileSize,
  disabled,
  subject,
  onComplete,
  showPreview = true,
  type,
  transforms,
  children,
  buttonProps,
}) => {
  const toaster = useToaster();
  const [invalidImage, setInvalidImage] = useState(false);
  const lowerSubject = subject.toLowerCase();
  const org = useUserSafe((u) => u.orgByOrgId.domain);

  const handleSelectFile = useCallback(async () => {
    const filestack = await import('filestack-js');
    const client = filestack.init(FILESTACK_API_KEY);

    client
      .picker({
        ...fileStackOpts,
        uploadConfig: {
          tags: {
            type,
            org,
          },
        },
        maxSize: maxFileSize,
        onUploadDone: (response) => {
          const [newFile] = response.filesUploaded;
          if (newFile) {
            const url = new URL(newFile.url);
            if (transforms) {
              url.pathname = `/${transforms.join('/')}${url.pathname}`;
            }

            toaster
              .withPromise(
                Promise.resolve(onComplete(url.href)),
                `${subject} saved`,
                failureMessage
              )
              .catch(logError);
          } else {
            toaster.failure(failureMessage);
          }
        },
      })
      .open()
      .catch(logError);
  }, [maxFileSize, onComplete, org, subject, toaster, transforms, type]);

  const onImageMissing = useCallback(() => {
    setInvalidImage(true);
  }, []);

  return (
    <div className="imagePicker">
      {showPreview && (
        <div className="imagePreview" onClick={handleSelectFile}>
          {imageUrl && !invalidImage ? (
            <img onError={onImageMissing} src={imageUrl} />
          ) : (
            <div className="imagePickerLabel">Your {lowerSubject}</div>
          )}
        </div>
      )}
      <UIButton {...buttonProps} disabled={disabled} onClick={handleSelectFile}>
        {children}
      </UIButton>
    </div>
  );
};
