import { LinearProgress } from '@mui/material';
import classNames from 'classnames';
import moment from 'moment';
import React, { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import './QAMessageView.scss';
import { ctorMap } from '../../apps';
import { useAppDefinitions } from '../../apps/definition';
import dashworksLogo from '../../assets/images/dashworks_logo.svg';
import { trackEvent } from '../../extra/sharedMethods';
import { Bot } from '../../models/Bots';
import {
  QAMessage,
  SkillRetrieved,
  StaticAnswerType,
} from '../../models/QAmodels';
import { useQAController } from '../../scripts/QAController';
import { AnalyticsEvent } from '../../scripts/constants/analytics-event';
import { useFlag } from '../../scripts/hooks';
import { logError } from '../../scripts/utils';
import { ProfileInfoHover } from '../bots/ProfileInfoHover';
import { ExpandableList } from '../controls/ui/ExpandableList/ExpandableList';
import { UIIcon } from '../controls/ui/UIIcon/UIIcon';
import { UITooltip } from '../controls/ui/UIToolTip/UIToolTip';
import { AnswerIcon } from '../controls/ui/icons/icons';
import { QaLoadingSkeletons } from '../general/Skeletons/QASkeleton';
import { TOUR_QA_MESSAGE_CLASS } from '../onboarding/OnboardingTour/utils';
import { MarkdownParser } from '../text/MarkdownParser';
import { QAActionRow } from './QAActionRow';
import { LazyQADebugLogs } from './QADebugLogs';
import { QAAttachedFiles } from './files/QAAttachedFiles';
import { QAOnboardingMessage } from './onboarding/QAOnboardingMessage';
import { InlineLink } from './references/QAInlineReference';
import { QAReferencesList } from './references/QAReferencesList';

interface QAMessageViewProps {
  qaMessage: QAMessage | undefined;
  refRelatedCollapsed?: boolean;
  isLoading?: boolean;
  isAnswerStreaming?: boolean;
  sharableConversation?: QAMessage[];
  showAllSources?: boolean;
  bots: Bot[];
  isFocused?: boolean;
  isViewOnly: boolean;
}

export const getInitials = (displayName: string | undefined): string => {
  if (!displayName) return '';

  const nameWords = displayName.trim().split(/\s+/);
  const firstInitial = nameWords[0]?.[0]?.toUpperCase() ?? '';
  const lastInitial =
    nameWords.length > 1
      ? nameWords[nameWords.length - 1]?.[0]?.toUpperCase() ?? ''
      : '';

  return firstInitial + lastInitial;
};

// eslint-disable-next-line max-lines-per-function
export const QAMessageView: FC<QAMessageViewProps> = ({
  qaMessage,
  refRelatedCollapsed = false,
  isLoading = false,
  isAnswerStreaming = false,
  sharableConversation,
  showAllSources = false,
  bots,
  isFocused,
  isViewOnly,
}) => {
  const messageContainerRef = useRef<HTMLDivElement>(null);

  const userPhotoComponent = useMemo(() => {
    const author = qaMessage?.author;

    if (author?.icon) {
      return (
        <img
          alt={author.display_name ?? 'User'}
          className="w-8 object-cover rounded-[50%]"
          src={author.icon}
        />
      );
    }

    const displayInitials = getInitials(
      author?.display_name ?? author?.email.split('@')[0]
    );

    if (displayInitials) {
      return <div className="userInitials">{displayInitials}</div>;
    }

    return <UIIcon name="person" size={32} />;
  }, [qaMessage?.author]);

  const assistantPhotoComponent = useMemo(() => {
    if (qaMessage?.bot_id) {
      const botIconLarge = (
        <div className="text-[32px] leading-8">
          {qaMessage.bot_icon ?? '🤖'}
        </div>
      );

      const bot = bots.find((b) => b.id === qaMessage.bot_id);

      if (qaMessage.bot_name) {
        return (
          <ProfileInfoHover
            customTriggerIcon={botIconLarge}
            description={bot?.description ?? ''}
            icon={botIconLarge}
            name={qaMessage.bot_name}
          />
        );
      }

      return (
        <UITooltip title="This bot has been deleted.">{botIconLarge}</UITooltip>
      );
    }

    return <img alt="Dashworks" className="w-8" src={dashworksLogo} />;
  }, [qaMessage?.bot_id, qaMessage?.bot_icon, qaMessage?.bot_name, bots]);

  const userMessageComponent = useMemo(() => {
    const renderRawTextPart = (part: string, key: number | string) => {
      if (part.includes('\n')) {
        return (
          <React.Fragment key={key}>
            {part.split('\n').map((line, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <React.Fragment key={index}>
                {line}
                <br />
              </React.Fragment>
            ))}
          </React.Fragment>
        );
      }

      return <React.Fragment key={key}>{part}</React.Fragment>;
    };

    const renderUserMentionPatternPart = (
      part: string,
      key: number | string
    ) => {
      const match = /{{(.*?)}}/.exec(part);
      const firstPart = match ? match[1] : part;

      return (
        <span
          className="bg-[#C9FFFF] p-1 pt-[1px] rounded-md text-black"
          key={key}
        >
          @{firstPart}
        </span>
      );
    };

    if (qaMessage?.bot_id && qaMessage.bot_name) {
      const botPattern = `@${qaMessage.bot_name}`;
      const botRegex = new RegExp(`(${botPattern})`, 'g');
      const parts = qaMessage.messageText.split(botRegex);

      return (
        <div>
          {parts.map((part, i) =>
            part === botPattern ? (
              <span
                className="bg-[#C9FFFF] p-1 pt-[1px] rounded-md"
                // eslint-disable-next-line react/no-array-index-key
                key={`part_${i}`}
              >
                {part}
              </span>
            ) : (
              renderRawTextPart(part, `part_${i}`)
            )
          )}
        </div>
      );
    }

    const userMentionPattern = '@{{.*?}}{{.*?}}';
    const userMentionPatternRegex = new RegExp(`(${userMentionPattern})`, 'g');
    const messageText = qaMessage?.messageText ?? '';

    if (messageText) {
      try {
        const parts = messageText.split(userMentionPatternRegex);

        return (
          <div>
            {parts.map((part, i) =>
              userMentionPatternRegex.test(part)
                ? renderUserMentionPatternPart(part, `part_${i}`)
                : renderRawTextPart(part, `part_${i}`)
            )}
          </div>
        );
      } catch (error) {
        logError('Error parsing custom pattern', error);
      }
    }

    return renderRawTextPart(messageText, 'messageText');
  }, [qaMessage?.bot_id, qaMessage?.messageText, qaMessage?.bot_name]);

  const relativeTimeString = moment(qaMessage?.tsSentAt).fromNow();
  const absoluteTimeString = moment(qaMessage?.tsSentAt).toLocaleString();

  const showRelatedSearches = !useFlag('hideRelatedSearches');

  const isOnboardingMessage = useMemo(
    () =>
      qaMessage?.extraData?.staticAnswerType === StaticAnswerType.ONBOARDING,
    [qaMessage]
  );

  const handleCopy = useCallback(() => {
    const message_id = qaMessage?.message_id;
    const row_id = qaMessage?.row_id;
    if (qaMessage?.sender === 'ASSISTANT') {
      trackEvent(AnalyticsEvent.QAMessageResponseUserCopiedText, {
        message_id,
        row_id,
      });
    } else {
      trackEvent(AnalyticsEvent.QAMessageQueryUserCopiedText, {
        message_id,
        row_id,
      });
    }
  }, [qaMessage?.sender, qaMessage?.message_id, qaMessage?.row_id]);

  useEffect(() => {
    if (isFocused) {
      messageContainerRef.current?.scrollIntoView();
    }
  }, [isFocused, qaMessage?.messageText]);

  if (!qaMessage) {
    return null;
  }

  return (
    <div
      className={classNames(
        'qaMessageView sm:max-w-3xl max-w-full sm:mx-auto mx-2 break-words scroll-mt-4',
        {
          botMessage: qaMessage.sender === 'ASSISTANT',
          'userMessage neumorphic-background border border-gray-20 border-solid rounded-lg':
            qaMessage.sender !== 'ASSISTANT',
          'mt-4': qaMessage.sender === 'USER',
          focused: isFocused,
        }
      )}
      data-ts-sent-at={qaMessage.tsSentAt}
      ref={messageContainerRef}
    >
      {qaMessage.isPreFinalGeneration && (
        <div className="centeredUnit px-1">
          <LinearProgress
            className="linearProgress"
            sx={{ height: '1px', bottom: '1.5px' }}
            value={qaMessage.progressBar}
            variant="determinate"
          />
        </div>
      )}

      {isOnboardingMessage ? (
        <QAOnboardingMessage
          sharable={
            sharableConversation !== undefined &&
            sharableConversation.length > 0
          }
        />
      ) : (
        <div
          className={classNames(
            'qaMessage relative sm:max-w-3xl max-w-full sm:mx-auto mx-2 px-4 pt-4',
            {
              'pb-4': qaMessage.sender === 'USER',
              'py-2':
                qaMessage.sender === 'USER' &&
                qaMessage.files &&
                qaMessage.files.length > 0,
            },
            TOUR_QA_MESSAGE_CLASS
          )}
        >
          {qaMessage.sender === 'ASSISTANT' && (
            <div className="qaAuthorIcon">{assistantPhotoComponent}</div>
          )}

          {qaMessage.sender !== 'ASSISTANT' &&
            (qaMessage.author ? (
              <ProfileInfoHover
                customTriggerIcon={
                  <div className="qaAuthorIcon">{userPhotoComponent}</div>
                }
                icon={userPhotoComponent}
                name={qaMessage.author.display_name ?? qaMessage.author.email}
              />
            ) : (
              <div className="qaAuthorIcon">{userPhotoComponent}</div>
            ))}

          <div className="qaMessageContentToolsRelated  w-[80%]">
            <div className="qaMessageContentTools">
              <div
                className={classNames('qaMessageContent', {
                  loadingText: isLoading && !isAnswerStreaming,
                  streaming: isAnswerStreaming,
                })}
                onCopy={handleCopy}
              >
                {qaMessage.skillRetrieved && !qaMessage.deepresearch && (
                  <SkillRetrievedLoadingComponent
                    appName={qaMessage.skillRetrieved.appName}
                    numDocs={qaMessage.skillRetrieved.numDocs}
                  />
                )}

                {qaMessage.sender === 'ASSISTANT' ? (
                  <MarkdownParser
                    // eslint-disable-next-line react/no-unstable-nested-components
                    customAnchorElement={(props) => (
                      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                      <InlineLink props={props} qaMessage={qaMessage} />
                    )}
                    text={qaMessage.messageText}
                  />
                ) : (
                  userMessageComponent
                )}
              </div>
              <div
                className={classNames('qaMessageTools', {
                  top: qaMessage.sender === 'ASSISTANT',
                  center: qaMessage.sender === 'USER',
                })}
              >
                {qaMessage.sender === 'USER' && (
                  <div className="timeDisplay" title={absoluteTimeString}>
                    {relativeTimeString}
                  </div>
                )}
              </div>
            </div>
            {qaMessage.sender === 'USER' &&
              qaMessage.files &&
              qaMessage.files.length > 0 && (
                <div className="pt-4 mr-3">
                  <QAAttachedFiles files={qaMessage.files} />
                </div>
              )}
            {qaMessage.isPreFinalGeneration && <QaLoadingSkeletons />}
            {qaMessage.sender === 'ASSISTANT' && (
              <div>
                {(qaMessage.deepresearch ||
                  qaMessage.references.length > 0) && (
                  <QAReferencesList
                    allReferencesSummary={qaMessage.allReferencesSummary}
                    answerId={qaMessage.row_id}
                    citedReferences={qaMessage.references}
                    deepresearch={qaMessage.deepresearch}
                    messageId={qaMessage.message_id}
                    showAllSources={showAllSources}
                    topicMessages={sharableConversation}
                  />
                )}
                {!isLoading &&
                  !isAnswerStreaming &&
                  !isViewOnly &&
                  !qaMessage.isAnswerNotLoadedAndIsNotStreaming && (
                    <QAActionRow
                      qaMessage={qaMessage}
                      sharableConversation={sharableConversation}
                    />
                  )}
                {showRelatedSearches && (
                  <ExpandableList
                    ItemComponent={RelatedSearchesItem}
                    className="mt-2"
                    defaultCollapsed={refRelatedCollapsed}
                    items={qaMessage.relatedSearches}
                    title="Related"
                  />
                )}
                <ExpandableList
                  ItemComponent={LazyQADebugLogs}
                  className="mt-2"
                  defaultCollapsed
                  items={qaMessage.debugLogs?.stages ?? []}
                  maxItems={100}
                  title="Debug"
                />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

const RelatedSearchesItem: FC<{ item: string; index: number }> = ({
  item: relatedSearch,
  index,
}) => {
  const qaController = useQAController();

  const onRelatedSearchClick = useCallback(() => {
    trackEvent(
      AnalyticsEvent.QAMessageRelatedSearchClicked,
      {
        position: index + 1,
      },
      {
        relatedSearch,
      }
    );

    qaController.triggerEvent('setQuery', { query: relatedSearch });
  }, [qaController, relatedSearch, index]);

  return (
    <li
      className={classNames('relatedSearchItem')}
      key={relatedSearch}
      onClick={onRelatedSearchClick}
    >
      {relatedSearch}
      <UIIcon name="keyboard-right" />
    </li>
  );
};

const SkillRetrievedLoadingComponent: FC<SkillRetrieved> = ({ appName }) => {
  const definitions = useAppDefinitions();

  let icon = null;
  let displayName = '';
  switch (appName) {
    case 'verified_answers': {
      displayName = 'Verified Answers';
      icon = <AnswerIcon size={20} />;

      break;
    }

    case 'files': {
      displayName = 'Files';
      icon = <UIIcon name="doc" />;

      break;
    }

    case 'web': {
      displayName = 'Web';
      icon = <UIIcon name="globe" />;

      break;
    }

    default: {
      const AppConstructor = ctorMap.get(appName);
      const definition = definitions[appName];

      if (AppConstructor && definition) {
        const app = new AppConstructor(definition);
        icon = <UIIcon name={app.definition.shortname} type="apps" />;
        ({ displayName } = definition);
      }
    }
  }

  return (
    <div>
      <span>Found results in</span> {icon && <span>{icon}</span>}{' '}
      <span>{displayName}...</span>
    </div>
  );
};
