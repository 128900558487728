import classNames from 'classnames';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { RadioList, RadioListOption } from '../../controls/RadioList/RadioList';
import { UIIcon } from '../../controls/ui/UIIcon/UIIcon';
import { UITextbox } from '../../controls/ui/UITextbox/UITextbox';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '../../shadcn/lib/components/popover';

export enum ReferralSourceType {
  SEARCH_ENGINE = 'SEARCH_ENGINE',
  SOCIAL_MEDIA = 'SOCIAL_MEDIA',
  NEWSLETTER = 'NEWSLETTER',
  ANOTHER_AI_TOOL = 'ANOTHER_AI_TOOL',
  ONLINE_ADS = 'ONLINE_ADS',
  OTHER = 'OTHER',
}

const referralSourceDisplayNames: Record<ReferralSourceType, string> = {
  [ReferralSourceType.SEARCH_ENGINE]: 'Search Engine',
  [ReferralSourceType.SOCIAL_MEDIA]: 'Social Media',
  [ReferralSourceType.NEWSLETTER]: 'Newsletter',
  [ReferralSourceType.ANOTHER_AI_TOOL]: 'Another AI Tool',
  [ReferralSourceType.ONLINE_ADS]: 'Online Ads',
  [ReferralSourceType.OTHER]: 'Other',
};

interface ReferralSourceSelectProps {
  selectedSource: ReferralSourceType;
  onChange: (source: ReferralSourceType) => void;
}

const ReferralSourceSelect: FC<ReferralSourceSelectProps> = ({
  selectedSource,
  onChange,
}) => {
  const referralSourceOptions: RadioListOption<ReferralSourceType>[] = useMemo(
    () =>
      Object.values(ReferralSourceType).map((source) => ({
        value: source,
        displayName: referralSourceDisplayNames[source],
      })),
    []
  );

  return (
    <RadioList
      onChange={onChange}
      options={referralSourceOptions}
      selected={selectedSource}
      setPaywallModalOpen={() => {
        //
      }}
    />
  );
};

interface Props {
  selectedSource: ReferralSourceType;
  setSelectedSource: React.Dispatch<React.SetStateAction<ReferralSourceType>>;
  otherSource: string;
  setOtherSource: React.Dispatch<React.SetStateAction<string>>;
}

export const UserReferralSourceSelect: FC<Props> = ({
  selectedSource,
  setSelectedSource,
  otherSource,
  setOtherSource,
}) => {
  const [openPopover, setOpenPopover] = useState(false);

  const onChange = useCallback((source: ReferralSourceType) => {
    setSelectedSource(source);
    setOpenPopover(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div className="block font-bold text-base mb-2">
        How did you hear about Dashworks?
      </div>
      <Popover onOpenChange={setOpenPopover} open={openPopover}>
        <PopoverTrigger asChild>
          <div className="border border-solid border-gray-30 focus:border-gray-50 rounded-md px-[17px] pt-[11px] pb-[10px] flex justify-between items-center cursor-pointer">
            <div>{referralSourceDisplayNames[selectedSource]}</div>
            <div>
              <UIIcon name="arrow-down" size={20} />
            </div>
          </div>
        </PopoverTrigger>
        <PopoverContent
          className={classNames('z-[201] w-[80vw] max-w-[472px] !p-2')}
          onMouseDown={() => {
            setOpenPopover(false);
          }}
        >
          <div>
            <ReferralSourceSelect
              onChange={onChange}
              selectedSource={selectedSource}
            />
          </div>
        </PopoverContent>
      </Popover>

      <div
        className={classNames(
          {
            hidden: selectedSource !== ReferralSourceType.OTHER,
          },
          'mt-2'
        )}
      >
        <UITextbox
          onChange={(e) => {
            setOtherSource(e);
          }}
          placeholder="Enter how did you hear about Dashworks"
          size="large"
          value={otherSource}
        />
      </div>
    </div>
  );
};
