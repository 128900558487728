import { useCallback, useEffect, useState } from 'react';
import { trackEvent } from '../../extra/sharedMethods';
import { invokeFastApi } from '../apis/fastapi';
import { AnalyticsEvent } from '../constants/analytics-event';
import { useResetHistoryPush } from '../page-search/result-utils';
import { logError } from '../utils';
import { useToaster, useUserSafe } from '.';

/**
 * Prevent non-admin users from reaching page.
 */
export const useAdminGuard = (): void => {
  const user = useUserSafe();
  const reset = useResetHistoryPush();

  useEffect(() => {
    if (!user.admin) {
      reset('/');
    }
  }, [reset, user]);
};

interface AiControlsResponse {
  custom_instructions: string;
  disclaimer: string;
}

interface AiControlsHookResponse {
  aiControlsData: AiControlsResponse | undefined;
  loading: boolean;
  error: boolean;
  getAiControls: () => Promise<void>;
  updateCustomInstructions: ({
    customInstructions,
  }: {
    customInstructions: string;
  }) => Promise<void>;
  updateDisclaimer: ({ disclaimer }: { disclaimer: string }) => Promise<void>;
}

export const useAiControls = (): AiControlsHookResponse => {
  const [aiControlsData, setAiControlsData] = useState<AiControlsResponse>();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const toaster = useToaster();

  const getAiControls = async () => {
    setLoading(true);
    setError(false);

    try {
      const res = await invokeFastApi({
        path: '/ai_controls',
        method: 'GET',
        shouldRetry: true,
      });

      const data = (await res) as AiControlsResponse;
      setAiControlsData(data);
    } catch (fetchError) {
      logError(fetchError);
      setError(true);
      toaster.failure('Failed to fetch AI controls');
    }

    setLoading(false);
  };

  useEffect(() => {
    getAiControls();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateDisclaimer = useCallback(
    async ({ disclaimer }: { disclaimer: string }) => {
      try {
        await invokeFastApi({
          path: '/ai_controls/disclaimer',
          method: 'POST',
          body: { disclaimer },
        });

        trackEvent(
          AnalyticsEvent.AiControlsDisclaimerUpdated,
          {},
          {
            disclaimer,
          }
        );

        setAiControlsData((prev) => {
          if (prev) {
            return {
              ...prev,
              disclaimer,
            };
          }

          return prev;
        });

        toaster.success('Successfully updated disclaimer');
      } catch (fetchError) {
        logError(fetchError);
        toaster.failure('Failed to update disclaimer');
      }
    },
    [toaster]
  );

  const updateCustomInstructions = useCallback(
    async ({ customInstructions }: { customInstructions: string }) => {
      try {
        await invokeFastApi({
          path: '/ai_controls/custom_instructions',
          method: 'POST',
          body: { custom_instructions: customInstructions },
        });

        trackEvent(
          AnalyticsEvent.AiControlsCustomInstructionsUpdated,
          {},
          {
            customInstructions,
          }
        );

        setAiControlsData((prev) => {
          if (prev) {
            return {
              ...prev,
              custom_instructions: customInstructions,
            };
          }
        });

        toaster.success('Successfully updated custom instructions');
      } catch (fetchError) {
        logError(fetchError);
        toaster.failure('Failed to update custom instructions');
      }
    },
    [toaster]
  );

  return {
    aiControlsData,
    loading,
    error,
    getAiControls,
    updateCustomInstructions,
    updateDisclaimer,
  };
};
