import React, { FC, useState } from 'react';
import freshdeskApiKeyScreen from '../../assets/images/admin/freshdesk_api_key.gif';
import { InstallChromeExtensionDialog } from '../../components/controls/InstallExtensionDialog/chrome/InstallExtensionDialog';
import { UIButton } from '../../components/controls/ui/UIButton/UIButton';
import { InstallDialogProps } from '../../scripts/app';
import { useBoolState } from '../../scripts/hooks';
import { useExtensionObserver } from '../../scripts/hooks/extension';
import { isChrome } from '../../scripts/utils';
import { ConnectStep } from '../generic/Components';
import { SimpleAPIKeyInstall } from '../generic/SimpleAPIKeyInstall';

const handleInstallApp = () => {
  window.open('https://login.freshworks.com/domain-login', '_blank');
};

export const AppInstallModal: FC<InstallDialogProps> = ({
  app,
  isOrg,
  onClose,
  open,
}) => {
  const [
    openInstallChromeExtensionDialog,
    setOpenInstallChromeExtensionDialog,
    setNotOpenInstallChromeExtensionDialog,
  ] = useBoolState(false);

  const [hasClickedInstall, setHasClickedInstall] = useState(false);
  const extensionInstalled = useExtensionObserver();
  const unsupportedBrowser = !isChrome;

  const handleInstallClick = () => {
    handleInstallApp();
    setHasClickedInstall(true);
  };

  return (
    <SimpleAPIKeyInstall
      additionalApiKeyInstructions={
        <strong>
          {' '}
          The user providing the token must be an Admin in Freshdesk.
        </strong>
      }
      app={app}
      credentialName="credentials"
      disableConnect={
        !extensionInstalled || unsupportedBrowser || !hasClickedInstall
      }
      instructionSteps={
        <>
          <ConnectStep step={1} title="Install Dashworks Extension">
            <p>
              Dashworks connects to Freshdesk through our browser extension,
              which securely reads your Freshdesk cookies to search your
              Freshdesk workspace.
            </p>
            {unsupportedBrowser ? (
              <p className="text-mahogany-20">
                Dashworks extension is only supported on Chrome. Please use
                Chrome to install the extension.
              </p>
            ) : (
              <UIButton
                disabled={extensionInstalled}
                onClick={setOpenInstallChromeExtensionDialog}
                size="large"
              >
                {extensionInstalled ? 'Extension installed' : 'Install'}
              </UIButton>
            )}
          </ConnectStep>

          <ConnectStep step={2} title="Generate API key">
            <p>
              After logging in to your Freshdesk account, Click on your profile
              picture on the top right and select{' '}
              <strong>Profile Settings</strong>. From the sidebar on the right,
              copy the API Key
            </p>
            <p>
              <div className="screenFrame">
                <img src={freshdeskApiKeyScreen} />
              </div>
            </p>
            <UIButton onClick={handleInstallClick} size="large">
              Open Freshdesk
            </UIButton>
          </ConnectStep>

          <InstallChromeExtensionDialog
            onClose={setNotOpenInstallChromeExtensionDialog}
            open={openInstallChromeExtensionDialog}
          />
        </>
      }
      isOrg={isOrg}
      onClose={onClose}
      open={open}
      stepCount={2}
      subdomainCapture={{
        hostname: 'freshdesk.com',
        loginURL: 'https://freshdesk.com/login',
      }}
    />
  );
};
