import { useMemo } from 'react';
import { InstantAvailableStatus, useAppDefinitions } from '../apps/definition';
import { isInstantAvailable } from '../components/admin/AppsTable/AppUtils';
import {
  AppState,
  errorAppStates,
  filterableIndexStates,
  pendingAppStates,
} from '../constants';
import { AppConnectionStatus } from '../models/User';
import { useUserSafe } from '../scripts/hooks';
import { count } from '../scripts/utils';

export interface AppConnectionSummary {
  totalConnectedCount: number;
  indexingCompleteCount: number;
  indexingPendingCount: number;
  errorCount: number;
  googleDirectorySyncStatusCode: AppState;
  instantAppConnectedCount: number;
}

export const useAppConnectionSummary = (): AppConnectionSummary => {
  const appConnections = useAppConnectionStatus();

  const googleDirectorySyncStatusCode =
    useUserSafe(
      (user) =>
        user.orgByOrgId.usersAppsByOrg.nodes.find(
          (a) => a.appName === 'gworkspace-directory'
        )?.statusCode
    ) ?? AppState.NotConnected;

  const totalConnectedCount = appConnections.length;
  const indexingCompleteCount = count(
    appConnections,
    ({ statusCode }) => statusCode === AppState.IndexingComplete
  );

  const indexingPendingCount = count(appConnections, ({ statusCode }) =>
    pendingAppStates.has(statusCode)
  );

  const errorCount = count(appConnections, ({ statusCode }) =>
    errorAppStates.has(statusCode)
  );

  const instantAppConnectedCount = appConnections.filter(
    (app) => app.isInstant
  ).length;

  return useMemo(
    () => ({
      totalConnectedCount,
      indexingCompleteCount,
      indexingPendingCount,
      errorCount,
      googleDirectorySyncStatusCode,
      instantAppConnectedCount,
    }),
    [
      totalConnectedCount,
      indexingCompleteCount,
      indexingPendingCount,
      errorCount,
      googleDirectorySyncStatusCode,
      instantAppConnectedCount,
    ]
  );
};

export function useAppConnectionStatus(): AppConnectionStatus[] {
  const appDefinitions = useAppDefinitions();

  return useUserSafe(
    (user) =>
      user.usersApps.nodes
        // remove org apps incorrectly coming in userApps array
        .filter((app) => app.org === '')
        .concat(user.orgByOrgId.usersAppsByOrg.nodes)
        .filter(
          (a) =>
            // remove apps like directory sync from connected app status code summary
            appDefinitions[a.appName]?.controlEnabled === true &&
            filterableIndexStates.has(a.statusCode) &&
            !a.onlyBotSearchEnabled
        )
        .map((a) => ({
          appName: a.appName,
          statusCode: a.statusCode,
          id: a.id,
          isOrg: a.org !== '',
          displayName: appDefinitions[a.appName]!.displayName,
          shortName: appDefinitions[a.appName]!.shortname,
          description: appDefinitions[a.appName]!.description,
          workspaceName: a.workspaceName,
          connectionName: a.connectionName,
          isInstant: isInstantAvailable(
            appDefinitions[a.appName]!,
            a.org === ''
              ? InstantAvailableStatus.Personal
              : InstantAvailableStatus.Org
          ),
          showInAppStore: appDefinitions[a.appName]!.showInAppStore ?? false,
          icon: a.icon,
        }))
    // deepEqual add support to passing comparator to to `useUserSafe` for speed maybe?
  );
}
