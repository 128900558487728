/* eslint-disable max-lines-per-function */
import Knock, { Feed, FeedItem, FeedMetadata } from '@knocklabs/client';
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { v4 as uuidV4 } from 'uuid';
import { trackEvent } from '../../extra/sharedMethods';
import { useQAController } from '../../scripts/QAController';
import { AnalyticsEvent } from '../../scripts/constants/analytics-event';
import {
  useFlag,
  useLoginTokens,
  useToaster,
  useUserSafe,
} from '../../scripts/hooks';
import { uniq } from '../../scripts/utils';
import {
  getKnockNotificationsToken,
  handleNotificationsCountChange,
  isNotificationSupported,
  KnockUser,
  markFeedItemsAsRead,
  mergeFeedItems,
  NOTIFICATIONS_CHANNEL_ID,
  NotificationsChannelMessage,
  NotificationsChannelMessageType,
  NotificationsChannelReadMessage,
  NotificationsData,
  NotificationsFeedItem,
  NotificationType,
  processFeedItem,
  processFeedItems,
  sendDesktopNotification,
  updateMetaDataAfterNotificationsRead,
} from './utils';

interface NotificationsProps {
  notificationItems: NotificationsFeedItem[] | null;
  metaData: FeedMetadata | null;
  knockUser: KnockUser | null;
  desktopNotificationPermission: NotificationPermission;
  setKnockUser: React.Dispatch<React.SetStateAction<KnockUser | null>>;
  markTopicNotificationsAsRead: (topicId: string) => void;
  markNotificationAsRead: (feedItem: FeedItem) => void;
  markAllNotificationsAsRead: () => void;
  deleteNotificationsByTopicId: (topicId: string) => void;
  deleteNotificationsByBotId: (botId: string) => void;
  requestDesktopNotificationPermission: () => void;
}

const NotificationsContext = createContext<NotificationsProps | undefined>(
  undefined
);

export const useNotifications = (): NotificationsProps => {
  const ctx = useContext(NotificationsContext);
  if (!ctx) {
    throw new Error('Attempted to use context outside of scope');
  }

  return ctx;
};

interface NotificationsProviderProps {
  children: ReactNode;
}

export const NotificationsProvider = ({
  children,
}: NotificationsProviderProps): JSX.Element => {
  const userEmail = useUserSafe((u) => u.email);
  const idToken = useLoginTokens();
  const [knockToken, setKnockToken] = useState<string | null>(null);
  const [notificationsFeed, setNotificationsFeed] = useState<Feed | null>(null);
  const [notificationItems, setNotificationItems] = useState<
    NotificationsFeedItem[] | null
  >(null);

  const sourceIdRef = useRef(uuidV4());

  const [desktopNotificationPermission, setDesktopNotificationPermission] =
    useState<NotificationPermission>(
      isNotificationSupported() ? Notification.permission : 'denied'
    );

  const [knockUser, setKnockUser] = useState<KnockUser | null>(null);

  const qaController = useQAController();
  const { isNewTopic } = qaController.getIsNewTopic();

  const messages = qaController.useMessages();
  const currentTopicMessages = qaController.useCurrentTopicMessages();

  const lastMessage =
    currentTopicMessages[currentTopicMessages.length - 1] ??
    messages[messages.length - 1];

  const currentConversationId = !isNewTopic && lastMessage?.conversation_id;

  const toaster = useToaster();

  const multiplayerEnabled = useFlag('multiplayerEnabled');
  const knockNotificationsEnabled = useFlag('knockNotificationsEnabled');
  const newNotificationsExperienceEnabled = useFlag(
    'newNotificationsExperience'
  );

  const [metaData, setMetaData] = useState<FeedMetadata | null>(null);

  const requestDesktopNotificationPermission = useCallback(() => {
    if (!isNotificationSupported()) return;

    if (Notification.permission !== 'default') return;

    Notification.requestPermission().then((result) => {
      setDesktopNotificationPermission(result);
      trackEvent(AnalyticsEvent.UpdatedDesktopNotificationPermission, {
        permission: result,
      });
    });
  }, []);

  useEffect(() => {
    async function getKnockToken() {
      if (idToken) {
        const knockTokenRes = await getKnockNotificationsToken();
        if (knockTokenRes) {
          setKnockToken(knockTokenRes.token);
          setKnockUser(knockTokenRes.knock_user);
        }
      }
    }

    if (multiplayerEnabled && knockNotificationsEnabled) {
      getKnockToken();
    }
  }, [idToken, multiplayerEnabled, knockNotificationsEnabled]);

  useEffect(() => {
    if (knockToken) {
      const knock = new Knock(KNOCK_PUBLIC_API_KEY);
      knock.authenticate(userEmail, knockToken);

      const knockFeed = knock.feeds.initialize(KNOCK_FEED_CHANNEL_ID);
      setNotificationsFeed(knockFeed);

      return () => {
        knock.teardown();
      };
    }
  }, [userEmail, knockToken]);

  const handleNotificationsMarkAsRead = (items: FeedItem[]) => {
    setNotificationItems((prev) => {
      if (!prev) return null;
      const isAnyNewUnreadItemFromPrev = prev.some(
        (item) => !item.read && items.find((i) => i.id === item.id)
      );

      if (!isAnyNewUnreadItemFromPrev) return prev;
      return mergeFeedItems(prev, markFeedItemsAsRead(items));
    });

    setMetaData((prev) => updateMetaDataAfterNotificationsRead(prev, items));
  };

  const broadcastReadNotifications = useCallback((feedItems: FeedItem[]) => {
    const broadcast = new BroadcastChannel(NOTIFICATIONS_CHANNEL_ID);

    const message: NotificationsChannelReadMessage = {
      type: NotificationsChannelMessageType.NOTIFICATIONS_READ,
      notificationIds: feedItems.map((item) => item.id),
      source: sourceIdRef.current,
    };

    broadcast.postMessage(message);
    broadcast.close();
  }, []);

  useEffect(() => {
    if (notificationsFeed) {
      notificationsFeed.listenForUpdates();

      notificationsFeed.fetch();

      notificationsFeed.on('items.read', (data: { items: FeedItem[] }) => {
        handleNotificationsMarkAsRead(data.items);
      });

      notificationsFeed.on('items.all_read', (data: { items: FeedItem[] }) => {
        handleNotificationsMarkAsRead(data.items);
        broadcastReadNotifications(data.items);
      });

      notificationsFeed.on(
        'items.received.page',
        ({
          items,
          metadata,
        }: {
          items: FeedItem[];
          metadata: FeedMetadata;
        }) => {
          setNotificationItems(processFeedItems(items));
          setMetaData(metadata);
        }
      );
    }

    return () => notificationsFeed?.teardown();
  }, [notificationsFeed, broadcastReadNotifications]);

  const markNotificationAsRead = useCallback(
    (feedItem: FeedItem) => {
      if (!notificationsFeed) return;
      notificationsFeed.markAsRead(feedItem);
      broadcastReadNotifications([feedItem]);
      if (feedItem.data) {
        const topicId = (feedItem.data as NotificationsData).topic_id;
        qaController.markTopicAsRead(topicId);
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [notificationsFeed, qaController]
  );

  useEffect(() => {
    if (!notificationsFeed) return;

    const notificationsRealtimeEventListener = (data: {
      items: FeedItem[];
      metadata: FeedMetadata;
    }) => {
      setNotificationItems((prev) => {
        return [...(prev ?? []), ...processFeedItems(data.items)].sort((a, b) =>
          a.knockItem.inserted_at > b.knockItem.inserted_at ? -1 : 1
        );
      });

      setMetaData(data.metadata);

      const feedItemsToSkipNotification: string[] = [];

      if (data.items.length > 0 && currentConversationId) {
        for (const feedItem of data.items) {
          const notificationsData = feedItem.data as NotificationsData | null;

          if (notificationsData?.topic_id === currentConversationId) {
            markNotificationAsRead(feedItem);
            feedItemsToSkipNotification.push(feedItem.id);
            if (
              knockUser &&
              notificationsData.type === NotificationType.NOTIFY_RESEARCH
            ) {
              sendDesktopNotification(knockUser, processFeedItem(feedItem));
            }
          }
        }
      }

      if (knockUser) {
        for (const feedItem of data.items) {
          if (
            !feedItemsToSkipNotification.includes(feedItem.id) &&
            newNotificationsExperienceEnabled
          )
            sendDesktopNotification(knockUser, processFeedItem(feedItem));
        }
      }
    };

    notificationsFeed.on(
      'items.received.realtime',
      notificationsRealtimeEventListener
    );

    return () => {
      notificationsFeed.off(
        'items.received.realtime',
        notificationsRealtimeEventListener
      );
    };
  }, [
    notificationsFeed,
    currentConversationId,
    markNotificationAsRead,
    knockUser,
    newNotificationsExperienceEnabled,
  ]);

  const markAllNotificationsAsRead = useCallback(async () => {
    if (!notificationsFeed) return;
    await notificationsFeed.markAllAsRead();
    qaController.markAllMessagesAsRead();
    toaster.success('Marked all notifications as read');
  }, [notificationsFeed, toaster, qaController]);

  const deleteNotificationsByTopicId = useCallback((topicId: string) => {
    setNotificationItems((prev) => {
      if (!prev) return null;
      return prev.filter((item) => {
        return item.topicId !== topicId;
      });
    });
  }, []);

  const deleteNotificationsByBotId = useCallback((botId: string) => {
    setNotificationItems((prev) => {
      if (!prev) return null;
      return prev.filter((item) => {
        return item.botId !== botId;
      });
    });
  }, []);

  const markTopicNotificationsAsRead = useCallback(
    (topicId: string) => {
      if (!notificationsFeed || !notificationItems) return;

      const unreadTopicNotifications = notificationItems.filter(
        (item) => item.topicId === topicId && !item.read
      );

      const knockItems = unreadTopicNotifications.map((item) => item.knockItem);
      if (knockItems.length === 0) return;
      notificationsFeed.markAsRead(knockItems);

      broadcastReadNotifications(
        unreadTopicNotifications.map((item) => item.knockItem)
      );
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [notificationsFeed, notificationItems]
  );

  useEffect(() => {
    if (!currentConversationId) return;
    markTopicNotificationsAsRead(currentConversationId);
  }, [currentConversationId, markTopicNotificationsAsRead]);

  useEffect(() => {
    if (!newNotificationsExperienceEnabled) return;
    handleNotificationsCountChange(metaData?.unread_count ?? 0);
  }, [metaData?.unread_count, newNotificationsExperienceEnabled]);

  useEffect(() => {
    const broadcast = new BroadcastChannel(NOTIFICATIONS_CHANNEL_ID);

    const handleBroadcastMessage = (
      event: MessageEvent<NotificationsChannelMessage>
    ) => {
      if (event.data.source === sourceIdRef.current) return;
      if (!notificationsFeed) return;

      switch (event.data.type) {
        case NotificationsChannelMessageType.NOTIFICATIONS_READ:
          for (const notificationId of event.data.notificationIds) {
            if (!notificationItems) return;
            const feedItems = notificationItems.filter(
              (item) => item.id === notificationId
            );

            handleNotificationsMarkAsRead(
              feedItems.map((item) => item.knockItem)
            );

            const topicIds = uniq(feedItems.map((item) => item.topicId));
            for (const topicId of topicIds) {
              qaController.markTopicAsRead(topicId, true);
            }
          }

          break;

        default:
          break;
      }
    };

    broadcast.addEventListener('message', handleBroadcastMessage);

    return () => {
      broadcast.removeEventListener('message', handleBroadcastMessage);
      broadcast.close();
    };
  }, [
    notificationsFeed,
    notificationItems,
    markAllNotificationsAsRead,
    qaController,
  ]);

  const contextValue = useMemo(
    () => ({
      markAllNotificationsAsRead,
      markNotificationAsRead,
      notificationItems,
      metaData,
      knockUser,
      setKnockUser,
      deleteNotificationsByTopicId,
      deleteNotificationsByBotId,
      markTopicNotificationsAsRead,
      desktopNotificationPermission,
      requestDesktopNotificationPermission,
    }),
    [
      markAllNotificationsAsRead,
      markNotificationAsRead,
      notificationItems,
      metaData,
      knockUser,
      deleteNotificationsByBotId,
      deleteNotificationsByTopicId,
      markTopicNotificationsAsRead,
      desktopNotificationPermission,
      requestDesktopNotificationPermission,
    ]
  );

  return (
    <NotificationsContext.Provider value={contextValue}>
      {children}
    </NotificationsContext.Provider>
  );
};
