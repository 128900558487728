import { useCallback, useEffect, useState } from 'react';
import { AppDefinitions, useAppDefinitions } from '../../apps/definition';
import { useNotifications } from '../../components/notifications/NotificationsContext';
import { trackEvent } from '../../extra/sharedMethods';
import {
  Bot,
  BotMinimal,
  BotVisibility,
  CreatorDetails,
} from '../../models/Bots';
import { Sources, SupportedLlm, UserApp } from '../../models/User';
import {
  populateBots,
  populateEnabledUserApps,
  populateHasRecommendedChannels,
} from '../../redux/bots/actions';
import { populateBotsMinimal } from '../../redux/botsMinimal/actions';
import { useDispatch } from '../../redux/store';
import { invokeFastApi } from '../apis/fastapi';
import { RequestError } from '../apis/request';
import { AnalyticsEvent } from '../constants/analytics-event';
import { logError } from '../utils';
import { useGlobalState, useUserSafe } from './redux';
import { ConnectedApps, filterSortedInstantApps } from './sortedInstantApps';
import { useToaster } from './toast';

export interface UpsertBotParams {
  id?: string;
  icon?: string;
  bot_name?: string;
  description?: string;
  sources?: Sources;
  preferred_llm?: SupportedLlm | null;
  visibility?: BotVisibility;
  is_managed?: boolean;
  admins?: CreatorDetails[];
  custom_instructions?: string;
  deleted_feedback_ids?: string[];
  updated_feedbacks?: Record<string, string>;
}

interface UpsertBotBody {
  icon?: string;
  bot_name?: string;
  description?: string;
  sources?: Sources;
  preferred_llm?: SupportedLlm | null;
  visibility?: BotVisibility;
  is_managed?: boolean;
  admin_emails?: string[];
  custom_instructions?: string;
  deleted_feedback_ids?: string[];
  updated_feedbacks?: [string, string][];
}

interface BotsResponse {
  bots: Bot[];
  botsEnabledApps: ConnectedApps[];
  botsEnabledUserApps: UserApp[];
  hasRecommendedChannels: boolean;
  loading: boolean;
  error: boolean;
  getBots: (forceFetch?: boolean) => Promise<void>;
  createBot: (params: UpsertBotParams) => Promise<boolean>;
  deleteBot: (id: string) => Promise<void>;
  updateBot: (params: UpsertBotParams) => Promise<boolean>;
}

const getFilteredEnabledApps = (
  enabledUserApps: UserApp[],
  appDefinitions: AppDefinitions
) => {
  const { sortedInstantConnectedApps: filteredEnabledApps } =
    filterSortedInstantApps(enabledUserApps, appDefinitions);

  const filteredEnabledUserApps = enabledUserApps.filter((userApp) =>
    filteredEnabledApps.some((app) => app.id === userApp.id)
  );

  return {
    filteredEnabledApps,
    filteredEnabledUserApps,
  };
};

// eslint-disable-next-line max-lines-per-function
export const useBots = (): BotsResponse => {
  const user = useUserSafe();
  const dispatch = useDispatch();
  const botsMinimalState = useGlobalState((state) => state.botsMinimal);
  const botsState = useGlobalState((state) => state.bots);
  const { userDisplayName, userPhotoUrl } = useGlobalState(
    (state) => state.meta
  );

  const [bots, setBots] = useState<Bot[]>([]);
  const [botsEnabledApps, setBotsEnabledApps] = useState<ConnectedApps[]>([]);
  const [botsEnabledUserApps, setBotsEnabledUserApps] = useState<UserApp[]>([]);
  const [hasRecommendedChannels, setHasRecommendedChannels] = useState(false);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const toaster = useToaster();
  const appDefinitions = useAppDefinitions();
  const { deleteNotificationsByBotId } = useNotifications();

  const getBots = useCallback(
    async (forceFetch = false) => {
      if (botsState.bots.length > 0 && !forceFetch) {
        const { filteredEnabledApps, filteredEnabledUserApps } =
          getFilteredEnabledApps(botsState.enabledUserApps, appDefinitions);

        setBots(botsState.bots);
        setBotsEnabledApps(filteredEnabledApps);
        setBotsEnabledUserApps(filteredEnabledUserApps);
        setHasRecommendedChannels(botsState.hasRecommendedChannels);
        return;
      }

      try {
        setBots([]);
        setBotsEnabledApps([]);
        setBotsEnabledUserApps([]);
        setHasRecommendedChannels(false);
        setLoading(true);
        setError(false);

        const {
          bots: fetchedBots,
          enabled_apps: enabled_user_apps,
          has_recommended_channels,
        } = await invokeFastApi<{
          bots: Bot[];
          // Gotcha: Backend doesn't send all the fields present in UserApp model
          enabled_apps: UserApp[];
          has_recommended_channels: boolean;
        }>({
          path: '/bots',
          method: 'GET',
          queryParams: {
            get_bots_enabled_apps: 'True',
            get_slackbot_channels_summary: 'True',
          },
          shouldRetry: true,
        });

        dispatch(
          populateBotsMinimal(
            fetchedBots.map((bot) => ({
              id: bot.id,
              icon: bot.icon,
              bot_name: bot.bot_name,
              description: bot.description,
            }))
          )
        );

        dispatch(populateBots(fetchedBots));
        dispatch(populateEnabledUserApps(enabled_user_apps));
        dispatch(populateHasRecommendedChannels(has_recommended_channels));

        const { filteredEnabledApps, filteredEnabledUserApps } =
          getFilteredEnabledApps(enabled_user_apps, appDefinitions);

        setBots(fetchedBots);
        setBotsEnabledApps(filteredEnabledApps);
        setBotsEnabledUserApps(filteredEnabledUserApps);
        setHasRecommendedChannels(has_recommended_channels);
        setLoading(false);
      } catch (fetchError) {
        logError(fetchError);
        setError(true);
        setLoading(false);
        toaster.failure('Failed to fetch bots');
      }
    },
    [
      appDefinitions,
      botsState.bots,
      botsState.enabledUserApps,
      botsState.hasRecommendedChannels,
      dispatch,
      toaster,
    ]
  );

  const createBot = useCallback(
    async ({
      icon,
      bot_name,
      description,
      sources,
      preferred_llm,
      visibility,
      is_managed,
      admins,
      custom_instructions,
    }: UpsertBotParams) => {
      const bodyData: UpsertBotBody = {};

      if (icon !== undefined) {
        bodyData.icon = icon;
      }

      if (bot_name !== undefined) {
        bodyData.bot_name = bot_name;
      }

      if (description !== undefined) {
        bodyData.description = description;
      }

      if (sources !== undefined) {
        bodyData.sources = sources;
      }

      if (preferred_llm !== undefined) {
        bodyData.preferred_llm = preferred_llm;
      }

      if (visibility !== undefined) {
        bodyData.visibility = visibility;
      }

      if (is_managed !== undefined) {
        bodyData.is_managed = is_managed;
      }

      if (admins !== undefined) {
        bodyData.admin_emails = admins.map((admin) => admin.email);
      }

      if (custom_instructions !== undefined) {
        bodyData.custom_instructions = custom_instructions;
      }

      try {
        const { bot_id } = await invokeFastApi<{ bot_id: string }>({
          path: '/bots/create',
          method: 'POST',
          body: bodyData,
        });

        toaster.success('Bot created successfully');
        trackEvent(AnalyticsEvent.CreatedBot, { bot_name });

        const createdBotMinimal: BotMinimal = {
          id: bot_id,
          icon: icon!,
          bot_name: bot_name!,
          description: description ?? null,
        };

        const createdBot: Bot = {
          ...createdBotMinimal,
          org_id: user.orgByOrgId.id,
          is_default: false,
          is_managed: is_managed ?? false,
          sources: sources!,
          preferred_llm: preferred_llm ?? null,
          visibility: visibility ?? BotVisibility.ORG_WIDE,
          custom_instructions: custom_instructions!,
          created_at: Math.floor(Date.now() / 1000),
          modified_at: Math.floor(Date.now() / 1000),
          author: {
            icon: userPhotoUrl,
            display_name: userDisplayName,
            email: user.email,
          },
          user_id: user.userId,
          admins: admins!,
          feedbacks: [],
        };

        dispatch(
          populateBotsMinimal([createdBotMinimal, ...botsMinimalState.bots])
        );

        dispatch(populateBots([createdBot, ...botsState.bots]));
        setBots((prevBots) => [createdBot, ...prevBots]);
        return true;
      } catch (fetchError) {
        logError(fetchError);

        if (
          fetchError instanceof RequestError &&
          fetchError.statusCode === 400
        ) {
          toaster.failure('Bot with same name already exists');
        } else {
          toaster.failure('Failed to create bot');
        }

        return false;
      }
    },
    [
      botsMinimalState.bots,
      botsState.bots,
      dispatch,
      toaster,
      user.email,
      user.orgByOrgId.id,
      user.userId,
      userDisplayName,
      userPhotoUrl,
    ]
  );

  const deleteBot = useCallback(
    async (id: string) => {
      try {
        await invokeFastApi({
          path: `/bots/${id}`,
          method: 'DELETE',
        });

        dispatch(
          populateBotsMinimal(
            botsMinimalState.bots.filter((bot) => bot.id !== id)
          )
        );

        dispatch(populateBots(botsState.bots.filter((bot) => bot.id !== id)));
        setBots((prevBots) => prevBots.filter((bot) => bot.id !== id));

        toaster.success('Bot deleted successfully');
        deleteNotificationsByBotId(id);
        trackEvent(AnalyticsEvent.DeletedBot, { bot_id: id });
      } catch (fetchError) {
        logError(fetchError);
        toaster.failure('Failed to delete bot');
      }
    },
    [
      botsMinimalState.bots,
      botsState.bots,
      dispatch,
      toaster,
      deleteNotificationsByBotId,
    ]
  );

  const updateBot = useCallback(
    async ({
      id,
      icon,
      bot_name,
      description,
      sources,
      preferred_llm,
      visibility,
      is_managed,
      admins,
      custom_instructions,
      deleted_feedback_ids,
      updated_feedbacks,
    }: UpsertBotParams) => {
      const bodyData: UpsertBotBody = {};

      if (icon !== undefined) {
        bodyData.icon = icon;
      }

      if (bot_name !== undefined) {
        bodyData.bot_name = bot_name;
      }

      if (description !== undefined) {
        bodyData.description = description;
      }

      if (sources !== undefined) {
        bodyData.sources = sources;
      }

      if (preferred_llm !== undefined) {
        bodyData.preferred_llm = preferred_llm;
      }

      if (visibility !== undefined) {
        bodyData.visibility = visibility;
      }

      if (is_managed !== undefined) {
        bodyData.is_managed = is_managed;
      }

      if (admins !== undefined) {
        bodyData.admin_emails = admins.map((admin) => admin.email);
      }

      if (custom_instructions !== undefined) {
        bodyData.custom_instructions = custom_instructions;
      }

      if (deleted_feedback_ids !== undefined) {
        bodyData.deleted_feedback_ids = deleted_feedback_ids;
      }

      if (updated_feedbacks !== undefined) {
        bodyData.updated_feedbacks = Object.entries(updated_feedbacks);
      }

      try {
        if (!id) {
          throw new Error('Bot ID is required');
        }

        await invokeFastApi({
          path: `/bots/${id}`,
          method: 'PATCH',
          body: bodyData,
        });

        const _updateBotMinimal = (botsMinimalToUpdate: BotMinimal[]) =>
          botsMinimalToUpdate.map((botMinimal) => {
            if (botMinimal.id === id) {
              return {
                ...botMinimal,
                icon: icon ?? botMinimal.icon,
                bot_name: bot_name ?? botMinimal.bot_name,
                description: description ?? botMinimal.description,
              };
            }

            return botMinimal;
          });

        const _updateBot = (botsToUpdate: Bot[]) =>
          botsToUpdate.map((bot) => {
            if (bot.id === id) {
              let updatedFeedbacks = bot.feedbacks;

              if (updated_feedbacks) {
                updatedFeedbacks = updatedFeedbacks?.map((feedback) => {
                  const updatedFeedback = updated_feedbacks[feedback.id];
                  if (updatedFeedback) {
                    return {
                      ...feedback,
                      processed_feedback: updatedFeedback,
                    };
                  }

                  return feedback;
                });
              }

              if (deleted_feedback_ids) {
                updatedFeedbacks = updatedFeedbacks?.filter(
                  (feedback) => !deleted_feedback_ids.includes(feedback.id)
                );
              }

              return {
                ...bot,
                icon: icon ?? bot.icon,
                bot_name: bot_name ?? bot.bot_name,
                description: description ?? bot.description,
                sources: sources ?? bot.sources,
                preferred_llm: preferred_llm ?? bot.preferred_llm,
                visibility: visibility ?? bot.visibility,
                is_managed: is_managed ?? bot.is_managed,
                admins: admins ?? bot.admins,
                custom_instructions:
                  custom_instructions ?? bot.custom_instructions,
                feedbacks: updatedFeedbacks,
              };
            }

            return bot;
          });

        dispatch(populateBotsMinimal(_updateBotMinimal(botsMinimalState.bots)));
        dispatch(populateBots(_updateBot(botsState.bots)));
        setBots((prevBots) => _updateBot(prevBots));

        toaster.success('Bot updated successfully');
        trackEvent(AnalyticsEvent.UpdatedBot, { bot_id: id });

        return true;
      } catch (fetchError) {
        logError(fetchError);

        if (
          fetchError instanceof RequestError &&
          fetchError.statusCode === 400
        ) {
          toaster.failure('Bot with same name already exists');
        } else {
          toaster.failure('Failed to update bot');
        }

        return false;
      }
    },
    [botsMinimalState.bots, botsState.bots, dispatch, toaster]
  );

  useEffect(() => {
    getBots();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    bots,
    botsEnabledApps,
    botsEnabledUserApps,
    hasRecommendedChannels,
    loading,
    error,
    getBots,
    createBot,
    deleteBot,
    updateBot,
  };
};
