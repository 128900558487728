import './Sidebar.scss';
import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined';
import { Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import classNames from 'classnames';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { errorAppStates } from '../../../constants';
import { SidebarRoutes } from '../../../containers/SidebarContainer';
import { AppCuesIds } from '../../../external-references';
import { trackEvent } from '../../../extra/sharedMethods';
import { useAppConnectionStatus } from '../../../hooks/app-connection-summary';
import { BotMinimal } from '../../../models/Bots';
import { TopicType } from '../../../models/QAmodels';
import {
  setSidebarAdminOpen,
  setSidebarKnowledgeOpen,
  setSidebarNotificationsOpen,
  setSidebarPersonalOpen,
  setSidebarSettingsOpen,
} from '../../../redux/sidebar/actions';
import { useDispatch } from '../../../redux/store';
import { useQAController } from '../../../scripts/QAController';
import { invokeFastApi } from '../../../scripts/apis/fastapi';
import { logout } from '../../../scripts/authentication';
import { AnalyticsEvent } from '../../../scripts/constants/analytics-event';
import {
  useBoolState,
  useFlag,
  useGlobalState,
  useHistoryPush,
  useOrgPreference,
  useSidebarOpen,
  useUserSafe,
} from '../../../scripts/hooks';
import { useExtensionObserver } from '../../../scripts/hooks/extension';
import { toggleSidebar } from '../../../scripts/sidebar';
import {
  extensionEnabledBrowser,
  installExtension,
  isChrome,
  logError,
} from '../../../scripts/utils';
import { CompanyLogo } from '../../controls/CompanyLogo/CompanyLogo';
import { InstallChromeExtensionDialog } from '../../controls/InstallExtensionDialog/chrome/InstallExtensionDialog';
import { ProductFeedbackDialog } from '../../controls/ProductFeedbackDialog/ProductFeedbackDialog';
import { BetaBadge } from '../../controls/ui/BetaBadge/BetaBadge';
import { UIButton } from '../../controls/ui/UIButton/UIButton';
import { UIIcon, UIIcons } from '../../controls/ui/UIIcon/UIIcon';
import { UIIconButton } from '../../controls/ui/UIIconButton/UIIconButton';
import { NotificationsBadge } from '../../notifications/NotificationBadge';
import { useNotifications } from '../../notifications/NotificationsContext';
import { NotificationsSidebar } from '../../notifications/NotificationsSidebar';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '../../shadcn/lib/components/popover';
import { LazyInviteMembersModal } from '../InviteMembersModal';
import { LazyMembersTableDialog } from '../MembersTableDialog';
import { TopicsList } from './TopicsList';

const trackSidebarItemClick = (title: string) => {
  trackEvent(AnalyticsEvent.SidebarItemClick, {
    title,
  });
};

interface SidebarHeaderProps {
  topicsOpen: boolean;
  setTopicsOpen: (open: boolean) => void;
}

const SidebarHeader: FC<SidebarHeaderProps> = ({
  topicsOpen,
  setTopicsOpen,
}: SidebarHeaderProps) => {
  const user = useUserSafe();
  const companyName = user.orgByOrgId.name;

  const onCollapseClick = useCallback(() => {
    toggleSidebar(false);
  }, []);

  return (
    <div className="flex flex-col">
      <div className="px-2 flex flex-col gap-0.5">
        <div className="sidebarLink flex items-center rounded justify-between cursor-pointer ">
          <Link
            className="flex items-center gap-1.5 text-black hover:text-black cursor-pointer"
            to="/"
          >
            <CompanyLogo size={20} />
            <span className="companyName">{companyName || 'Dashworks'}</span>
          </Link>
          <UIIcon name="arrow-left" onClick={onCollapseClick} size={20} />
        </div>
      </div>

      <div className="px-2 flex flex-col gap-0.5">
        <NavLink
          className="sidebarLink flex text-black items-center rounded justify-between cursor-pointer"
          exact
          onClick={() => {
            setTopicsOpen(!topicsOpen);
          }}
          to={SidebarRoutes.JitQA}
        >
          <div className="flex items-center gap-1.5">
            <UIIcon name="messages" size={20} />
            My topics
          </div>
          <UIIcon name={topicsOpen ? 'arrow-up' : 'arrow-down'} size={20} />
        </NavLink>
      </div>
    </div>
  );
};

export const SidebarLink: FC<{
  error?: boolean;
  to: string;
  icon?: UIIcons;
  iconStroke?: boolean;
  title: string;
  logName: string;
  beta?: boolean;
  external?: boolean;
  appCuesId?: AppCuesIds;
  textColor?: string;
}> = ({
  error,
  to,
  icon,
  iconStroke,
  title,
  logName,
  beta,
  external,
  appCuesId,
  textColor = 'text-black',
}) => {
  const onClick = useCallback(() => {
    trackSidebarItemClick(logName);
  }, [logName]);

  const content = (
    <>
      <div className="flex items-center gap-1.5">
        {icon && (
          <UIIcon
            className="sidebarLinkIcon"
            name={icon}
            size={20}
            stroke={iconStroke}
          />
        )}
        {title}
        {beta && <BetaBadge />}
      </div>
      {error && (
        <div className="text-mahogany-20">
          <ErrorOutlinedIcon fontSize="small" />{' '}
        </div>
      )}
    </>
  );

  return external ? (
    <a
      className={`sidebarLink flex items-center rounded ${textColor}`}
      href={to}
      onClick={onClick}
      rel="noreferrer"
      target="_blank"
    >
      {content}
    </a>
  ) : (
    <NavLink
      className={`sidebarLink flex items-center justify-between rounded ${textColor}`}
      data-appcues-id={appCuesId}
      exact
      onClick={onClick}
      to={to}
    >
      {content}
    </NavLink>
  );
};

interface SidebarPopoverButtonProps {
  onClick?: (event: React.KeyboardEvent | React.MouseEvent) => void;
  logName: string;
  id?: string;
  href?: string;
}

const SidebarPopoverButton: FC<SidebarPopoverButtonProps> = ({
  children,
  onClick,
  id,
  logName,
  href,
}) => {
  const onButtonClick = useCallback(
    (event: React.KeyboardEvent | React.MouseEvent) => {
      trackSidebarItemClick(logName);
      if (onClick) onClick(event);
    },
    [onClick, logName]
  );

  if (href) {
    return (
      <a
        className="flex items-center gap-2 p-2 text-black hover:text-black hover:bg-cloud-15 rounded cursor-pointer font-medium"
        href={href}
        id={id}
        rel="noreferrer"
        target="_blank"
      >
        {children}
      </a>
    );
  }

  return (
    <div
      className="flex items-center gap-2 p-2 hover:bg-cloud-15 rounded cursor-pointer font-medium"
      id={id}
      onClick={onButtonClick}
    >
      {children}
    </div>
  );
};

interface SidebarButtonProps {
  onClick?: (event: React.KeyboardEvent | React.MouseEvent) => void;
  logName: string;
  className?: string;
  id?: string;
  propagate?: boolean;
}

const SidebarButton: FC<SidebarButtonProps> = ({
  children,
  onClick,
  logName,
  className,
  id,
  propagate,
}) => {
  const onButtonClick = useCallback(
    (event: React.KeyboardEvent | React.MouseEvent) => {
      trackSidebarItemClick(logName);
      if (onClick) onClick(event);
    },
    [onClick, logName]
  );

  return (
    <UIButton
      className={classNames('sidebarButton !text-black', className ?? '')}
      id={id}
      onClick={onButtonClick}
      propagate={propagate}
      size="small"
      type="flat"
    >
      {children}
    </UIButton>
  );
};

const SectionHeader: FC<{
  onToggleExpand?: () => void;
  onClick?: () => void;
  iconName: UIIcons;
  title: string;
  logName: string;
  active: boolean;
}> = ({ onToggleExpand, iconName, onClick, title, logName, active }) => {
  return (
    <div className="sectionHeader">
      <SidebarButton
        logName={`header-${logName}`}
        onClick={onToggleExpand ?? onClick}
      >
        <UIIcon className="!mr-1.5 shrink-0" name={iconName} size={20} />
        <span className={classNames({ 'text-black': active })}>{title}</span>
        {onToggleExpand && (
          <UIIcon className="expandIcon" name="arrow-down" size={16} />
        )}
      </SidebarButton>
    </div>
  );
};

const SidebarFooter: FC = () => {
  const dispatch = useDispatch();
  const sidebarSettingsOpen = useGlobalState(
    (state) => state.sidebar.settingsOpen
  );

  const knockNotificationsEnabled = useFlag('knockNotificationsEnabled');
  const multiplayerEnabled = useFlag('multiplayerEnabled');
  const simplerInviteExperience = useFlag('simplerInviteExperience');

  const sidebarNotificationsOpen = useGlobalState(
    (state) => state.sidebar.notificationsOpen
  );

  const { metaData: notificationsMetaData } = useNotifications();

  const [openMembersDialog, setOpenMembersDialog, setClosedMembersDialog] =
    useBoolState(false);

  const [
    openProductFeedbackDialog,
    setOpenProductFeedbackDialog,
    setClosedProductFeedbackDialog,
  ] = useBoolState(false);

  const [showSupportAndResources, setShowSupportAndResources] = useState(false);

  const isEarn500Enabled = useFlag('earn500SidebarLink');

  const extensionInstalled = useExtensionObserver();

  const [
    openInstallChromeExtensionDialog,
    setOpenInstallChromeExtensionDialog,
    setNotOpenInstallChromeExtensionDialog,
  ] = useBoolState(false);

  const openSettingsSidebar = useCallback(() => {
    dispatch(setSidebarSettingsOpen(!sidebarSettingsOpen));
  }, [dispatch, sidebarSettingsOpen]);

  const openNotificationsSidebar = useCallback(() => {
    dispatch(setSidebarNotificationsOpen(!sidebarNotificationsOpen));
  }, [dispatch, sidebarNotificationsOpen]);

  const openSettingsPage = useHistoryPush(SidebarRoutes.AppsV2);

  const onSettingsClick = useCallback(() => {
    openSettingsSidebar();
    openSettingsPage();
  }, [openSettingsSidebar, openSettingsPage]);

  return (
    <>
      <div className="sidebarFooter">
        <div className="flex flex-col gap-0.5 px-2 border-solid border-0 border-t border-cloud-15">
          {knockNotificationsEnabled && multiplayerEnabled && (
            <SidebarButton logName="inbox" onClick={openNotificationsSidebar}>
              <div className="relative">
                <UIIcon className="sidebarLinkIcon" name="bell" size={20} />
              </div>
              Inbox
              {Boolean(notificationsMetaData?.unread_count) && (
                <NotificationsBadge
                  className="ml-auto"
                  count={notificationsMetaData!.unread_count}
                />
              )}
            </SidebarButton>
          )}
          <SidebarButton logName="settings" onClick={onSettingsClick}>
            <UIIcon className="sidebarLinkIcon" name="cog" size={20} />
            Settings
          </SidebarButton>
          <SidebarButton logName="invite" onClick={setOpenMembersDialog}>
            <UIIcon className="sidebarLinkIcon" name="invite" size={20} />
            Invite
          </SidebarButton>
          {extensionEnabledBrowser && !extensionInstalled && (
            <SidebarButton
              logName="extension"
              onClick={
                isChrome
                  ? setOpenInstallChromeExtensionDialog
                  : installExtension
              }
            >
              <UIIcon
                className="mr-2"
                name={isChrome ? 'chrome' : 'firefox'}
                type="apps"
              />
              Install Extension
            </SidebarButton>
          )}
          <Popover
            onOpenChange={setShowSupportAndResources}
            open={showSupportAndResources}
          >
            <PopoverTrigger asChild>
              <div
                className="flex items-center gap-2 p-2 hover:bg-cloud-15 rounded cursor-pointer font-medium"
                onClick={() => {
                  setShowSupportAndResources(!showSupportAndResources);
                }}
              >
                <UIIcon name="help" size={20} />
                <span className="text-black">Support</span>
              </div>
            </PopoverTrigger>
            <PopoverContent className="z-[1000] bg-white text-sm !p-1 w-[228px]">
              <SidebarPopoverButton
                logName="support"
                onClick={() => {
                  // eslint-disable-next-line new-cap
                  Pylon('show');
                }}
              >
                <UIIcon name="message" size={20} />
                <span>Contact Us</span>
              </SidebarPopoverButton>
              <SidebarPopoverButton
                href="https://support.dashworks.ai"
                logName="help-center"
              >
                <UIIcon name="glossary" size={20} />
                <span>Visit Help Center</span>
              </SidebarPopoverButton>
              <SidebarPopoverButton
                href="https://join.slack.com/t/dashworkscommunity/shared_invite/zt-2yuyv8aat-6gzyJWkliaZzE7iZESgPZw"
                logName="slack-community"
              >
                <UIIcon name="slack" size={20} type="apps" />
                <span>Join Slack Community</span>
              </SidebarPopoverButton>
              <SidebarPopoverButton
                logName="feedback"
                onClick={setOpenProductFeedbackDialog}
              >
                <UIIcon name="light-bulb" size={20} />
                <span>Request Feature</span>
              </SidebarPopoverButton>
              {isEarn500Enabled && (
                <SidebarPopoverButton
                  href="https://support.dashworks.ai/referral-program"
                  logName="earn500"
                >
                  <UIIcon name="gift" size={20} />
                  <span>Earn $500</span>
                </SidebarPopoverButton>
              )}
            </PopoverContent>
          </Popover>
        </div>
      </div>
      {simplerInviteExperience ? (
        <LazyInviteMembersModal
          onClose={setClosedMembersDialog}
          open={openMembersDialog}
        />
      ) : (
        <LazyMembersTableDialog
          onClose={setClosedMembersDialog}
          open={openMembersDialog}
        />
      )}
      <ProductFeedbackDialog
        onClose={setClosedProductFeedbackDialog}
        open={openProductFeedbackDialog}
      />
      <InstallChromeExtensionDialog
        onClose={setNotOpenInstallChromeExtensionDialog}
        open={openInstallChromeExtensionDialog}
      />
    </>
  );
};

const SettingsLinks: FC = () => {
  const user = useUserSafe();
  const dispatch = useDispatch();
  const sidebarSettingsOpen = useGlobalState(
    (state) => state.sidebar.settingsOpen
  );

  const onClick = useCallback(() => {
    dispatch(setSidebarSettingsOpen(!sidebarSettingsOpen));
  }, [dispatch, sidebarSettingsOpen]);

  return (
    <div className="settingsSection">
      <div className="settingsHeader">
        <UIIconButton link="/" name="arrow-left" onClick={onClick} size={24} />
        <NavLink className="settings" onClick={onClick} to="/">
          Settings
        </NavLink>
      </div>
      <KnowledgeLinks />
      <div className="flex flex-col gap-0.5 px-2 border-solid border-0 border-t border-cloud-15">
        <SidebarLink
          icon="bot"
          logName="bots"
          title="Bots"
          to={SidebarRoutes.Bots}
        />
        <SidebarLink
          icon="bolt"
          logName="workflows"
          title="Workflows"
          to={SidebarRoutes.Workflows}
        />
      </div>
      {user.admin && <WorkspaceLinks />}
      <PersonalLinks />
    </div>
  );
};

const KnowledgeLinks: FC = () => {
  const dispatch = useDispatch();
  const sidebarKnowledgeOpen = useGlobalState(
    (state) => state.sidebar.knowledgeOpen
  );

  const { isAdmin } = useUserSafe((u) => ({
    isAdmin: u.admin,
  }));

  const answersEnabled = useOrgPreference('answers', true);

  const userApps = useAppConnectionStatus();
  const isAppInErrorState = useMemo(() => {
    // Checks if any app is in an error state.
    return userApps.some((app) => {
      if (isAdmin) {
        return errorAppStates.has(app.statusCode) && app.isInstant;
      }

      return errorAppStates.has(app.statusCode) && app.isInstant && !app.isOrg;
    });
  }, [isAdmin, userApps]);

  const onToggleExpand = useCallback(() => {
    dispatch(setSidebarKnowledgeOpen(!sidebarKnowledgeOpen));
  }, [dispatch, sidebarKnowledgeOpen]);

  return (
    <div className="linksSection">
      <SectionHeader
        active={sidebarKnowledgeOpen}
        iconName="glossary"
        logName="knowledge"
        onToggleExpand={onToggleExpand}
        title="Knowledge"
      />
      <div
        className={classNames('linksChildren', {
          hidden: !sidebarKnowledgeOpen,
        })}
      >
        <SidebarLink
          error={isAppInErrorState}
          icon="app"
          iconStroke
          logName="appStore"
          title="Apps"
          to={SidebarRoutes.AppsV2}
        />
        {isAdmin && (
          <SidebarLink
            icon="doc"
            logName="files"
            title="Files"
            to={SidebarRoutes.Files}
          />
        )}
        {answersEnabled && (
          <SidebarLink
            icon="answer"
            logName="answers"
            title="Answers"
            to="/answers"
          />
        )}
      </div>
    </div>
  );
};

const WorkspaceLinks: FC = () => {
  const user = useUserSafe();
  const dispatch = useDispatch();
  const sidebarAdminOpen = useGlobalState((state) => state.sidebar.adminOpen);
  const analytics = useFlag('adminAnalytics');
  const showAnalytics = analytics && user.orgByOrgId.exploToken?.length;
  const apiKeysEnabled = useFlag('apiKeysEnabled');

  const onToggleExpand = useCallback(() => {
    dispatch(setSidebarAdminOpen(!sidebarAdminOpen));
  }, [dispatch, sidebarAdminOpen]);

  return (
    <div className="linksSection">
      <SectionHeader
        active={sidebarAdminOpen}
        iconName="company"
        logName="workspace"
        onToggleExpand={onToggleExpand}
        title="Workspace"
      />
      <div
        className={classNames('linksChildren', { hidden: !sidebarAdminOpen })}
      >
        <SidebarLink
          icon="design"
          logName="workspace-general"
          title="General"
          to={SidebarRoutes.Appearance}
        />
        <SidebarLink
          icon="settings-2"
          logName="workspace-ai-controls"
          title="AI Controls"
          to={SidebarRoutes.AiControls}
        />
        <SidebarLink
          icon="people"
          logName="workspace-members"
          title="Members"
          to="/admin/members"
        />

        <SidebarLink
          icon="sync"
          logName="directory-sync"
          title="Directory Sync"
          to={SidebarRoutes.DirectorySync}
        />
        {showAnalytics && (
          <SidebarLink
            icon="activity"
            logName="workspace-analytics"
            title="Analytics"
            to={SidebarRoutes.Analytics}
          />
        )}
        <SidebarLink
          icon="lock"
          logName="workspace-security"
          title="Security"
          to={SidebarRoutes.SSO}
        />
        {apiKeysEnabled && (
          <SidebarLink
            icon="key"
            logName="workspace-api-keys"
            title="API Keys"
            to={SidebarRoutes.APIKeys}
          />
        )}
        <SidebarLink
          icon="update"
          logName="workspace-billing"
          title="Billing"
          to={SidebarRoutes.Billing}
        />
      </div>
    </div>
  );
};

const PersonalLinks: FC = () => {
  const dispatch = useDispatch();
  const sidebarPersonalOpen = useGlobalState(
    (state) => state.sidebar.personalOpen
  );

  const userEmail = useUserSafe((u) => u.email);

  const onToggleExpand = useCallback(() => {
    dispatch(setSidebarPersonalOpen(!sidebarPersonalOpen));
  }, [dispatch, sidebarPersonalOpen]);

  const handleLogout = useCallback(() => {
    trackEvent(AnalyticsEvent.LogoutClick);
    void logout();
  }, []);

  return (
    <div className="linksSection">
      <SectionHeader
        active={sidebarPersonalOpen}
        iconName="person"
        logName="personal"
        onToggleExpand={onToggleExpand}
        title="Personal"
      />
      <div
        className={classNames('linksChildren', {
          hidden: !sidebarPersonalOpen,
        })}
      >
        <SidebarLink
          appCuesId={AppCuesIds.MySettingsLink}
          icon="design"
          logName="personal-general"
          title="General"
          to={SidebarRoutes.MySettings}
        />
        <SidebarLink
          icon="settings-2"
          logName="personal-ai-controls"
          title="AI Controls"
          to={SidebarRoutes.MyAiControls}
        />
        <SidebarLink
          icon="bell"
          logName="notification-preferences"
          title="Notifications"
          to={SidebarRoutes.NotificationsPreferences}
        />
        <SidebarLink
          icon="extension"
          logName="browser-extension"
          title="Browser Extension"
          to={SidebarRoutes.BrowserExtension}
        />
        <SidebarButton logName="logout" onClick={handleLogout}>
          <Tooltip title={userEmail}>
            <Box>
              <UIIcon className="sidebarLinkIcon" name="log-out" />
              Logout
            </Box>
          </Tooltip>
        </SidebarButton>
      </div>
    </div>
  );
};

export const Sidebar: FC = () => {
  const [userManagedBots, setUserManagedBots] = useState<BotMinimal[]>([]);
  const qaController = useQAController();
  const dispatch = useDispatch();

  const location = useLocation();

  const allTopics = qaController.useTopics();

  const sidebarOpen = useSidebarOpen();
  const sidebarSettingsOpen = useGlobalState(
    (state) => state.sidebar.settingsOpen
  );

  const multiplayerEnabled = useFlag('multiplayerEnabled');

  const sidebarNotificationsOpen = useGlobalState(
    (state) => state.sidebar.notificationsOpen
  );

  const [topicsOpen, setTopicsOpen] = useState(true);
  const [sharedTopicsOpen, setSharedTopicsOpen] = useState(false);
  const [managedBotsTopicsOpen, setManagedBotsTopicsOpen] = useState<
    string | null
  >(null);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    if (searchParams.get('open_inbox')) {
      dispatch(setSidebarNotificationsOpen(true));
    }
  }, [location, dispatch]);

  useEffect(() => {
    if (!multiplayerEnabled) {
      return;
    }

    invokeFastApi<{
      user_managed_bots: BotMinimal[];
    }>({
      path: '/bots/managed/user',
      method: 'GET',
      shouldRetry: true,
    })
      .then(({ user_managed_bots }) => {
        setUserManagedBots(user_managed_bots);
      })
      .catch(logError);
  }, [multiplayerEnabled]);

  useEffect(() => {
    if (topicsOpen) {
      setSharedTopicsOpen(false);
      setManagedBotsTopicsOpen(null);
    }
  }, [topicsOpen]);

  return (
    <div
      className={classNames(
        'sidebar h-screen bg-cloud-10 text-sm overflow-y-auto scrollbar scrollbar-track-white scrollbar-thumb-gray-30 scrollbar-thin',
        {
          open: sidebarOpen,
        }
      )}
    >
      {sidebarSettingsOpen ? (
        <SettingsLinks />
      ) : (
        <>
          <SidebarHeader
            setTopicsOpen={setTopicsOpen}
            topicsOpen={topicsOpen}
          />
          <div className="min-h-[200px] flex flex-col flex-grow">
            <TopicsList
              topicsOpen={topicsOpen}
              topicsType={TopicType.MY_TOPICS}
            />

            {multiplayerEnabled &&
              Boolean(allTopics[TopicType.SHARED_WITH_ME].length > 0) && (
                <>
                  <div className="px-2 flex flex-col gap-0.5 border-solid border-0 border-t border-cloud-15">
                    <div
                      className="sidebarLink flex text-black items-center rounded justify-between cursor-pointer"
                      onClick={() => {
                        setSharedTopicsOpen(!sharedTopicsOpen);
                        setTopicsOpen(sharedTopicsOpen);
                        setManagedBotsTopicsOpen(null);
                      }}
                    >
                      <div
                        className={classNames('flex items-center gap-1.5', {
                          'font-semibold': qaController.hasUnread(
                            TopicType.SHARED_WITH_ME
                          ),
                        })}
                      >
                        <UIIcon name="people" size={20} />
                        Shared with me
                      </div>
                      <UIIcon
                        name={sharedTopicsOpen ? 'arrow-up' : 'arrow-down'}
                        size={20}
                      />
                    </div>
                  </div>
                  <TopicsList
                    topicsOpen={sharedTopicsOpen}
                    topicsType={TopicType.SHARED_WITH_ME}
                  />
                </>
              )}

            {multiplayerEnabled &&
              userManagedBots.map((bot) => (
                <React.Fragment key={bot.id}>
                  <div className="px-2 flex flex-col gap-0.5 border-solid border-0 border-t border-cloud-15">
                    <div
                      className="sidebarLink flex text-black items-center rounded justify-between cursor-pointer"
                      onClick={() => {
                        setSharedTopicsOpen(false);

                        if (managedBotsTopicsOpen === bot.id) {
                          setManagedBotsTopicsOpen(null);
                          setTopicsOpen(true);
                        } else {
                          setManagedBotsTopicsOpen(bot.id);
                          setTopicsOpen(false);
                        }
                      }}
                    >
                      <div
                        className={classNames('flex items-center gap-1.5', {
                          'font-semibold': qaController.hasUnread(
                            TopicType.MANAGED_BOTS,
                            bot.id
                          ),
                        })}
                      >
                        <span className="text-xl/5">{bot.icon}</span>{' '}
                        {bot.bot_name.length > 20
                          ? `${bot.bot_name.slice(0, 20)}...`
                          : bot.bot_name}
                      </div>
                      <UIIcon
                        name={
                          managedBotsTopicsOpen === bot.id
                            ? 'arrow-up'
                            : 'arrow-down'
                        }
                        size={20}
                      />
                    </div>
                  </div>
                  <TopicsList
                    managedBotId={bot.id}
                    topicsOpen={managedBotsTopicsOpen === bot.id}
                    topicsType={TopicType.MANAGED_BOTS}
                  />
                </React.Fragment>
              ))}
          </div>
          <SidebarFooter />
        </>
      )}

      <NotificationsSidebar isOpen={sidebarNotificationsOpen} />

      {createPortal(
        <div
          className={classNames(
            'fixed top-0 left-0 bottom-0 right-0 bg-gray-50/50 z-[135] hidden',
            {
              'max-lgr:block': sidebarOpen,
            }
          )}
          onClick={() => {
            toggleSidebar(false);
          }}
        />,
        document.body
      )}
    </div>
  );
};
