import { UseCases } from './types';

export const defaultUseCases: UseCases = {
  Understand: [
    {
      title: 'How to request time off?',
      question: 'How to request time off?',
    },
    {
      title: 'How does feature work?',
      question: 'How does feature work?',
    },
    {
      title: 'Q4 Company Goals',
      question: 'Q4 Company Goals',
    },
  ],
  Create: [
    {
      title: 'Write follow up email',
      question: 'Write follow up email',
    },
    {
      title: 'Generate social post',
      question: 'Generate social post',
    },
    {
      title: 'Plan a presentation',
      question: 'Plan a presentation',
    },
  ],
  Find: [
    {
      title: 'Link to Q1 Sales Report',
      question: 'Link to Q1 Sales Report',
    },
    {
      title: 'Link to Onboarding Guide',
      question: 'Link to Onboarding Guide',
    },
    {
      title: 'Link to Daily Updates doc',
      question: 'Link to Daily Updates doc',
    },
  ],
};

export const engineeringUseCases: UseCases = {
  Understand: [
    {
      title: 'Debug Errors & On-Call',
      question: 'How do I resolve a failed deployment for [environment name]?',
    },
    {
      title: 'Understand Codebase',
      question:
        'What does the function [function name] do in the [repository name] repository?',
    },
    {
      title: 'Research Projects',
      question:
        'Summarize the goals and outcomes of the [initiative name] project.',
    },
  ],
  Create: [
    {
      title: 'Review & Optimize Code',
      question: 'Can you review this code snippet for potential issues?',
    },
    {
      title: 'Write SQL Queries',
      question:
        'Write a SQL query to find all users who signed up in the last 30 days.',
    },
    {
      title: 'Automate Tasks',
      question: 'Generate a boilerplate code for [specific use case].',
    },
  ],
  Find: [
    {
      title: 'Find Team Experts',
      question: 'Who worked on the [specific feature] implementation?',
    },
    {
      title: 'Onboard Engineers',
      question: 'What is the branching strategy for our Git repositories?',
    },
    {
      title: 'Link to Design Docs',
      question: 'Where can I find the design documents for [specific feature]?',
    },
  ],
};

export const customerSupportUseCases: UseCases = {
  Understand: [
    {
      title: 'Resolve Customer Issues',
      question: "How do I reset a customer's password?",
    },
    {
      title: 'Understand Product',
      question: 'How does feature X work in our product?',
    },
    {
      title: 'Handle Refunds',
      question: 'What is our refund policy for [specific product]?',
    },
  ],
  Create: [
    {
      title: 'Draft Help Articles',
      question: 'Write a step-by-step guide for setting up [specific feature].',
    },
    {
      title: 'Automate FAQ Responses',
      question: 'What is the process for resetting a password?',
    },
    {
      title: 'Create Training Material',
      question:
        'What should be included in the training materials for new support agents?',
    },
  ],
  Find: [
    {
      title: 'Manage Accounts',
      question: 'Who is the account manager for [customer name]?',
    },
    {
      title: 'Collaborate with Teams',
      question: 'What is the status of the bug reported in ticket [ticket ID]?',
    },
    {
      title: 'Onboard Support Agents',
      question: 'What are the steps for handling a refund request?',
    },
  ],
};

export const salesUseCases: UseCases = {
  Understand: [
    {
      title: 'Prepare for Calls',
      question: 'Summarize my past communications with [prospect name].',
    },
    {
      title: 'Answer Tech Questions',
      question: 'How does our product integrate with Salesforce?',
    },
    {
      title: 'Handle Objections',
      question: 'How do we compare to [competitor] on pricing?',
    },
  ],
  Create: [
    {
      title: 'Draft Pitches & Emails',
      question:
        'Draft a follow-up email for [prospect name] after our meeting.',
    },
    {
      title: 'Fill Security Forms',
      question: 'Are we SOC-2 Type 2 compliant?',
    },
    {
      title: 'Onboard Sales Reps',
      question: 'What is the sales process for [specific product]?',
    },
  ],
  Find: [
    {
      title: 'Find Sales Collateral',
      question: 'Where can I find the latest sales deck for [industry]?',
    },
    {
      title: 'Research Prospects',
      question: "What is [prospect company]'s annual revenue?",
    },
    {
      title: 'Find Case Studies',
      question:
        'Where can I find case studies relevant to [specific industry]?',
    },
  ],
};

export const marketingUseCases: UseCases = {
  Understand: [
    {
      title: 'Market Research',
      question:
        'Generate a fact sheet on [company] and include their headcount, office locations, recent news, leadership team, and top competitors.',
    },
    {
      title: 'Synthesize Feedback',
      question:
        'What are the most common pain points mentioned in customer feedback?',
    },
    {
      title: 'Uncover Insights',
      question:
        'What are effective landing page ideas for a campaign targeting [audience]?',
    },
  ],
  Create: [
    {
      title: 'Create Content',
      question:
        'Draft a blog post about the benefits of using our product for [specific industry].',
    },
    {
      title: 'Draft Collateral',
      question: 'What metrics can I use to demonstrate ROI from [Feature]?',
    },
    {
      title: 'Repurpose Content',
      question: 'Turn this blog post into a LinkedIn article.',
    },
  ],
  Find: [
    {
      title: 'Manage Social Media',
      question:
        'Write a Twitter thread about the latest trends in [specific industry].',
    },
    {
      title: 'Customer Outreach',
      question:
        'Draft a personalized email for [specific persona] about [specific feature].',
    },
    {
      title: 'Plan Events',
      question: 'Write an email invitation for our upcoming webinar.',
    },
  ],
};

export const productManagementUseCases: UseCases = {
  Understand: [
    {
      title: 'Answer Product Questions',
      question: 'What are the key features of [specific product/feature]?',
    },
    {
      title: 'Synthesize Feedback',
      question: 'What are the top complaints from users about [feature]?',
    },
    {
      title: 'Competitive Analysis',
      question: 'Who are the main competitors for [product/service]?',
    },
  ],
  Create: [
    {
      title: 'Manage Roadmaps',
      question: 'What are the upcoming milestones for [project]?',
    },
    {
      title: 'Draft Documentation',
      question: 'Draft an RFC for [new feature].',
    },
    {
      title: 'Onboard Team Members',
      question: "What is the company's product strategy?",
    },
  ],
  Find: [
    {
      title: 'Research Projects',
      question: 'What were the key challenges and solutions in [project]?',
    },
    {
      title: 'Find User Stories',
      question: 'Where can I find user stories for [specific feature]?',
    },
    {
      title: 'Find Market Data',
      question: 'Where can I find market data for [specific industry]?',
    },
  ],
};

export const hrUseCases: UseCases = {
  Understand: [
    {
      title: 'Support Learning',
      question: 'Where can I find training materials for [specific skill]?',
    },
    {
      title: 'Synthesize Feedback',
      question: 'What are the most common concerns raised in employee surveys?',
    },
    {
      title: 'Manage Compliance',
      question:
        'What are the legal requirements for terminating an employee in [specific location]?',
    },
  ],
  Create: [
    {
      title: 'Streamline Onboarding',
      question: 'What are the steps to set up my company email?',
    },
    {
      title: 'Automate HR Tasks',
      question: 'How do I update my personal information in the HR system?',
    },
    {
      title: 'Write Feedback',
      question:
        'Can you help me write constructive feedback for [name] / [role] who consistently meets deadlines but struggles with team communication?',
    },
  ],
  Find: [
    {
      title: 'Manage Policies',
      question: 'What is our remote work policy?',
    },
    {
      title: 'Improve Engagement',
      question:
        'How do I nominate a colleague for the employee recognition program?',
    },
    {
      title: 'Communicate Updates',
      question: 'How do I communicate [Policy Update] to employees?',
    },
  ],
};

export const legalComplianceUseCases: UseCases = {
  Understand: [
    {
      title: 'Verify Compliance',
      question:
        'What are the GDPR requirements for data retention in our industry?',
    },
    {
      title: 'Clarify Terms',
      question:
        "Explain the difference between 'indemnity' and 'liability' clauses.",
    },
    {
      title: 'Research Regulations',
      question:
        'What are the HIPAA requirements for data encryption in healthcare?',
    },
  ],
  Create: [
    {
      title: 'Draft Legal Docs',
      question: 'Find me our NDA template for new vendors',
    },
    {
      title: 'Generate Reports',
      question: 'Generate a compliance report for our recent ISO 27001 audit.',
    },
    {
      title: 'Train Legal Teams',
      question: 'What are the key compliance policies new hires should know?',
    },
  ],
  Find: [
    {
      title: 'Manage Docs',
      question:
        'Where can I find the latest version of our data privacy policy?',
    },
    {
      title: 'Answer Security Questions',
      question:
        'What are the access control policies for sensitive customer data?',
    },
    {
      title: 'Support Negotiations',
      question: 'What are the standard payment terms in our vendor contracts?',
    },
  ],
};

export const talentUseCases: UseCases = {
  Understand: [
    {
      title: 'Understand Roles',
      question: 'What are the key responsibilities for [specific role]?',
    },
    {
      title: 'Analyze Profiles',
      question: 'What are the strengths and weaknesses of [candidate name]?',
    },
    {
      title: 'Evaluate Interviews',
      question: 'How did [candidate name] perform in their interview?',
    },
  ],
  Create: [
    {
      title: 'Create Job Descriptions',
      question: 'Write a job description for a [specific role].',
    },
    {
      title: 'Draft Interview Questions',
      question: 'What are good interview questions for hiring [Specific Role]?',
    },
    {
      title: 'Develop Onboarding Plans',
      question:
        'What should be included in the onboarding plan for [specific role]?',
    },
  ],
  Find: [
    {
      title: 'Search Candidates',
      question:
        'Where can I find candidates with experience in [specific skill]?',
    },
    {
      title: 'Identify Skill Gaps',
      question: 'What are the skill gaps in our current team?',
    },
    {
      title: 'Find Training Resources',
      question: 'Where can I find training resources for [specific skill]?',
    },
  ],
};
