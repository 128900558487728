import { makeLazyComponent } from '../../../../scripts/utils';

export const LazyQAReferencesSidecar = makeLazyComponent(
  async () =>
    (
      await import(
        /* webpackChunkName: "members-table-dialog" */ './QAReferencesSidecar'
      )
    ).QAReferencesSidecar
);
