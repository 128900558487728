import { makeLazyComponent } from '../../../scripts/utils';

export const LazyMembersTableDialog = makeLazyComponent(
  async () =>
    (
      await import(
        /* webpackChunkName: "members-table-dialog" */ './MembersTableDialog'
      )
    ).MembersTableDialog
);
