/* eslint-disable max-lines-per-function */
import { useEffect, useState } from 'react';
import { UploadedFile } from '../../models/File';
import { invokeFastApi } from '../apis/fastapi';
import { ToastType } from '../models/toast';
import { logError } from '../utils';
import { useGlobalState, useLoginTokens, useUserSafe } from './redux';
import { useToaster } from './toast';

export interface UploadedFileResponse {
  id: string;
  name: string;
}

interface FilesResponse {
  files: UploadedFile[];
  loading: boolean;
  uploadStatus: UploadStatus;
  getFiles: () => Promise<void>;
  uploadFiles: (
    files: File[],
    isZipFile: boolean,
    siteId: string
  ) => Promise<void>;
  uploadQAFiles: (
    files: File[]
  ) => Promise<{ document: UploadedFileResponse }[] | null>;
  deleteFile: (id: string) => Promise<void>;
}

export const enum UploadStatus {
  PENDING = 0,
  TRANSIT = 1,
  DONE = 2,
}

export const enum FileVisibility {
  ORG = 'ORG',
  CHAT = 'CHAT',
}

export const useFiles = (): FilesResponse => {
  const [files, setFiles] = useState<UploadedFile[]>([]);
  const [loading, setLoading] = useState(false);
  const [uploadStatus, setUploadStatus] = useState(UploadStatus.PENDING);
  const idToken = useLoginTokens();
  const userEmail = useUserSafe((u) => u.email);
  const { userDisplayName, userPhotoUrl } = useGlobalState(
    (state) => state.meta
  );

  const toaster = useToaster();

  const getFiles = async () => {
    setLoading(true);
    setFiles([]);
    try {
      const res = await invokeFastApi({
        path: '/document_uploads',
        method: 'GET',
        shouldRetry: true,
      });

      const { documents } = (await res) as { documents: UploadedFile[] };
      setFiles(documents);
    } catch (fetchError) {
      logError(fetchError);
      toaster.failure('Failed to fetch files');
    }

    setLoading(false);
  };

  const _uploadFiles = async (
    toBeUploadedFiles: File[],
    isZipFile: boolean,
    siteId: string,
    visibility: FileVisibility
  ): Promise<
    | {
        document: UploadedFileResponse;
      }[]
    | null
  > => {
    setUploadStatus(UploadStatus.TRANSIT);
    const FILE_UPLOADING_TOAST_UID = 'uploading-qa-files';

    try {
      const filesCountMessage =
        toBeUploadedFiles.length > 1
          ? `${toBeUploadedFiles.length} Files`
          : 'File';

      toaster.create({
        type: ToastType.LOADING,
        message: `Uploading ${filesCountMessage}. Please keep tab open`,
        uid: FILE_UPLOADING_TOAST_UID,
      });

      const uploadPromises: Promise<{
        response: Response | null;
        file: File;
        errorMessage?: string;
      }>[] = toBeUploadedFiles.map(async (file) => {
        if (file.size > 1_000_000_000) {
          return {
            response: null,
            file,
            errorMessage: `Failed to upload file: ${file.name}. File size is too large. Max size is 1GB`,
          };
        }

        const formData = new FormData();
        formData.append('file', file);
        if (!isZipFile) formData.append('visibility', visibility);

        const uploadFileApiUrl = isZipFile
          ? `${SEARCH_URL}/document_uploads/zip`
          : `${SEARCH_URL}/document_uploads`;

        try {
          const response = await fetch(uploadFileApiUrl, {
            headers: {
              ...(idToken
                ? { authorization: `Bearer ${idToken}`, 'site-id': siteId }
                : { 'site-id': siteId }),
            },
            method: 'POST',
            body: formData,
          });

          if (!response.ok) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            const errorData = (await response
              .json()
              .catch(() => ({ error: undefined }))) as { error?: string };

            if (
              response.status === 400 &&
              errorData.error === 'No content was parsed from the file.'
            ) {
              return {
                response,
                file,
                errorMessage: `Failed to upload: ${file.name}. The document appears to be empty or its content could not be parsed. Please check the file and try again.`,
              };
            }
          }

          return { response, file };
        } catch {
          return {
            response: null,
            file,
            errorMessage: `Failed to upload file: ${file.name}`,
          };
        }
      });

      const uploadPromisesResponses = await Promise.all(uploadPromises);

      const failedUploads = uploadPromisesResponses.filter(
        (item) => !item.response?.ok
      );

      if (failedUploads.length > 0) {
        for (const failedUpload of failedUploads) {
          const fileName = failedUpload.file.name;
          toaster.failure(
            failedUpload.errorMessage ?? `Failed to upload file: ${fileName}`
          );
        }
      }

      const successfulUploads = uploadPromisesResponses.filter(
        (item) => item.response?.ok
      );

      const uploadedFilesData = await Promise.all(
        successfulUploads.map(async (item) => {
          const data = (await item.response!.json()) as {
            document: UploadedFileResponse;
          };

          return data;
        })
      );

      if (uploadedFilesData.length > 0) {
        setUploadStatus(UploadStatus.DONE);
        const uploadedFilesCountMessage =
          uploadedFilesData.length > 1
            ? `${uploadedFilesData.length} Files`
            : 'File';

        toaster.success(`Successfully uploaded ${uploadedFilesCountMessage}`);
      } else {
        setUploadStatus(UploadStatus.PENDING);
      }

      return uploadedFilesData;
    } catch (error) {
      logError(error);

      toaster.failure('Failed to upload files');
      setUploadStatus(UploadStatus.PENDING);
    } finally {
      toaster.delete(FILE_UPLOADING_TOAST_UID);
    }

    return null;
  };

  const uploadFiles = async (
    toBeUploadedFiles: File[],
    isZipFile: boolean,
    siteId: string
  ): Promise<void> => {
    const uploadedFiles = await _uploadFiles(
      toBeUploadedFiles,
      isZipFile,
      siteId,
      FileVisibility.ORG
    );

    if (isZipFile) return;

    if (uploadedFiles) {
      setFiles((prev) => [
        ...uploadedFiles.map((file) => {
          return {
            id: file.document.id,
            title: file.document.name,
            created_at: Math.floor(Date.now() / 1000),
            creator: {
              icon: userPhotoUrl,
              name: userDisplayName,
              email: userEmail,
            },
          };
        }),
        ...prev,
      ]);
    }
  };

  const uploadQAFiles = async (
    toBeUploadedFiles: File[]
  ): Promise<{ document: UploadedFileResponse }[] | null> => {
    const uploadedFiles = await _uploadFiles(
      toBeUploadedFiles,
      false,
      '',
      FileVisibility.CHAT
    );

    return uploadedFiles;
  };

  const deleteFile = async (id: string) => {
    try {
      await invokeFastApi({
        path: `/document_uploads/${id}`,
        method: 'DELETE',
      });

      setFiles((prevFiles) => {
        const fileIndex = prevFiles.findIndex((file) => file.id === id);
        if (fileIndex === -1) {
          // File not found, return the previous state
          return prevFiles;
        }

        // Create a new array without the deleted file
        const newFiles = [
          ...prevFiles.slice(0, fileIndex),
          ...prevFiles.slice(fileIndex + 1),
        ];

        return newFiles;
      });

      toaster.success('File deleted successfully');
    } catch (error) {
      logError(error);
      toaster.failure('Failed to delete file');
    }
  };

  useEffect(() => {
    getFiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    files,
    loading,
    uploadStatus,
    getFiles,
    uploadFiles,
    deleteFile,
    uploadQAFiles,
  };
};
