import { useEffect, useState } from 'react';
import { Workflow } from '../../models/Workflows';
import { invokeFastApi } from '../apis/fastapi';
import { logError } from '../utils';
import { useToaster } from './toast';

// whole point is to verify type...
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isWorkflowArray(data: any): boolean {
  return Array.isArray(data);
}

interface UseWorkflowsReturn {
  loading: boolean;
  error: boolean;
  workflows: Workflow[];
  fetchWorkflows: (query?: string) => Promise<void>;
}

export const useWorkflows = (): UseWorkflowsReturn => {
  const [workflows, setWorkflows] = useState<Workflow[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const toaster = useToaster();

  const fetchWorkflows = async (query?: string) => {
    setLoading(true);
    setError(false);
    setWorkflows([]);

    try {
      const res = await invokeFastApi({
        path: '/prompts',
        method: 'GET',
        queryParams: { query },
        shouldRetry: true,
      });

      const data = await res;

      if (!isWorkflowArray(data)) {
        setError(true);
        return;
      }

      setWorkflows(data as Workflow[]);
    } catch (fetchError) {
      logError(fetchError);
      setError(true);
      toaster.failure('Error fetching workflows');
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchWorkflows();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loading,
    error,
    workflows,
    fetchWorkflows,
  };
};

interface UseWorkflowByIdReturn {
  loading: boolean;
  error: boolean;
  workflow?: Workflow;
}

export const useWorkflowById = (id?: string): UseWorkflowByIdReturn => {
  const [workflow, setWorkflow] = useState<Workflow | undefined>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const toaster = useToaster();

  const fetchWorkflow = async () => {
    if (!id) return;

    setLoading(true);
    setError(false);

    try {
      const res = await invokeFastApi({
        path: `/prompts/promptsById/${id}`,
        method: 'GET',
        shouldRetry: true,
      });

      const data = await res;

      if (!data) {
        setError(true);
        return;
      }

      setWorkflow(data as Workflow);
    } catch (fetchError) {
      logError(fetchError);
      setError(true);
      toaster.failure('Error fetching workflow');
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchWorkflow();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loading,
    error,
    workflow,
  };
};

interface UseRecommendedWorkflowsReturn {
  loading: boolean;
  error: boolean;
  workflows: Workflow[];
  fetchRecommendedWorkflows: () => Promise<void>;
}

export const useRecommendedWorkflows = (): UseRecommendedWorkflowsReturn => {
  const [workflows, setWorkflows] = useState<Workflow[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const fetchRecommendedWorkflows = async () => {
    setLoading(true);
    setError(false);

    try {
      const res = await invokeFastApi({
        path: '/prompts/recommended',
        method: 'GET',
        shouldRetry: true,
      });

      const data = await res;

      if (!isWorkflowArray(data)) {
        setError(true);
        return;
      }

      setWorkflows(data as Workflow[]);
    } catch (fetchError) {
      logError(fetchError);
      setError(true);
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchRecommendedWorkflows();
  }, []);

  return {
    loading,
    error,
    workflows,
    fetchRecommendedWorkflows,
  };
};
