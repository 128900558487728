import React, { FC, useCallback } from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import notionFilePickerScreen from '../../assets/images/admin/notion_admin_file_picker.gif';
import { InstallChromeExtensionDialog } from '../../components/controls/InstallExtensionDialog/chrome/InstallExtensionDialog';
import { UIButton } from '../../components/controls/ui/UIButton/UIButton';
import { withInstallModal } from '../../hoc/withInstallModal';
import { onSetNotionToken } from '../../scripts/apis/updateNotionCookie';
import { InstallDialogProps } from '../../scripts/app';
import {
  EventSource,
  MessageAction,
} from '../../scripts/constants/message-action';
import { useBoolState, useFlag } from '../../scripts/hooks';
import { useExtensionObserver } from '../../scripts/hooks/extension';
import { isChrome, logError } from '../../scripts/utils';
import { ConnectStep } from '../generic/Components';
import { InformationOnlyDialog } from '../generic/InformationOnlyDialog';

const HybridInstallDialog = withInstallModal(
  ({ connectInProgress, handleOAuthInstall }) => {
    const [
      openInstallChromeExtensionDialog,
      setOpenInstallChromeExtensionDialog,
      setNotOpenInstallChromeExtensionDialog,
    ] = useBoolState(false);

    const extensionInstalled = useExtensionObserver();
    const unsupportedBrowser = !isChrome;

    const handleClickDone = useCallback(async () => {
      try {
        await handleOAuthInstall();

        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        window.addEventListener('message', onSetNotionToken);
        window.postMessage({
          action: MessageAction.RefreshNotionCookie,
          source: EventSource.WEB_APP,
        });
      } catch (error) {
        logError(error);
      }
    }, [handleOAuthInstall]);

    return (
      <>
        <ConnectStep step={1} title="Install Dashworks Extension">
          <p>
            Dashworks connects to Notion through our browser extension, which
            securely reads your Notion cookies to search your Notion workspace.
          </p>
          {unsupportedBrowser ? (
            <p className="text-mahogany-20">
              Dashworks extension is only supported on Chrome. Please use Chrome
              to install the extension.
            </p>
          ) : (
            <UIButton
              disabled={extensionInstalled}
              onClick={setOpenInstallChromeExtensionDialog}
              size="large"
            >
              {extensionInstalled ? 'Extension installed' : 'Install'}
            </UIButton>
          )}
        </ConnectStep>
        <ConnectStep step={2} title="Grant Notion access">
          <p>
            Click Connect and select the pages Dashworks can search. We
            recommend selecting all top-level pages for complete results.
          </p>
        </ConnectStep>
        <InstallChromeExtensionDialog
          onClose={setNotOpenInstallChromeExtensionDialog}
          open={openInstallChromeExtensionDialog}
        />
        {connectInProgress(handleClickDone, !extensionInstalled || !isChrome)}
      </>
    );
  }
);

const OAuthInstallDialog: FC<InstallDialogProps> = ({
  app,
  isOrg,
  onClose,
  open,
}) => (
  <InformationOnlyDialog app={app} isOrg={isOrg} onClose={onClose} open={open}>
    <ConnectStep
      step={1}
      title="Select the pages that should be searchable by anyone in the organization."
    >
      <p>
        Make sure you don&apos;t select any pages from your private workspace.
        <br />
        As this an experimental feature,{' '}
        <strong>
          results may show up in Dashworks even if the user does not have access
          to the page
        </strong>
        . However such users will not be able to gain access to the Notion page.
      </p>
      <div className="screenFrame">
        <img src={notionFilePickerScreen} />
      </div>
    </ConnectStep>
    <ConnectStep step={2} title="Connect Dashworks">
      <p>Click the button below to complete the connection.</p>
    </ConnectStep>
  </InformationOnlyDialog>
);

export const AppInstallModal: FC<InstallDialogProps> = ({
  app,
  isOrg,
  onClose,
  open,
}) => {
  const flag = useFlag('notionUserAuth');

  return flag ? (
    <HybridInstallDialog
      app={app}
      isOrg={isOrg}
      onClose={onClose}
      open={open}
    />
  ) : (
    <OAuthInstallDialog app={app} isOrg={isOrg} onClose={onClose} open={open} />
  );
};
