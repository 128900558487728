/* eslint-disable max-lines-per-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-len */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import {
  TOPIC_LINK_COPIED_FAILURE_MESSAGE,
  TOPIC_LINK_COPIED_SUCCESS_MESSAGE,
} from '../../../constants';
import { trackEvent } from '../../../extra/sharedMethods';
import { CreatorDetails, ManagedBotAdminsResponse } from '../../../models/Bots';
import {
  ConversationMember,
  ConversationMemberRole,
  QATopicAccess,
  QATopicAccessType,
  TopicAuthor,
} from '../../../models/QAmodels';
import { useQAController } from '../../../scripts/QAController';
import { invokeFastApi } from '../../../scripts/apis/fastapi';
import { AnalyticsEvent } from '../../../scripts/constants/analytics-event';
import { useFlag, useToaster, useUserSafe } from '../../../scripts/hooks';
import { useMembers } from '../../../scripts/hooks/members';
import { debounce, uniqBy } from '../../../scripts/utils';
import { Loading } from '../../controls/Loading/Loading';
import { UserImage } from '../../controls/UserImage/UserImage';
import { Modal } from '../../controls/ui/Modal/Modal';
import { UIButton } from '../../controls/ui/UIButton/UIButton';
import { UIIcon } from '../../controls/ui/UIIcon/UIIcon';
import { UISelect } from '../../controls/ui/UISelect/UISelect';
import { UITextbox } from '../../controls/ui/UITextbox/UITextbox';
import { UserProfileIcon } from '../../general/UserProfile/UserProfile';
import { convertConversationMemberToCreatorDetails } from './utils';

interface TopicShareModalProps {
  onClose: () => void;
  conversationId: string;
  topicTitle?: string;
  topicAuthor: TopicAuthor;
  topicAccess: QATopicAccess;
}

const commonCardClassNames = 'flex gap-2 mb-4 items-center';

export const TopicShareModal = ({
  onClose,
  conversationId,
  topicTitle,
  topicAuthor,
  topicAccess,
}: TopicShareModalProps): JSX.Element => {
  const toaster = useToaster();
  const publicTopicsFeatureEnabled = useFlag('publicTopics');

  const [members, setMembers] = useState<CreatorDetails[]>([]);
  const {
    orgByOrgId: { publicTopicsEnabled: publicTopicsEnabledInOrg },
  } = useUserSafe();

  const [managedBotAdmins, setManagedBotAdmins] =
    useState<ManagedBotAdminsResponse | null>(null);

  const [showMemberSuggestions, setShowMemberSuggestions] = useState(false);
  const [loading, setLoading] = useState(false);

  const qaController = useQAController();

  const newlyAddedConversationMembers =
    qaController.useNewlyAddedConversationMembers();

  const allMembers: CreatorDetails[] = useMemo(() => {
    return uniqBy(
      [
        {
          icon: topicAuthor.icon,
          email: topicAuthor.email,
          display_name: topicAuthor.displayName ?? topicAuthor.email,
        },
        ...members,
        ...newlyAddedConversationMembers
          .filter((member) => member.conversation_id === conversationId)
          .map((m) => convertConversationMemberToCreatorDetails(m)),
      ],
      'email'
    );
  }, [members, newlyAddedConversationMembers, conversationId, topicAuthor]);

  const generalAccessOptions = useMemo(() => {
    const commonOptions = [
      {
        label: 'Invite only',
        value: 'invite-only',
      },
      {
        label: 'Workspace',
        value: 'workspace',
      },
    ];

    return [
      ...commonOptions,
      ...(publicTopicsEnabledInOrg && publicTopicsFeatureEnabled
        ? [{ label: 'Anyone with link', value: 'public' }]
        : []),
    ];
  }, [publicTopicsEnabledInOrg, publicTopicsFeatureEnabled]);

  const memberEmailsToSkip = useMemo(() => {
    const set = new Set<string>();

    set.add(topicAuthor.email);

    for (const member of allMembers) {
      set.add(member.email);
    }

    if (managedBotAdmins)
      for (const [, botAdmins] of managedBotAdmins) {
        for (const botAdmin of botAdmins) {
          set.add(botAdmin.email);
        }
      }

    return Array.from(set);
  }, [allMembers, managedBotAdmins, topicAuthor]);

  const generalAccessDetails: {
    icon: 'company' | 'globe' | 'group' | 'lock';
    title: string;
    description: string;
  } = useMemo(() => {
    if (topicAccess.isPublic) {
      return {
        icon: 'globe',
        title: 'Anyone with link',
        description: 'Anyone on the internet with the link can view',
      };
    }

    if (topicAccess.isOrgWide) {
      return {
        icon: 'company',
        title: 'Workspace',
        description: `All workspace members with the link can view`,
      };
    }

    const inviteOnlyTitle = 'Invite only';

    if (topicAccess.isMultiplayer) {
      return {
        icon: 'group',
        title: inviteOnlyTitle,
        description: 'Only you and invited or tagged members can access',
      };
    }

    return {
      icon: 'lock',
      title: inviteOnlyTitle,
      description: 'Only you can access',
    };
  }, [topicAccess]);

  const loadMembers = useCallback(async () => {
    if (!conversationId) {
      toaster.failure('Failed to load members');
      return;
    }

    try {
      setLoading(true);
      const result = await invokeFastApi<{
        members: ConversationMember[];
        managed_bot_admins: ManagedBotAdminsResponse;
      }>({
        path: `/topics/topic/members/${conversationId}`,
        method: 'GET',
        shouldRetry: true,
      });

      const admins: CreatorDetails[] = [];

      for (const [_, botAdmins] of result.managed_bot_admins) {
        admins.push(...botAdmins);
      }

      setMembers((prevMembers) =>
        uniqBy(
          [
            ...prevMembers,
            ...admins,
            ...result.members.map((m) =>
              convertConversationMemberToCreatorDetails(m)
            ),
          ],
          'email'
        )
      );

      setManagedBotAdmins(result.managed_bot_admins);
    } catch {
      toaster.failure('Failed to load members');
    } finally {
      setLoading(false);
    }
  }, [conversationId, toaster]);

  const copyTopicLink = useCallback(() => {
    if (!conversationId) {
      toaster.failure(TOPIC_LINK_COPIED_FAILURE_MESSAGE);
      return;
    }

    const sharableLink = `${window.location.origin}/topic/${conversationId}`;
    navigator.clipboard.writeText(sharableLink);

    toaster.success(TOPIC_LINK_COPIED_SUCCESS_MESSAGE);
    trackEvent(AnalyticsEvent.SharableLinkCreated);
  }, [conversationId, toaster]);

  const updateTopicAccess = useCallback(
    async (access: 'invite-only' | 'public' | 'workspace') => {
      if (access === 'public') {
        //
        await qaController.updateConversationVisibility(
          conversationId,
          QATopicAccessType.PUBLIC,
          { ...topicAccess, isPublic: true }
        );

        return;
      }

      if (access === 'workspace') {
        await qaController.updateConversationVisibility(
          conversationId,
          QATopicAccessType.ORG,
          { ...topicAccess, isOrgWide: true, isPublic: false }
        );

        return;
      }

      /*
       * remove org membership
       * remove is_public
       */
      await qaController.updateConversationVisibility(
        conversationId,
        QATopicAccessType.INVITE_ONLY,
        { ...topicAccess, isOrgWide: false, isPublic: false }
      );
    },
    [qaController, conversationId, topicAccess]
  );

  const addMember = useCallback(
    async (icon: string, email: string, name?: string) => {
      setMembers((prev) =>
        uniqBy(
          [
            {
              icon,
              email,
              display_name: name ?? email,
              role: ConversationMemberRole.EDITOR,
              conversation_id: conversationId,
            },
            ...prev,
          ],
          'email'
        )
      );

      setShowMemberSuggestions(false);

      qaController.updateConversationDetails(conversationId, {
        topicAccess: {
          ...topicAccess,
          isMultiplayer: true,
        },
      });

      try {
        await invokeFastApi({
          path: '/topics/topic/member',
          method: 'POST',
          body: {
            conversation_id: conversationId,
            user_email: email,
          },
        });
      } catch {
        toaster.failure(`Failed to add member: ${email}`);
        setMembers((prev) => prev.filter((member) => member.email !== email));
      }
    },
    [conversationId, qaController, topicAccess, toaster]
  );

  const onSetShowMembersSuggestionsChange = useCallback(
    (show: boolean) => {
      if (loading) {
        setShowMemberSuggestions(false);
        return;
      }

      setShowMemberSuggestions(show);
    },
    [loading]
  );

  useEffect(() => {
    loadMembers();
  }, [loadMembers]);

  return createPortal(
    <Modal modalClasses="!w-[520px]" onClose={onClose}>
      <div>
        <h2 className="text-xl font-bold m-0">
          Share {topicTitle ? <>"{topicTitle}"</> : 'this topic'}
        </h2>
        <div className="mt-4">
          <MembersSelect
            conversationId={conversationId}
            memberEmailsToSkip={memberEmailsToSkip}
            onMemberSelect={addMember}
            setShowMemberSuggestions={onSetShowMembersSuggestionsChange}
            showMemberSuggestions={showMemberSuggestions}
          />
        </div>
        <div>
          <div className="flex justify-between items-center">
            <h3 className="font-semibold mt-4 mb-4">Members with access</h3>

            {allMembers.length > 0 && loading && (
              <UIIcon name="spinner-animated" size={16} />
            )}
          </div>
          {allMembers.length === 0 && loading && <Loading />}
          <div className="overflow-y-auto max-h-[200px]">
            <MembersList members={allMembers} topicAuthor={topicAuthor} />
          </div>
        </div>
        <h3 className="font-semibold mt-2 mb-2">General Access</h3>
        <div className="flex justify-between w-full items-center">
          <div className="flex items-center">
            <div className="rounded-[50%] w-8 h-8  min-w-[32px] min-h-[32px] bg-gray-20 overflow-hidden relative">
              <UIIcon
                className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                name={generalAccessDetails.icon}
                size={20}
              />
            </div>
            <div>
              <UISelect
                // dropdownWidth={120}
                disableSelectionState
                items={generalAccessOptions}
                onSelect={(v) => {
                  updateTopicAccess(
                    v.value as 'invite-only' | 'public' | 'workspace'
                  );

                  trackEvent(AnalyticsEvent.UpdatedTopicVisibility, {
                    type: v.value,
                    topicId: conversationId,
                  });
                }}
                selectedValue={
                  topicAccess.isPublic
                    ? 'public'
                    : // eslint-disable-next-line unicorn/no-nested-ternary
                    topicAccess.isOrgWide
                    ? 'workspace'
                    : 'invite-only'
                }
                selectorButtonClassName="title"
                // selectedValue={member.admin ? 'admin' : 'member'}
              />
              <div className="text-sm text-gray-50 ml-[11px]">
                {generalAccessDetails.description}
              </div>
            </div>
          </div>
          {(topicAccess.isOrgWide || topicAccess.isPublic) && (
            <div>Can view</div>
          )}
        </div>

        <footer className="flex justify-between mt-6 ">
          <UIButton onClick={copyTopicLink} type="secondary">
            <UIIcon className="mr-2" name="link" />
            Copy link
          </UIButton>
          <UIButton onClick={onClose}>Done</UIButton>
        </footer>
      </div>
    </Modal>,
    document.body
  );
};

const MembersList = ({
  members,
  topicAuthor,
}: {
  members: CreatorDetails[];
  topicAuthor?: TopicAuthor;
}): JSX.Element => {
  return (
    <div>
      {members.map((member) => (
        <div
          className={`${commonCardClassNames} justify-between`}
          key={member.email}
        >
          <div className="flex gap-2 items-center mb-0">
            <div className="flex">
              <UserProfileIcon
                email={member.email}
                icon={member.icon}
                name={member.display_name}
              />
            </div>
            <div>{member.display_name}</div>
          </div>
          {topicAuthor?.email === member.email ? 'Owner' : 'Can edit'}
        </div>
      ))}
    </div>
  );
};

interface MembersSelectProps {
  onMemberSelect: (icon: string, email: string, name?: string) => void;
  showMemberSuggestions: boolean;
  setShowMemberSuggestions: (show: boolean) => void;
  memberEmailsToSkip: string[];
  conversationId: string;
}

export const MembersSelect = ({
  onMemberSelect,
  showMemberSuggestions,
  setShowMemberSuggestions,
  memberEmailsToSkip,
  conversationId,
}: MembersSelectProps): JSX.Element => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [query, setQuery] = useState('');
  const { members, fetchMembersByQuery } = useMembers();

  const memberSuggestions = useMemo(() => {
    return members
      .filter((member) => !memberEmailsToSkip.includes(member.email))
      .slice(0, 5);
  }, [members, memberEmailsToSkip]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetchMembers = useCallback(
    debounce((value: string) => {
      fetchMembersByQuery(value);
    }, 200),
    []
  );

  const handleSearch = useCallback(
    (value: string) => {
      setQuery(value);
      debouncedFetchMembers(value);
    },
    [debouncedFetchMembers]
  );

  useEffect(() => {
    setShowMemberSuggestions(memberSuggestions.length > 0 && query.length > 0);
  }, [memberSuggestions, query, setShowMemberSuggestions]);

  return (
    <div className="relative">
      <UITextbox
        autoFocus
        onChange={handleSearch}
        onClick={() => {
          trackEvent(AnalyticsEvent.ClickedAddMemberToTopic, {
            topicId: conversationId,
          });
        }}
        placeholder="Search for members in the workspace"
        ref={inputRef}
        value={query}
      />
      <div className="absolute top-full left-0 w-full z-[201]">
        {showMemberSuggestions && (
          <div className="w-full rounded-md border border-solid border-cloud-15 bg-popover p-2 text-popover-foreground shadow-lg outline-none">
            {memberSuggestions.map(({ icon, name, email }) => {
              return (
                <div
                  className="flex items-center gap-2 p-2 cursor-pointer hover:bg-cloud-10"
                  key={email}
                  onClick={() => {
                    onMemberSelect(icon, email, name);
                    setQuery('');
                    trackEvent(AnalyticsEvent.AddedMemberToTopic, {
                      topicId: conversationId,
                      user_email: email,
                    });
                  }}
                >
                  <UserImage displayName={name} imageUrl={icon} size={20} />
                  <div>{name}</div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};
