import { BotMinimal } from '../../models/Bots';
import { Action } from '../actions';
import { GlobalState } from '../state';
import { BotsMinimalAction } from './actions';

export const botsMinimalReducer = (
  state: GlobalState,
  action: Action
): GlobalState => {
  const botsMinimalState = { ...state.botsMinimal };

  switch (action.type) {
    case BotsMinimalAction.POPULATE_BOTS: {
      const newBots = action.payload as BotMinimal[];
      botsMinimalState.bots = newBots;

      break;
    }

    case BotsMinimalAction.ADD_SEARCH_QUERY: {
      const addedQuery = action.payload as string;

      if (!botsMinimalState.searchedQueries.includes(addedQuery)) {
        botsMinimalState.searchedQueries = [
          ...botsMinimalState.searchedQueries,
          addedQuery,
        ];
      }

      break;
    }
  }

  return {
    ...state,
    botsMinimal: botsMinimalState,
  };
};
