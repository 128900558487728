import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { SidebarRoutes } from '../../../containers/SidebarContainer';
import { useBoolState, useFlag, useUserSafe } from '../../../scripts/hooks';
import { InstallChromeExtensionDialog } from '../../controls/InstallExtensionDialog/chrome/InstallExtensionDialog';
import { HoverEffectButton } from '../../controls/buttons/HoverEffectButton/HoverEffectButton';
import { UIIcon } from '../../controls/ui/UIIcon/UIIcon';
import { SlackConnectButton } from './SlackConnectButton';

export const QAWorkflows: FC = () => {
  const history = useHistory();
  const isAdmin = useUserSafe((user) => user.admin);
  const apiKeysEnabled = useFlag('apiKeysEnabled');
  const [
    openInstallChromeExtensionDialog,
    setOpenInstallChromeExtensionDialog,
    setNotOpenInstallChromeExtensionDialog,
  ] = useBoolState(false);

  return (
    <div className="flex flex-col gap-2">
      <div className="flex gap-1">
        <div className="flex items-center">
          <UIIcon name="bolt" size={20} />
        </div>
        <div className="font-medium text-lg">
          Bring Dashworks into your existing workflows!
        </div>
      </div>
      <div className="recommendedAppsContainer">
        <div className="recommendedAppsRow rounded bg-cloud-10">
          <div className="flex items-center gap-2">
            <UIIcon name="slack" size={24} type="apps" />
            <span className="text-sm font-medium">Ask in Slack DM</span>
          </div>
          <SlackConnectButton />
        </div>
        <div className="recommendedAppsRow rounded bg-cloud-10">
          <div className="flex items-center gap-2">
            <UIIcon name="slack" size={24} type="apps" />
            <span className="text-sm font-medium">
              Add Slackbot to channels
            </span>
          </div>
          <HoverEffectButton
            onClick={() => {
              window.open(
                'https://support.dashworks.ai/surfaces/slackbot#id-1.-slackbot-in-channels',
                '_blank'
              );
            }}
            text="Learn"
          />
        </div>
        <div className="recommendedAppsRow rounded bg-cloud-10">
          <div className="flex items-center gap-2">
            <UIIcon name="chrome" size={24} type="apps" />
            <span className="text-sm font-medium">Access from homepage</span>
          </div>
          <HoverEffectButton
            onClick={() => {
              setOpenInstallChromeExtensionDialog();
            }}
            text="Install"
          />
        </div>
        {apiKeysEnabled && isAdmin && (
          <div className="recommendedAppsRow rounded bg-cloud-10">
            <div className="flex items-center gap-2">
              <UIIcon name="key" size={24} />
              <span className="text-sm font-medium">Embed anywhere</span>
            </div>
            <HoverEffectButton
              onClick={() => {
                history.push(SidebarRoutes.APIKeys);
              }}
              text="Create"
            />
          </div>
        )}
      </div>
      <InstallChromeExtensionDialog
        onClose={setNotOpenInstallChromeExtensionDialog}
        open={openInstallChromeExtensionDialog}
      />
    </div>
  );
};
