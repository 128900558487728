import './PageSearchContainer.scss';
import classNames from 'classnames';
import moment from 'moment';
import React, {
  FC,
  lazy,
  Suspense,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Redirect } from 'react-router-dom';
import { v1 } from 'uuid';
import { PaywallModal } from '../components/admin/EnterprisePaywall/EnterprisePaywall';
import { SupportChatBotBubble } from '../components/controls/SupportChatBotBubble/SupportChatBotBubble';
import { QAFileDragNDrop } from '../components/jit-qa/files/QAFileDragNDrop';
import { Homepage } from '../components/pageSearch/home/Homepage/Homepage';
import { LazyQAWorkflowTemplateModal } from '../components/workflowModal';
import { trackEvent } from '../extra/sharedMethods';
import { setPreview } from '../redux/pageSearch/actions';
import { Sorting, SortOrder, SortProperty } from '../redux/state';
import { batch, useDispatch } from '../redux/store';
import { AnalyticsEvent } from '../scripts/constants/analytics-event';
import { FilterDefaults, ObjectType, Sort } from '../scripts/constants/filters';
import {
  AllSearchRelatedParams,
  QueryParams,
  useAllFlags,
  useAllSearchRelatedParams,
  useBoolState,
  useGlobalState,
  useLoginTokens,
  useOmniboxParam,
  usePageSearch,
  useUserSafe,
} from '../scripts/hooks';
import { useFeedback } from '../scripts/hooks/feedback';
import { pushSearchHistory } from '../scripts/page-search/search-history';
import { isEqual, logError } from '../scripts/utils';
import { CloudIndex } from '../search/CloudIndex';
import { CloudIndexProvider, useCloudIndex } from '../search/CloudIndexContext';
import { SidebarRoutes } from './SidebarContainer';

const ResultsContainer = lazy(async () =>
  import(
    '../components/pageSearch/results/ResultsContainer/ResultsContainer'
  ).then((module) => ({
    default: module.ResultsContainer,
  }))
);

const FilterBar = lazy(async () =>
  import('../components/pageSearch/top/FilterBar/FilterBar').then((module) => ({
    default: module.FilterBar,
  }))
);

const SearchInput = lazy(async () =>
  import('../components/pageSearch/top/SearchInput/SearchInput').then(
    (module) => ({
      default: module.SearchInput,
    })
  )
);

const TopHeader = lazy(async () =>
  import('../components/pageSearch/top/TopHeader/TopHeader').then((module) => ({
    default: module.TopHeader,
  }))
);

const modifiedOptions = (modified: string): [] | [string, string] => {
  // Modified
  if (modified && modified !== FilterDefaults.Modified) {
    const now = moment().toISOString();

    switch (modified) {
      case 'day':
        return [moment().subtract(24, 'hours').toISOString(), now];
      case 'week':
        return [moment().subtract(7, 'day').toISOString(), now];
      case 'month':
        return [moment().subtract(30, 'day').toISOString(), now];
      case 'year':
        return [moment().subtract(365, 'day').toISOString(), now];
      default:
      // Not recognized
    }
  }

  return [];
};

const sortOptions = (sort: string): Sorting => {
  // Sorting
  let sortValue = SortProperty.Relevance;
  let orderValue = SortOrder.Descending;

  if (sort && sort !== SortProperty.Relevance) {
    switch (sort) {
      case Sort.ModifiedNewest: {
        sortValue = SortProperty.Modified;
        orderValue = SortOrder.Descending;
        break;
      }

      case Sort.ModifiedOldest: {
        sortValue = SortProperty.Modified;
        orderValue = SortOrder.Ascending;
        break;
      }

      case Sort.CreatedNewest: {
        sortValue = SortProperty.Created;
        orderValue = SortOrder.Descending;
        break;
      }

      case Sort.CreatedOldest: {
        sortValue = SortProperty.Created;
        orderValue = SortOrder.Ascending;
        break;
      }

      default: {
        // Not recognized
      }
    }
  }

  return { sort: sortValue, order: orderValue };
};

// Scroll past this Y position to show the feedback widget
const feedbackScrollYTrigger = 900;

const unversionedFilters = new Set([ObjectType.All]);

/**
 * Picks all feature flags prefixed `search` and strips the `search` prefix of the keys.
 */
const convertFlagsToSearchParameters = (
  flags: Record<string, unknown>
): Record<string, object> => {
  const out: Record<string, object> = {};
  for (const [k, v] of Object.entries(flags)) {
    if (
      k.startsWith('search') &&
      (typeof v === 'boolean' || typeof v === 'object') &&
      !!v
    ) {
      if (typeof v === 'boolean') {
        out[k.slice(6, 7).toLowerCase() + k.slice(7)] = {
          enabled: true,
        };

        continue;
      }

      out[k.slice(6, 7).toLowerCase() + k.slice(7)] = v;
    }
  }

  return out;
};

export const CloudIndexWrapper: FC = ({ children }) => {
  const { aclKey } = useUserSafe();
  const idToken = useLoginTokens();
  const dispatch = useDispatch();

  const flags = useAllFlags();

  const searchFlags = useMemo(
    () => convertFlagsToSearchParameters(flags),
    [flags]
  );

  const cloudIndex = useRef<CloudIndex>();

  if (!idToken) {
    return null;
  }

  const newIndex = CloudIndex.update(
    {
      parameters: searchFlags,
      aclKey,
      idToken,
      dispatcher: dispatch,
    },
    cloudIndex.current
  );

  return <CloudIndexProvider value={newIndex}>{children}</CloudIndexProvider>;
};

/**
 * The container for our new search experience.
 */
// eslint-disable-next-line max-lines-per-function
export const PageSearchContainer: React.FC = () => {
  const dispatch = useDispatch();
  const [omniboxParam] = useOmniboxParam();
  const featureFlagsFullyLoaded = useGlobalState(
    (s) => s.featureFlagsFullyLoaded
  );

  const cloudIndex = useCloudIndex();

  const [searchState] = useAllSearchRelatedParams();
  const version = usePageSearch((pageSearch) => pageSearch.version);

  // Used for change detection
  const [searchStateHolder, setSearchStateHolder] = useState<
    AllSearchRelatedParams | undefined
  >();

  const [openPaywallModal, setOpenPaywallModal, setNotOpenPaywallModal] =
    useBoolState(false);

  const feedback = useFeedback();
  const searchRef = useRef<HTMLTextAreaElement | null>(null);

  useEffect(() => {
    const onWindowScroll = () => {
      if (window.scrollY > feedbackScrollYTrigger) {
        feedback.show();
      }
    };

    window.addEventListener('scroll', onWindowScroll, {
      passive: true,
    });

    return () => {
      window.removeEventListener('scroll', onWindowScroll);
    };
  }, [feedback]);

  // Trigger search on load and every search query change

  useEffect(() => {
    if (!featureFlagsFullyLoaded) {
      return;
    }

    const { app, person, modified, filetype, filter, sort, q } = searchState;

    if (
      !isEqual(searchState, searchStateHolder) ||
      searchStateHolder?.semanticToggleState
    ) {
      setSearchStateHolder(searchState);

      const filters: Record<string, string[] | undefined> = {
        source: app && app !== FilterDefaults.Apps ? [app] : [],
        person_filter: person === FilterDefaults.Person ? [] : [person],
        modified: modifiedOptions(modified),
        // eslint-disable-next-line @cspell/spellchecker
        filefilter: filter === ObjectType.Files ? [filetype] : undefined,
      };

      filters.object_type = [
        `${filter}${unversionedFilters.has(filter as ObjectType) ? '' : '_v2'}`,
      ];

      if (!q) {
        return;
      }

      const queryId = v1();

      trackEvent(AnalyticsEvent.SearchQuery, {
        ...searchState,
        query: q,
        omnibox: omniboxParam,
        version,
        queryId,
      });

      cloudIndex
        .search({
          query: q,
          filters,
          sortOrder: sortOptions(sort),
          queryId,
        })
        .then(() => {
          trackEvent(AnalyticsEvent.SearchQueryLoaded, {
            queryId,
          });
        })
        .catch(logError);

      searchRef.current?.blur();
      pushSearchHistory(searchState);
    }

    batch(() => {
      dispatch(setPreview());
    });

    // Hide feedback widget for next search
    feedback.reset();
  }, [
    searchState,
    cloudIndex,
    searchStateHolder,
    dispatch,
    feedback,
    omniboxParam,
    version,
    featureFlagsFullyLoaded,
  ]);

  useEffect(() => {
    // Always scroll to top on query param change
    window.scrollTo(0, 0);
  }, [searchState]);

  // Trigger search when params change

  // Only show homepage if first search and no params set
  const showHomepage = searchState.q.trim().length === 0;

  const searchContainer = useMemo(() => {
    if (showHomepage) {
      return null;
    }

    return (
      <div className="searchInputContainer">
        <SearchInput ref={searchRef} />
      </div>
    );
  }, [showHomepage]);

  const filterBar = useMemo(() => {
    if (showHomepage) {
      return null;
    }

    return <FilterBar />;
  }, [showHomepage]);

  return (
    <div
      className={classNames('pageSearchContainer', {
        resultsView: !showHomepage,
      })}
    >
      {!showHomepage && (
        <Redirect
          to={`${SidebarRoutes.JitQA}?${
            QueryParams.Search
          }=${encodeURIComponent(searchState.q)}&${QueryParams.Omnibox}=${
            omniboxParam ? '1' : '0'
          }`}
        />
      )}
      <div className="mainSearch">
        {showHomepage ? (
          <QAFileDragNDrop>
            <Homepage setOpenPaywallModal={setOpenPaywallModal} />
          </QAFileDragNDrop>
        ) : (
          <Suspense fallback={null}>
            <TopHeader
              filterBar={filterBar}
              hideBorder={showHomepage}
              onHomepage={showHomepage}
              search={searchContainer}
            />
            <ResultsContainer />
          </Suspense>
        )}
        <SupportChatBotBubble />
      </div>
      <LazyQAWorkflowTemplateModal setOpenPaywallModal={setOpenPaywallModal} />
      {openPaywallModal && (
        <PaywallModal allowUpgrade closeModal={setNotOpenPaywallModal} />
      )}
    </div>
  );
};
