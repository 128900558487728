import React, { FC } from 'react';
import { UIButton } from '../../components/controls/ui/UIButton/UIButton';
import { InstallDialogProps } from '../../scripts/app';
import { ConnectStep } from '../generic/Components';
import { SimpleAPIKeyInstall } from '../generic/SimpleAPIKeyInstall';

export const AppInstallModal: FC<InstallDialogProps> = ({
  app,
  isOrg,
  onClose,
  open,
}) => {
  return (
    <SimpleAPIKeyInstall
      app={app}
      instructionSteps={
        <ConnectStep step={1} title="Generate API key">
          <p>
            Sign in with {app.definition.displayName} and generate user token
            for the account you want to connect. For non-admin users, ask your
            workspace admin to generate one on your behalf.
          </p>
          <div className="actionContainer">
            <UIButton href="https://dashboard.stripe.com/login?redirect=/apikeys">
              Sign in with {app.definition.displayName}
            </UIButton>
          </div>
        </ConnectStep>
      }
      isOrg={isOrg}
      onClose={onClose}
      open={open}
      stepCount={1}
    />
  );
};
