import React from 'react';
import { useBoolState } from '../../../../scripts/hooks';
import { UIButton } from '../../ui/UIButton/UIButton';

export const HoverEffectButton: React.FC<{
  onClick: () => void;
  text: string;
}> = ({ onClick, text }) => {
  const [hovered, setHovered, unsetHovered] = useBoolState(false);
  return (
    <div onMouseEnter={setHovered} onMouseLeave={unsetHovered}>
      <UIButton
        className="connectButton"
        onClick={onClick}
        size="small"
        type={hovered ? 'primary' : 'secondary'}
      >
        {text}
      </UIButton>
    </div>
  );
};
