import { trackEvent } from '../../extra/sharedMethods';
import { AnalyticsEvent } from '../constants/analytics-event';
import { logError } from '../utils';
import { invokeFastApi } from './fastapi';

export const onSetFigmaToken = async (
  event: MessageEvent<{
    action: string;
    payload: { token: string };
  }>
): Promise<void> => {
  if (event.data.action !== 'dash-set-figma-token') {
    return;
  }

  const { token } = event.data.payload;

  if (!token) {
    trackEvent(AnalyticsEvent.FigmaTokenNotFoundAfterInstall);
    return;
  }

  const path = '/figma/setCookie';
  try {
    await invokeFastApi({
      path,
      method: 'POST',
      body: { token },
      shouldRetry: true,
    });
  } catch (error) {
    logError(error);
  }

  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  window.removeEventListener('message', onSetFigmaToken);
};
