import classNames from 'classnames';
type ClassValue =
  | ClassValue[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  | Record<string, any>
  | number
  | string
  | null
  | undefined;

export function cn(...inputs: ClassValue[]): string {
  return classNames(inputs);
}
