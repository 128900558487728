import React, { FC, useCallback, useMemo, useState } from 'react';
import {
  AppConnectionType,
  FINCH_APPS,
  GOOGLE_APPS,
} from '../../../../apps/definition';
import { trackEvent } from '../../../../extra/sharedMethods';
import { Feature, useFeatureAccess } from '../../../../hooks/subscriptionHooks';
import { App } from '../../../../scripts/app';
import { AnalyticsEvent } from '../../../../scripts/constants/analytics-event';
import { useUserSafe } from '../../../../scripts/hooks';
import { UIButton } from '../../../controls/ui/UIButton/UIButton';
import { UIIcon } from '../../../controls/ui/UIIcon/UIIcon';
import { UITooltip } from '../../../controls/ui/UIToolTip/UIToolTip';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../../../shadcn/lib/components/dropdownMenu';
import { useAppInstallController } from '../../AppsTable/AppInstallController';
import {
  EnterpriseIcon,
  PaywallModal,
} from '../../EnterprisePaywall/EnterprisePaywall';

// eslint-disable-next-line max-lines-per-function
export const ConnectButton: FC<{
  app: App;
  connectionsLength?: number;
  size?: 'large' | 'long' | 'normal' | 'small' | 'tiny' | 'xLarge';
  // eslint-disable-next-line max-lines-per-function
}> = ({ app, size, connectionsLength = 0 }) => {
  const appInstallController = useAppInstallController();
  const [connectionPopupOpen, setConnectionPopupOpen] = useState(false);
  const [paywallModalOpen, setPaywallModalOpen] = useState(false);
  const { connectionType, shortname } = app.definition;
  const {
    admin,
    orgByOrgId: { usersAppsByOrg },
    usersApps,
  } = useUserSafe();

  const hasOrgWideAppsFeature = useFeatureAccess(Feature.ORG_WIDE_APPS);
  const hasMultipleAppAccountsFeature = useFeatureAccess(
    Feature.MULTIPLE_APP_ACCOUNTS
  );

  const hasHRISFeature = useFeatureAccess(Feature.HRIS);
  const hasAPIAccessFeature = useFeatureAccess(Feature.API_ACCESS);

  const canConnectOrg = useMemo(() => {
    if (connectionType === AppConnectionType.PersonalOnly) {
      return false;
    }

    if (!admin) {
      return false;
    }

    // Apps that can only have one connection
    if (
      GOOGLE_APPS.has(shortname) &&
      usersAppsByOrg.nodes.some(({ appName }) => GOOGLE_APPS.has(appName))
    ) {
      return false;
    }

    if (!hasOrgWideAppsFeature) {
      return !usersAppsByOrg.nodes.some(({ appName }) => appName === shortname);
    }

    return true;
  }, [
    shortname,
    connectionType,
    admin,
    usersAppsByOrg.nodes,
    hasOrgWideAppsFeature,
  ]);

  const canConnectIndividual = useMemo(() => {
    if (
      connectionType === AppConnectionType.PersonalOnly ||
      connectionType === AppConnectionType.AccessControl
    ) {
      if (!hasMultipleAppAccountsFeature) {
        return !usersApps.nodes.some(
          ({ appName, org }) => appName === shortname && org === ''
        );
      }

      return true;
    }

    return false;
  }, [
    shortname,
    connectionType,
    hasMultipleAppAccountsFeature,
    usersApps.nodes,
  ]);

  const isButtonEnabled = useMemo(
    () =>
      connectionType === AppConnectionType.PersonalOnly ||
      connectionType === AppConnectionType.AccessControl ||
      canConnectOrg,
    [canConnectOrg, connectionType]
  );

  const isPaywalled = useMemo(() => {
    if (FINCH_APPS.has(shortname)) {
      return !hasHRISFeature;
    }

    if (shortname === 'custom-api') {
      return !hasAPIAccessFeature;
    }

    if (hasMultipleAppAccountsFeature && hasOrgWideAppsFeature) {
      return false;
    }

    return !canConnectOrg && !canConnectIndividual;
  }, [
    hasHRISFeature,
    hasAPIAccessFeature,
    hasMultipleAppAccountsFeature,
    hasOrgWideAppsFeature,
    shortname,
    canConnectOrg,
    canConnectIndividual,
  ]);

  const orgTooltipText: string = useMemo(() => {
    if (connectionType === AppConnectionType.PersonalOnly) {
      return 'No org-wide connection available for this app';
    }

    if (!hasOrgWideAppsFeature) {
      return 'Upgrade to enable org-wide app connections';
    }

    return 'Only 1 Org connection allowed for this app';
  }, [connectionType, hasOrgWideAppsFeature]);

  const orgDropdownCopy = useMemo(() => {
    if (shortname === 'gitlab') {
      return 'Org connection (Self-Managed)';
    }

    return 'Org connection';
  }, [shortname]);

  const individualDropdownCopy = useMemo(() => {
    if (shortname === 'gitlab') {
      return 'Individual connection (GitLab.com)';
    }

    return 'Individual connection';
  }, [shortname]);

  const installCallback = useCallback(
    ({ isOrg = false }: { isOrg: boolean }) => {
      appInstallController.triggerAppInstallRequest(app, isOrg);
      trackEvent(AnalyticsEvent.AppConnect, {
        app: shortname,
        isOrg,
      });
    },
    [app, shortname, appInstallController]
  );

  const handleConnectButtonClick = useCallback(() => {
    if (isPaywalled) {
      setPaywallModalOpen(true);
      return;
    }

    if (admin) {
      setConnectionPopupOpen((prev) => !prev);
    } else {
      installCallback({ isOrg: false });
    }
  }, [admin, installCallback, isPaywalled]);

  // Check if an other finch app is connected if so we don't allow to connect
  if (
    FINCH_APPS.has(shortname) &&
    usersAppsByOrg.nodes.some(({ appName }) => FINCH_APPS.has(appName))
  ) {
    return (
      <ConnectionNotAvailable tooltipText="Only one HRIS app is able to be connected at a time. Please disconnect the connected HRIS app in order to connect this app." />
    );
  }

  // Apps that only allow one individual connection
  const individualApps = [
    'notion',
    'figma',
    'gong',
    'monday',
    'highspot',
    'freshdesk',
    'freshservice',
  ];

  if (
    individualApps.includes(shortname) &&
    usersApps.nodes.some(({ appName }) => appName === shortname)
  ) {
    return (
      <ConnectionNotAvailable
        tooltipText={`Only one ${
          shortname.charAt(0).toUpperCase() + shortname.slice(1)
        } connection is allowed at a time.`}
      />
    );
  }

  // Apps that only allow one org-wide connection
  const orgApps = ['outlook', 'onedrive'];
  if (
    orgApps.includes(shortname) &&
    usersAppsByOrg.nodes.some(({ appName }) => appName === shortname)
  ) {
    return (
      <ConnectionNotAvailable
        tooltipText={`Only one ${
          shortname.charAt(0).toUpperCase() + shortname.slice(1)
        } connection is allowed at a time.`}
      />
    );
  }

  return (
    <DropdownMenu
      onOpenChange={setConnectionPopupOpen}
      open={connectionPopupOpen}
    >
      <DropdownMenuTrigger asChild>
        <UIButton
          className="connectButtonAppcuesClass w-[130px]"
          disabled={!isButtonEnabled}
          disabledReason={
            isButtonEnabled ? undefined : 'You are unable to add this app.'
          }
          onClick={handleConnectButtonClick}
          size={size}
          type="secondary"
        >
          {isPaywalled && <EnterpriseIcon size={8} />}
          {connectionsLength > 0 ? 'Add' : 'Connect'}
        </UIButton>
      </DropdownMenuTrigger>
      {paywallModalOpen && (
        <PaywallModal
          allowUpgrade
          closeModal={() => {
            setPaywallModalOpen(false);
          }}
          modalClasses="whitespace-normal"
        />
      )}

      <DropdownMenuContent className="font-medium">
        <UITooltip disableHover={canConnectOrg} title={orgTooltipText}>
          <DropdownMenuItem
            className={`${
              canConnectOrg && hasOrgWideAppsFeature
                ? ''
                : 'opacity-50 focus:bg-white'
            }`}
            onClick={() => {
              if (!canConnectOrg) return;
              if (!hasOrgWideAppsFeature) {
                setPaywallModalOpen(true);
                return;
              }

              installCallback({ isOrg: true });
            }}
          >
            <div className="flex gap-2 items-center">
              {canConnectOrg && !hasOrgWideAppsFeature ? (
                <EnterpriseIcon />
              ) : (
                <UIIcon name="company" />
              )}
              <span>{orgDropdownCopy}</span>
            </div>
          </DropdownMenuItem>
        </UITooltip>
        <UITooltip
          disableHover={canConnectIndividual}
          placement="bottom"
          title="No Individual connections allowed for this app"
        >
          <DropdownMenuItem
            className={`flex gap-2 items-center ${
              canConnectIndividual ? '' : 'opacity-40 focus:bg-white'
            }`}
            onClick={() => {
              if (!canConnectIndividual) return;
              installCallback({ isOrg: false });
            }}
          >
            <UIIcon name="person" />
            <span>{individualDropdownCopy}</span>
          </DropdownMenuItem>
        </UITooltip>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

const ConnectionNotAvailable = ({ tooltipText }: { tooltipText?: string }) => (
  <UIButton
    className="w-[130px]"
    disabled
    disabledReason={tooltipText ?? 'You are unable to add this app.'}
    type="secondary"
  >
    Connect
  </UIButton>
);
