import * as Sentry from '@sentry/browser';
import { Method } from 'axios';
import { store } from '../../redux/store';
import { deriveErrorFromFailedRequest, RequestError } from './request';

interface RequestParams {
  path: string;
  method?: Method;
  body?: unknown;
  queryParams?: Record<string, number | string | null | undefined>;
  retryCount?: number;
  shouldRetry?: boolean;
}

const MAX_RETRIES = 1;

export async function invokeFastApi<T>({
  path,
  method = 'GET',
  body,
  queryParams = {},
  retryCount = 0,
  shouldRetry = false,
}: RequestParams): Promise<T> {
  const { tokens } = store.getState();
  const id_token = tokens?.loginTokens?.id_token;
  const filterQueryParams = Object.fromEntries(
    Object.entries(queryParams).filter(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      ([_, v]) => v !== null && v !== undefined
    )
  ) as Record<string, string>;

  const queryParamsString = new URLSearchParams(filterQueryParams).toString();

  const url =
    queryParamsString === ''
      ? `${SEARCH_URL}${path}`
      : `${SEARCH_URL}${path}?${queryParamsString}`;

  let response: Response;
  try {
    response = await fetch(url, {
      headers: {
        ...(id_token ? { authorization: `Bearer ${id_token}` } : undefined),
        ...(method === 'GET'
          ? undefined
          : { 'content-type': 'application/json' }),
      },
      body: method === 'GET' ? undefined : JSON.stringify(body),
      method,
    });
  } catch (error) {
    const requestError = new RequestError(
      path,
      method,
      undefined,
      undefined,
      error as Error
    );

    Sentry.captureException(requestError);

    if (shouldRetry && retryCount < MAX_RETRIES) {
      return invokeFastApi({
        path,
        method,
        body,
        queryParams,
        retryCount: retryCount + 1,
        shouldRetry,
      });
    }

    throw requestError;
  }

  if (response.status >= 200 && response.status < 300) {
    const contentType = response.headers.get('content-type');
    if (contentType?.includes('application/json')) {
      return (await response.json()) as T;
    }

    return response as T;
  }

  try {
    deriveErrorFromFailedRequest(method, path, response);
  } catch (error) {
    if (error instanceof RequestError) {
      if (error.statusCode !== 404) {
        Sentry.captureException(error);
      }

      if (shouldRetry && retryCount < MAX_RETRIES) {
        return invokeFastApi({
          path,
          method,
          body,
          queryParams,
          retryCount: retryCount + 1,
          shouldRetry,
        });
      }
    }

    throw error;
  }

  throw new RequestError(path, method, response.status, response);
}
