/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import {
  FeedItem,
  FeedMetadata,
  MarkdownContentBlock,
  User,
} from '@knocklabs/client';
import { BotMinimal } from '../../models/Bots';
import { invokeFastApi } from '../../scripts/apis/fastapi';
import {
  EventSource,
  MessageAction,
} from '../../scripts/constants/message-action';
import { logDebug, logError } from '../../scripts/utils';
import { RadioListOption } from '../controls/RadioList/RadioList';

export const NOTIFICATIONS_CHANNEL_ID = 'DW_NOTIFICATION_CHANNEL';

export enum NotificationsChannelMessageType {
  NOTIFICATIONS_READ = 'NOTIFICATIONS_READ',
  NOTIFICATIONS_MARK_ALL_AS_READ = 'NOTIFICATIONS_MARK_ALL_AS_READ',
}

interface NotificationsChannelMessageBase {
  type: NotificationsChannelMessageType;
  source: string;
}

export interface NotificationsChannelReadMessage
  extends NotificationsChannelMessageBase {
  type: NotificationsChannelMessageType.NOTIFICATIONS_READ;
  notificationIds: string[];
  source: string;
}

export interface NotificationsChannelMarkAllAsReadMessage
  extends NotificationsChannelMessageBase {
  type: NotificationsChannelMessageType.NOTIFICATIONS_MARK_ALL_AS_READ;
}

export type NotificationsChannelMessage =
  | NotificationsChannelMarkAllAsReadMessage
  | NotificationsChannelReadMessage;

export enum NotificationsFilter {
  'All' = 'All',
  'Unread' = 'Unread',
  'Read' = 'Read',
}

export const NOTIFICATION_FILTERS_OPTIONS: RadioListOption<string>[] =
  Object.values(NotificationsFilter).map((filterType) => ({
    value: filterType,
    displayName: filterType,
  }));

export enum NotificationType {
  NOTIFY_TAGGED_USERS = 'notify-tagged-users',
  NOTIFY_BOT_ADMINS = 'notify-bot-admins',
  NOTIFY_NEW_MSGS_IN_SHARED_TOPICS = 'notify-new-msgs-in-shared-topics',
  NOTIFY_USER_ADDED_TO_TOPIC = 'notify-user-added-to-topic',
  NOTIFY_RESEARCH = 'notify-research',
}

export interface NotificationsData {
  topic_id: string;
  message_id?: string;
  topic_title?: string;
  managed_bot_details: BotMinimal | null;
  bot_id?: string;
  type?: NotificationType;
  message_slice?: string;
  success?: boolean;
}

export interface NotificationsFeedItem {
  knockItem: FeedItem;
  markdown: string;
  sender: {
    avatar?: string | null;
    name: string;
  };
  createdAt: string;
  read: boolean;
  id: string;
  topicId: string;
  messageId?: string;
  topicTitle: string;
  managedBotDetails: BotMinimal | null;
  notificationType: NotificationType;
  botId?: string;
  notificationMessageText: string;
  messageSlice: string | null;
  success: boolean | null;
}

export interface KnockUser {
  id: string;
  name?: string;
  email: string;
  avatar?: string;
  // The following things will be "True" or "False" as string because Knock only compares strings
  in_app_tagged_users_disabled?: string;
  in_app_bot_admins_disabled?: string;
  in_app_new_msgs_disabled?: string;
  in_app_user_added_to_topic_disabled?: string;
  in_app_research_disabled?: string;

  email_tagged_users_disabled?: string;
  email_bot_admins_disabled?: string;
  email_new_msgs_disabled?: string;
  email_user_added_to_topic_disabled?: string;
  email_research_disabled?: string;

  desktop_tagged_users_disabled?: string;
  desktop_bot_admins_disabled?: string;
  desktop_new_msgs_disabled?: string;
  desktop_user_added_to_topic_disabled?: string;
  desktop_push_disabled?: string;
  desktop_research_disabled?: string;
}

export const getKnockNotificationsToken = async (): Promise<{
  token: string;
  knock_user: KnockUser;
} | null> => {
  try {
    const res = await invokeFastApi<{ token: string; knock_user: KnockUser }>({
      path: '/notifications/token',
      shouldRetry: true,
    });

    const knockUser = normalizeBackwardCompatibilityForKnockUser(
      res.knock_user
    );

    return { token: res.token, knock_user: knockUser };
  } catch (error) {
    logError(error);
    return null;
  }
};

const normalizeBackwardCompatibilityForKnockUser = (
  knockUser: KnockUser
): KnockUser => {
  const keys: (keyof KnockUser)[] = [
    'in_app_tagged_users_disabled',
    'in_app_bot_admins_disabled',
    'in_app_new_msgs_disabled',
    'in_app_user_added_to_topic_disabled',
    'in_app_research_disabled',

    'email_tagged_users_disabled',
    'email_bot_admins_disabled',
    'email_new_msgs_disabled',
    'email_user_added_to_topic_disabled',
    'email_research_disabled',

    'desktop_tagged_users_disabled',
    'desktop_bot_admins_disabled',
    'desktop_new_msgs_disabled',
    'desktop_push_disabled',
    'desktop_user_added_to_topic_disabled',
    'desktop_research_disabled',
  ];

  for (const key of keys) {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (knockUser[key] === undefined || knockUser[key] === null) {
      knockUser[key] = 'False';
    }
  }

  return knockUser;
};

export const processFeedItem = (feedItem: FeedItem): NotificationsFeedItem => {
  const markDownBlock = feedItem.blocks.find(
    (item) => item.type === 'markdown'
  ) as MarkdownContentBlock | undefined;

  const markdown = markDownBlock?.rendered ?? '';
  const sender = feedItem.actors[0] as User | undefined;
  const data = feedItem.data as NotificationsData;
  const notificationType =
    data.type || (feedItem.source.key as NotificationType);

  const topicTitle = data.topic_title || 'a topic';
  const senderName = sender?.name || 'Your teammate';

  let notificationMessageText = `${senderName} sent a new message in ${topicTitle}`;

  if (notificationType === NotificationType.NOTIFY_TAGGED_USERS) {
    notificationMessageText = `${senderName} tagged you in ${topicTitle}`;
  }

  if (notificationType === NotificationType.NOTIFY_BOT_ADMINS) {
    const botName = data.managed_bot_details
      ? `${data.managed_bot_details.icon} ${data.managed_bot_details.bot_name}`
      : 'a bot managed by you';

    notificationMessageText = `${senderName} tagged ${botName} in ${topicTitle}`;
  }

  if (notificationType === NotificationType.NOTIFY_USER_ADDED_TO_TOPIC) {
    notificationMessageText = `${senderName} added you to ${topicTitle}`;
  }

  if (notificationType === NotificationType.NOTIFY_RESEARCH) {
    notificationMessageText = data.success
      ? `Research on ${topicTitle} is ready.`
      : `Something went wrong — research for ${topicTitle} couldn't be finished.`;
  }

  return {
    id: feedItem.id,
    knockItem: feedItem,
    markdown,
    sender: {
      avatar: sender?.avatar,
      name: senderName,
    },
    read: !!feedItem.read_at,
    topicId: data.topic_id,
    messageId: data.message_id,
    botId: data.bot_id,
    topicTitle,
    managedBotDetails: data.managed_bot_details,
    notificationType,
    createdAt: feedItem.inserted_at,
    notificationMessageText,
    messageSlice: data.message_slice ?? null,
    success: data.success ?? null,
  };
};

export const processFeedItems = (
  feedItems: FeedItem[]
): NotificationsFeedItem[] => {
  return feedItems.map((item) => processFeedItem(item));
};

export const markFeedItemsAsRead = (
  items: FeedItem[]
): NotificationsFeedItem[] => {
  return items.map((item) => {
    const newKnockItem = {
      ...item,
      read_at: new Date().toISOString(),
    };

    return processFeedItem(newKnockItem);
  });
};

export const mergeFeedItems = (
  oldItems: NotificationsFeedItem[],
  newItems: NotificationsFeedItem[]
): NotificationsFeedItem[] => {
  return oldItems
    .map((oldItem) => {
      const newItem = newItems.find((item) => oldItem.id === item.id);
      return newItem ?? oldItem;
    })
    .sort((a, b) =>
      a.knockItem.inserted_at > b.knockItem.inserted_at ? -1 : 1
    );
};

export const updateMetaDataAfterNotificationsRead = (
  metaData: FeedMetadata | null,
  items: FeedItem[]
): FeedMetadata | null => {
  if (!metaData) return null;
  return {
    ...metaData,
    unread_count: Math.max(0, metaData.unread_count - items.length),
  };
};

export function getDisplayableTime(timestamp: string): string {
  const now = new Date();
  const past = new Date(timestamp);
  const secondsAgo = Math.floor((now.getTime() - past.getTime()) / 1000);

  if (secondsAgo < 60) {
    return 'just now';
  }

  const intervals = [
    { label: 'year', seconds: 31_536_000 },
    { label: 'month', seconds: 2_592_000 },
    { label: 'week', seconds: 604_800 },
    { label: 'day', seconds: 86_400 },
    { label: 'hour', seconds: 3600 },
    { label: 'minute', seconds: 60 },
    { label: 'second', seconds: 1 },
  ];

  for (const interval of intervals) {
    const count = Math.floor(secondsAgo / interval.seconds);
    if (count >= 1) {
      return `${count} ${interval.label}${count > 1 ? 's' : ''} ago`;
    }
  }

  return 'just now';
}

function updateFaviconNotificationBadge(notificationsCount: number): void {
  const favicon: HTMLLinkElement | null =
    document.querySelector("link[rel='icon']");

  if (!favicon) {
    logError('Favicon not found');
    return;
  }

  if (notificationsCount === 0) {
    favicon.href = '/favicon.png';
    return;
  }

  favicon.href = '/favicon_with_badge.png';
}

function updateDocumentTitle(notificationsCount: number): void {
  const baseTitle = 'Dashworks';
  if (notificationsCount === 0) {
    document.title = baseTitle;
    return;
  }

  const formattedNotificationsCount =
    notificationsCount > 9 ? '9+' : notificationsCount;

  document.title = `(${formattedNotificationsCount}) ${baseTitle}`;
}

export function handleNotificationsCountChange(
  notificationsCount: number
): void {
  // update document title and favicon
  updateFaviconNotificationBadge(notificationsCount);
  updateDocumentTitle(notificationsCount);

  // post message to parent window for new tab page
  window.parent.postMessage(
    {
      action: MessageAction.UpdateNotificationCount,
      data: {
        notificationsCount,
      },
      source: EventSource.WEB_APP,
    },
    '*'
  );
}

export function sendDesktopNotification(
  knockUser: KnockUser,
  notificationFeedItem: NotificationsFeedItem
): void {
  if (!('Notification' in window)) {
    logDebug('This browser does not support desktop notification');
    return;
  }

  if (!isNotificationSupported()) return;

  if (knockUser.desktop_push_disabled === 'True') {
    return;
  }

  const {
    notificationType,
    id: notificationId,
    notificationMessageText,
    messageSlice,
    topicId,
    messageId,
  } = notificationFeedItem;

  if (
    notificationType === NotificationType.NOTIFY_USER_ADDED_TO_TOPIC &&
    knockUser.desktop_user_added_to_topic_disabled === 'True'
  ) {
    return;
  }

  if (
    notificationType === NotificationType.NOTIFY_TAGGED_USERS &&
    knockUser.desktop_tagged_users_disabled === 'True'
  ) {
    return;
  }

  if (
    notificationType === NotificationType.NOTIFY_BOT_ADMINS &&
    knockUser.desktop_bot_admins_disabled === 'True'
  ) {
    return;
  }

  if (
    notificationType === NotificationType.NOTIFY_NEW_MSGS_IN_SHARED_TOPICS &&
    knockUser.desktop_new_msgs_disabled === 'True'
  ) {
    return;
  }

  if (
    notificationType === NotificationType.NOTIFY_RESEARCH &&
    knockUser.desktop_research_disabled === 'True'
  ) {
    return;
  }

  if (Notification.permission !== 'granted') {
    return;
  }

  const notificationTitle = notificationMessageText;
  let notificationBody = messageSlice;

  if (
    notificationType === NotificationType.NOTIFY_TAGGED_USERS &&
    notificationBody
  ) {
    const customPattern = '@{{.*?}}{{.*?}}';
    const customPatternRegex = new RegExp(`(${customPattern})`, 'g');

    try {
      notificationBody = notificationBody.replace(
        customPatternRegex,
        (match) => {
          const parts = /{{(.*?)}}/.exec(match) ?? [];
          // eslint-disable-next-line prefer-destructuring
          const firstPart = parts[1];
          if (firstPart) return `@${firstPart}`;
          return match;
        }
      );
    } catch (error) {
      logError('Error parsing custom pattern', error);
      return;
    }
  }

  createDesktopNotification(
    notificationTitle,
    notificationId,
    `${window.location.origin}/topic/${topicId}${
      messageId ? `/${messageId}` : ''
    }`,
    notificationBody
  );
}

const createDesktopNotification = (
  title: string,
  id: string,
  actionUrl: string,
  body: string | null
) => {
  setTimeout(() => {
    const options = {
      tag: id,
      renotify: true,
      body: body ?? undefined,
    };

    const notification = new Notification(title, options);

    notification.addEventListener('click', (e) => {
      e.preventDefault();
      window.open(actionUrl, '_blank');
      notification.close();
    });
  }, Math.random() * 1000);
};

export const isNotificationSupported = (): boolean => {
  return 'Notification' in window;
};
