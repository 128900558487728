import classNames from 'classnames';
import React from 'react';
import { InviteMemberItem } from './InviteMemberItem';
import { InviteMember, UpdateMemberRoleFn } from './types';

interface InviteMembersListProps {
  title: string;
  members: InviteMember[];
  onRemove: (email: string) => void;
  updateMemberRole: UpdateMemberRoleFn;
  headingSize: 'lg' | 'sm';
}

export const InviteMembersList: React.FC<InviteMembersListProps> = ({
  title,
  members,
  onRemove,
  updateMemberRole,
  headingSize = 'lg',
}) => {
  if (members.length === 0) {
    return null;
  }

  return (
    <>
      <h3
        className={classNames(
          'mt-0',
          headingSize === 'lg'
            ? 'font-semibold mb-4'
            : 'font-medium text-base mb-2'
        )}
      >
        {title}
      </h3>
      {members.map(({ name, email, icon, admin }) => (
        <InviteMemberItem
          admin={admin}
          email={email}
          icon={icon}
          key={email}
          name={name}
          onRemove={onRemove}
          updateMemberRole={updateMemberRole}
        />
      ))}
    </>
  );
};
