import classNames from 'classnames';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useToaster, useUserSafe } from '../../../scripts/hooks';
import { useMembers } from '../../../scripts/hooks/members';
import { MemberStatus, OrgMember } from '../../../scripts/models/org-member';
import { debounce, isValidEmail } from '../../../scripts/utils';
import { UIIcon } from '../../controls/ui/UIIcon/UIIcon';
import { UITextbox } from '../../controls/ui/UITextbox/UITextbox';
import { MembersSelectList } from './MembersSelectList';
import { InviteMember } from './types';

interface MembersSelectProps {
  onMemberSelect: (member: InviteMember) => void;
  selectedMembers: InviteMember[];
  canShowOptionsInBottom?: boolean;
  placeholder?: string;
  doNotLoadMembers?: boolean;
}

export const MembersSelect = ({
  onMemberSelect,
  selectedMembers,
  canShowOptionsInBottom,
  placeholder = "Enter member's email or name to invite",
  doNotLoadMembers = false,
}: MembersSelectProps): JSX.Element => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [query, setQuery] = useState('');
  const {
    allMembers: members,
    fetchMembersByQuery,
    loading: membersLoading,
  } = useMembers();

  const toaster = useToaster();
  const user = useUserSafe();
  const allowedDomains = user.orgByOrgId.allowedDomains.nodes;

  const memberSuggestions = useMemo(() => {
    return members
      .filter(
        (member) =>
          member.status === MemberStatus.INVITED ||
          member.status === MemberStatus.CREATED
      )
      .filter((member) =>
        selectedMembers.every(
          (selectedMember) => selectedMember.email !== member.email
        )
      )
      .filter(
        (member) =>
          member.email.toLowerCase().includes(query.toLowerCase()) ||
          member.name?.toLowerCase().includes(query.toLowerCase())
      );
  }, [members, selectedMembers, query]);

  const uninvitedMemberSuggestions = useMemo(
    () =>
      memberSuggestions.filter(
        (member) => member.status === MemberStatus.CREATED
      ),
    [memberSuggestions]
  );

  const invitedMemberSuggestions = useMemo(
    () =>
      memberSuggestions.filter(
        (member) => member.status === MemberStatus.INVITED
      ),
    [memberSuggestions]
  );

  const doesMemberFromQueryExist = useMemo(() => {
    return members.some((m) => m.email.toLowerCase() === query.toLowerCase());
  }, [members, query]);

  const hasUninvitedMembers =
    !doesMemberFromQueryExist || uninvitedMemberSuggestions.length > 0;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetchMembers = useCallback(
    debounce((value: string) => {
      fetchMembersByQuery(value, MemberStatus.CREATED);
      fetchMembersByQuery(value, MemberStatus.INVITED);
    }, 200),
    [fetchMembersByQuery]
  );

  const handleSearch = useCallback(
    (value: string) => {
      setQuery(value);
      if (doNotLoadMembers) return;
      debouncedFetchMembers(value);
    },
    [doNotLoadMembers, debouncedFetchMembers]
  );

  const validateEmail = useCallback(
    (email: string) => {
      if (!isValidEmail(email)) {
        toaster.failure('Invalid email address');
        return false;
      }

      const isAllowedDomain = allowedDomains.some((allowedDomain) =>
        email.endsWith(allowedDomain.domain)
      );

      if (!isAllowedDomain) {
        toaster.failure('Domain not allowed');
        return false;
      }

      return true;
    },
    [allowedDomains, toaster]
  );

  const handleInviteNewMember = useCallback(() => {
    if (!validateEmail(query)) return;

    onMemberSelect({
      email: query,
      inviteType: 'new',
      admin: false,
      icon: '',
    });

    setQuery('');
  }, [query, validateEmail, onMemberSelect]);

  const handleSelectMember = useCallback(
    (member: OrgMember, inviteType: 'new' | 'resend') => {
      onMemberSelect({
        icon: member.icon,
        name: member.name,
        email: member.email,
        inviteType,
        admin: member.admin,
      });

      setQuery('');
    },
    [onMemberSelect]
  );

  const handleSelectUninvitedMember = useCallback(
    (member: OrgMember) => {
      handleSelectMember(member, 'new');
    },
    [handleSelectMember]
  );

  const handleSelectInvitedMember = useCallback(
    (member: OrgMember) => {
      handleSelectMember(member, 'resend');
    },
    [handleSelectMember]
  );

  return (
    <div className="relative">
      <UITextbox
        autoFocus
        inputClassName="border !border-solid !border-gray-30 focus:!border-gray-50 rounded-md !px-[17px] !pt-[11px] !pb-[10px]"
        onChange={handleSearch}
        onKeyDown={(e) => {
          if (!doNotLoadMembers) return;
          if (e.key === 'Enter') {
            e.preventDefault();
            handleInviteNewMember();
          }
        }}
        placeholder={placeholder}
        ref={inputRef}
        size="large"
        value={query}
      />
      {query.trim().length > 0 && (
        <div
          className={classNames(
            'absolute left-0 w-full z-[201]',
            canShowOptionsInBottom && window.innerHeight < 1200
              ? 'bottom-full'
              : 'top-full'
          )}
        >
          <div className="w-full rounded-md border border-solid border-cloud-15 bg-popover p-2 text-popover-foreground shadow-lg outline-none max-h-80 overflow-y-auto custom-scrollbar">
            {hasUninvitedMembers && !doNotLoadMembers && (
              <div className="flex justify-between items-center">
                <div>Invite</div>
                {membersLoading && <UIIcon name="spinner-animated" size={16} />}
              </div>
            )}
            {!doesMemberFromQueryExist && (
              <div
                className="cursor-pointer hover:bg-cloud-10 "
                onClick={handleInviteNewMember}
              >
                <button
                  className="flex items-center gap-2 p-2 resetButton"
                  type="button"
                >
                  <div className="w-7 p-1 flex items-center justify-center">
                    <UIIcon name="mail" size={20} />
                  </div>
                  <div className="text-base">{query}</div>
                </button>
              </div>
            )}
            {uninvitedMemberSuggestions.length > 0 && (
              <MembersSelectList
                members={uninvitedMemberSuggestions}
                onMemberSelect={handleSelectUninvitedMember}
              />
            )}
            {invitedMemberSuggestions.length > 0 && (
              <>
                <div className="flex justify-between items-center">
                  <div>Resend invite</div>
                  {!hasUninvitedMembers && membersLoading && (
                    <UIIcon name="spinner-animated" size={16} />
                  )}
                </div>
                <MembersSelectList
                  members={invitedMemberSuggestions}
                  onMemberSelect={handleSelectInvitedMember}
                />
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
