import { makeLazyComponent } from '../../scripts/utils';

export const LazyQAWorkflowTemplateModal = makeLazyComponent(
  async () =>
    (
      await import(
        /* webpackChunkName: "qa-workflow-template-modal" */ './QAWorkflowTemplateModal'
      )
    ).QAWorkflowTemplateModal
);
