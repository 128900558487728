import { AppName } from '../apps/definition';
import { QAFile } from '../components/jit-qa/files/QAFilesContext';
import { QAWebSocketRequestHandler } from '../scripts/apis/qaWebSocketClient';
import { CreatorDetails } from './Bots';

export interface QAStreamMessage<T = unknown> {
  type: QAPacketType;
  data: T;
}

export enum QAPacketType {
  SET_STAGE = 'SET_STAGE',
  CHUNK = 'CHUNK',
  SKILL_RETRIEVED = 'SKILL_RETRIEVED',
  WEB_VIEW_URLS = 'WEB_VIEW_URLS',
  ALL_WEB_VIEW_URLS_SUMMARY = 'ALL_WEB_VIEW_URLS_SUMMARY',
  RELATED_SEARCHES = 'RELATED_SEARCHES',
  TOPIC_TITLE = 'TOPIC_TITLE',
  DEBUG_LOGS = 'DEBUG_LOGS',
  ROW_ID = 'ROW_ID',
  ANSWER_COMPLETE = 'ANSWER_COMPLETE',
}

export enum QAStages {
  WAIT_USER_INPUT = 'WAIT_USER_INPUT',
  SENDING_REQUEST = 'SENDING_REQUEST',
  BUILDING_PROMPT = 'BUILDING_PROMPT',
  GATHERING_DATA = 'GATHERING_DATA',
  STREAMING_ANSWER = 'STREAMING_ANSWER',
  WORKBOOK_EVENT = 'WORKBOOK_EVENT',
  MULTI_STEP_THOUGHT = 'MULTI_STEP_THOUGHT',
  HOP_COMPLETED = 'HOP_COMPLETED',
}

export enum MessageType {
  ANSWER = 'ANSWER',
  STATIC_ANSWER = 'STATIC_ANSWER',
  NAVIGATION_ANSWER = 'NAVIGATION_ANSWER',
}

export enum StaticAnswerType {
  ONBOARDING = 'ONBOARDING',
  STOPPED_GENERATION = 'STOPPED_GENERATION',
}

export interface SetStageMessageData {
  stage: QAStages;
  displayText?: string;
  appIconName?: string;
  iconUrl?: string;
  answerType?: MessageType;
  event?: QAWorkbookEntry;
  progress?: number;
  is_visible?: boolean;
}

export interface UserInfo {
  className?: string;
  email: string;
  name?: string;
  photo?: string;
}

export interface AllReferencesSummary {
  app_references_count?: SkillRetrieved[];
}

export interface Reference {
  id: string;
  author?: UserInfo;
  modified_time?: number;
  object_type?: string;
  url?: string;
  title: string;
  snippet?: string;
  source: string;
  rating?: number;
  extra_fields?: Record<string, never>;
}

export interface QAMessageFeedbackType {
  rating?: number;
  comment?: string;
}

export interface SkillRetrieved {
  appName: AppName | 'files' | 'verified_answers';
  numDocs?: number;
}

export enum MessageStatus {
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  CANCELLED = 'CANCELLED',
}

export interface QAMessage {
  row_id: string;
  message_id: string;
  conversation_id: string;
  bot_id?: string;
  conversation_timestamp: number;
  sender: 'ASSISTANT' | 'USER';
  tsSentAt: number;
  messageText: string;
  messageEncoding: 'MARKDOWN' | 'PLAIN_TEXT';
  messageType?: MessageType;
  extraData?: ChatExtraData;
  progressBar?: number;
  references: Reference[];
  allReferencesSummary: AllReferencesSummary;
  relatedSearches: string[];
  skillRetrieved?: SkillRetrieved;
  isPreFinalGeneration?: boolean;
  topic_title?: string;
  debugLogs?: IntermediateVariables;
  files?: QAFile[];
  author?: CreatorDetails;
  topicAccess?: QATopicAccess;
  bot_name?: string;
  bot_icon?: string;
  deepresearch: boolean;
  status?: MessageStatus;
  isAnswerNotLoadedAndIsNotStreaming?: boolean;
}

export interface QATopicAccess {
  isMultiplayer?: boolean;
  isOrgWide?: boolean;
  isPublic?: boolean;
}

export const DEFAULT_TOPIC_ACCESS: QATopicAccess = {
  isMultiplayer: false,
  isOrgWide: false,
  isPublic: false,
};

export interface QATopic {
  topicId: string;
  topicTitle: string;
  createdAt: number;
  unreadCount?: number;
}

export interface QAWorkbookEntry {
  id: string;
  message_id: string;
  query_row_id: string;
  timestamp: string;
  stage_name: string;
  stage_value: string;
  stage_idx: number;
}

export interface QAWorkbookResponse {
  success: true;
  count: number;
  entries: QAWorkbookEntry[];
}

export interface Conversation {
  conversation_id: string;
  conversation_timestamp: number;
  topicTitle?: string;
  topicAccess: QATopicAccess;
  messages: QAMessage[];
}

export interface ChatExtraData {
  staticAnswerType?: StaticAnswerType;
  messageFeedback?: QAMessageFeedbackType;
}

export enum QATopicAccessType {
  INVITE_ONLY = 'INVITE_ONLY',
  PUBLIC = 'PUBLIC',
  ORG = 'ORG',
}

export enum TopicType {
  MY_TOPICS = 'MY_TOPICS',
  SHARED_WITH_ME = 'SHARED_WITH_ME',
  MANAGED_BOTS = 'MANAGED_BOTS',
}

export interface QAControllerState {
  isFetchingMessages: boolean;
  isNewTopic: boolean;
  hasMoreMessages: boolean;
  allTopicsLoaded: {
    [key: string]: boolean;
    [TopicType.MY_TOPICS]: boolean;
    [TopicType.SHARED_WITH_ME]: boolean;
  };
  topicFilesMap: Record<string, string[]>;
  isDeepResearchFlagEnabled: boolean;
}

export interface QAStreamingAnswerState {
  answerValue?: QAMessage;
  currentStage: QAStages;
  stageTextMessage?: string;
  websocket: QAWebSocketRequestHandler<QAStreamMessage>;
  workbookEntries: QAWorkbookEntry[];
  isCompleted: boolean;
  onStop: () => void;
}

interface LLMInteractions {
  model_name: string;
  prompt: string;
  response: string;
  role?: string;
}

export interface StageVariables {
  step_idx: number;
  stage_name: string;
  inputs: never[] | Record<string, never> | string;
  llm_interactions: LLMInteractions[];
  outputs: never[] | Record<string, never> | string;
  extra: Record<string, never>;
  execution_time?: number;
}

interface IntermediateVariables {
  stages: StageVariables[];
  execution_time?: number;
  doc_data: Record<string, never>;
}

export enum ConversationMemberRole {
  CREATOR = 'CREATOR',
  EDITOR = 'EDITOR',
}

export interface ConversationMember {
  display_name: string;
  email: string;
  icon?: string;
  role: ConversationMemberRole;
  conversation_id: string;
}

export interface TopicAuthor {
  displayName?: string;
  email: string;
  icon?: string;
}
