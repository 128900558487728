import { useCallback, useMemo, useState } from 'react';
import { useToaster } from '../../../scripts/hooks';
import { uniqBy } from '../../../scripts/utils';
import { EmailInviteType, InviteMember, UpdateMemberRoleFn } from './types';
import { sendEmailInvite } from './utils';

export const useInviteMembers = (): {
  selectedMembers: InviteMember[];
  membersToSendNewInvite: InviteMember[];
  membersToResendInvite: InviteMember[];
  selectMember: (member: InviteMember) => void;
  removeMember: (email: string) => void;
  updateMemberRole: UpdateMemberRoleFn;
  inviting: boolean;
  setInviting: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedMembers: React.Dispatch<React.SetStateAction<InviteMember[]>>;
  sendInvites: (showToast: boolean) => Promise<boolean>;
} => {
  const [selectedMembers, setSelectedMembers] = useState<InviteMember[]>([]);
  const [inviting, setInviting] = useState(false);
  const toaster = useToaster();

  const [membersToSendNewInvite, membersToResendInvite] = useMemo(() => {
    return [
      selectedMembers.filter((member) => member.inviteType === 'new'),
      selectedMembers.filter((member) => member.inviteType === 'resend'),
    ];
  }, [selectedMembers]);

  const selectMember = useCallback((member: InviteMember) => {
    setSelectedMembers((prev) => uniqBy([...prev, member], 'email'));
  }, []);

  const removeMember = useCallback((email: string) => {
    setSelectedMembers((prev) =>
      prev.filter((member) => member.email !== email)
    );
  }, []);

  const updateMemberRole: UpdateMemberRoleFn = useCallback(
    (email: string, admin: boolean) => {
      setSelectedMembers((prev) => {
        return prev.map((member) => {
          if (member.email === email) {
            return { ...member, admin };
          }

          return member;
        });
      });
    },
    []
  );

  const sendInvites = useCallback(
    async (showToast: boolean) => {
      const showSuccessToast = (message: string) => {
        if (showToast) {
          toaster.success(message);
        }
      };

      const showFailureToast = (message: string) => {
        if (showToast) {
          toaster.failure(message);
        }
      };

      if (
        membersToSendNewInvite.length === 0 &&
        membersToResendInvite.length === 0
      ) {
        return true;
      }

      setInviting(true);
      try {
        const newInvitePromise = sendEmailInvite(EmailInviteType.NEW, {
          emails: membersToSendNewInvite.map((member) => ({
            email: member.email,
            make_admin: member.admin,
          })),
        });

        const resendInvitePromise = sendEmailInvite(EmailInviteType.RESEND, {
          emails: membersToResendInvite.map((member) => ({
            email: member.email,
            make_admin: member.admin,
          })),
        });

        const [newInviteResult, resendInviteResult] = await Promise.allSettled([
          newInvitePromise,
          resendInvitePromise,
        ]);

        if (
          newInviteResult.status === 'rejected' &&
          resendInviteResult.status === 'rejected'
        ) {
          showFailureToast('Failed to invite members');
          return false;
        }

        if (
          newInviteResult.status === 'fulfilled' &&
          resendInviteResult.status === 'fulfilled'
        ) {
          showSuccessToast('Invited members successfully');
          setSelectedMembers([]);
          return true;
        }

        if (membersToSendNewInvite.length > 0) {
          if (newInviteResult.status === 'fulfilled') {
            showSuccessToast('Invited members successfully');
          } else {
            showFailureToast('Failed to send invites');
            setSelectedMembers(membersToSendNewInvite);
            return false;
          }
        }

        if (membersToResendInvite.length > 0) {
          if (resendInviteResult.status === 'fulfilled') {
            showSuccessToast('Resent invites successfully');
          } else {
            showFailureToast('Failed to resend invites');
            setSelectedMembers(membersToResendInvite);
            return false;
          }
        }

        return true;
      } finally {
        setInviting(false);
      }
    },
    [
      membersToSendNewInvite,
      membersToResendInvite,
      toaster,
      setInviting,
      setSelectedMembers,
    ]
  );

  return {
    selectedMembers,
    membersToSendNewInvite,
    membersToResendInvite,
    selectMember,
    removeMember,
    updateMemberRole,
    inviting,
    setInviting,
    setSelectedMembers,
    sendInvites,
  };
};
