import './OnboardingTour.scss';
import React, { FC, useEffect, useState } from 'react';
import Joyride, { CallBackProps, Status, STATUS } from 'react-joyride';
import { useHistory } from 'react-router-dom';
import { SidebarRoutes } from '../../../containers/SidebarContainer';
import { trackEvent } from '../../../extra/sharedMethods';
import { invokeFastApi } from '../../../scripts/apis/fastapi';
import { AnalyticsEvent } from '../../../scripts/constants/analytics-event';
import { MessageAction } from '../../../scripts/constants/message-action';
import { useUserSafe } from '../../../scripts/hooks';
import { toggleSidebar } from '../../../scripts/sidebar';
import { logError, logErrorWithData } from '../../../scripts/utils';
import {
  isQAMessagePresentInDOM,
  isUserOnboardingTourDoneStoredInLocalStorage,
  ONBOARDING_TOUR_STEP_CLASSES,
  setUserOnboardingTourDoneInLocalStorage,
  STEPS,
} from './utils';

const POLLING_INTERVAL = 500;
const POLLING_TIMEOUT = 10_000;

export const OnboardingTour: FC = () => {
  const [currentTourStatus, setCurrentTourStatus] =
    useState<CallBackProps | null>(null);

  const [tourProps, setTourProps] = useState({ run: false, steps: STEPS });
  const [isTourDone, setIsTourDone] = useState(false);

  const history = useHistory();

  const user = useUserSafe();

  const handleJoyrideCallback = async (data: CallBackProps) => {
    const { status } = data;

    const finishedStatuses: Status[] = [STATUS.FINISHED, STATUS.SKIPPED];

    setCurrentTourStatus(data);

    if (finishedStatuses.includes(status)) {
      setUserOnboardingTourDoneInLocalStorage(true);

      trackEvent(
        status === STATUS.FINISHED
          ? AnalyticsEvent.UserOnboardingTourFinished
          : AnalyticsEvent.UserOnboardingTourSkipped,
        {
          tourName: 'onboarding_tour',
        }
      );

      setIsTourDone(true);
      setTourProps((prev) => ({ ...prev, run: false }));

      try {
        await invokeFastApi({
          path: '/users/tour_progress',
          method: 'PATCH',
          body: {
            tour_name: 'onboarding_tour',
            state: status,
          },
          shouldRetry: true,
        });
      } catch {
        logErrorWithData(new Error('Failed to update user tour progress'), {
          status,
        });
      }
    }
  };

  const checkElementsPresence = (): boolean => {
    let classNames = tourProps.steps
      .filter((step) => step.target && typeof step.target === 'string')
      .map((step) => step.target as string);

    if (isQAMessagePresentInDOM()) {
      classNames = classNames.filter(
        // filtering use case card step, incase the user has already asked a question before the tour has started
        (className) =>
          className !== `.${ONBOARDING_TOUR_STEP_CLASSES.USE_CASE_CARD}`
      );
    }

    return classNames.every((className) => document.querySelector(className));
  };

  const startTourWhenElementsPresent = () => {
    history.push(SidebarRoutes.JitQA);
    toggleSidebar(false);

    const startTime = Date.now();

    const intervalId = setInterval(() => {
      if (checkElementsPresence()) {
        clearInterval(intervalId);
        if (isQAMessagePresentInDOM()) {
          setTourProps((prev) => ({
            ...prev,
            steps: STEPS.filter(
              (step) =>
                step.target !== `.${ONBOARDING_TOUR_STEP_CLASSES.USE_CASE_CARD}`
            ),
            run: true,
          }));
        } else {
          setTourProps((prev) => ({ ...prev, run: true }));
        }
      } else if (Date.now() - startTime > POLLING_TIMEOUT) {
        clearInterval(intervalId);
        logError('Tour elements not found within timeout period.');
      }
    }, POLLING_INTERVAL);
  };

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      const isUserTourDone = isUserOnboardingTourDoneStoredInLocalStorage();

      if (event.data === MessageAction.UserSetupFinished && !isUserTourDone) {
        startTourWhenElementsPresent();
      }
    };

    window.addEventListener('message', handleMessage);

    const isUserTourDone = isUserOnboardingTourDoneStoredInLocalStorage();

    setIsTourDone(isUserTourDone);

    if (!isUserTourDone) {
      const isUserSetupDone = user.userSetupStatus === 'SHOWN';
      const isTourNotDone = user.toursProgress.onboarding_tour === 'not_seen';

      if (isUserSetupDone && isTourNotDone) {
        startTourWhenElementsPresent();
      }
    }

    return () => {
      window.removeEventListener('message', handleMessage);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!tourProps.run || isTourDone) {
    return null;
  }

  if (
    currentTourStatus?.action === 'close' ||
    currentTourStatus?.action === 'skip'
  ) {
    return null;
  }

  return (
    <Joyride
      callback={handleJoyrideCallback}
      continuous
      disableCloseOnEsc
      disableOverlayClose
      hideCloseButton
      locale={{
        last: 'Done',
      }}
      spotlightClicks
      steps={tourProps.steps}
      styles={{
        options: {
          zIndex: 10_000,
        },
        tooltip: {
          backgroundColor: 'white',
          color: 'black',
          borderRadius: '10px',
        },
        buttonNext: {
          backgroundColor: 'rgb(79, 13, 82)',
          color: '#fff',
          borderRadius: '4px',
          padding: '9px 15px',
          lineHeight: '1.5',
          fontSize: '14px',
          fontWeight: '500',
          letterSpacing: '0.2px',
        },
        buttonBack: {
          display: 'none',
        },
        tooltipFooter: {
          marginTop: '0',
          borderTop: '1px solid #d2d7db',
          paddingTop: '12px',
        },
        beaconInner: {
          backgroundColor: 'rgb(79, 13, 82)',
        },
        beaconOuter: {
          backgroundColor: 'rgb(79, 13, 82, 0.2)',
          border: '2px solid rgb(79, 13, 82)',
        },
      }}
    />
  );
};
